import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';

import '../assets/css/style.css';
import style from '../assets/css/custom.css';

import logo from '../../src/assets/images/logo.svg';

import Amendinput from '../components/Modal/InputModal';
import Popupinput from '../components/Modal/PopupModal';
import axios, {authClient} from '../lib/axios';


const EditPage = () => {
    //토글 모달 구현
    const [modalState, setModalState] = useState(false);
    const [popState, setpopState] = useState(false);
    const [toggleIndex, setToggleIndex] = useState('');

    const [title, setTitle] = useState([]);
    const [isLoading, SetLoading] = useState(true);
    //수정의 토글모달
    const toggleModal = (el) => {
        setModalState(!modalState);
        setToggleIndex(el);
    }
    //팝업의 토글모달
    const poptoggle = (el) => {
        setpopState(!popState)
        setToggleIndex(el);
    }

    //로그아웃
    const getLogOut = () => {
        try {
            authClient.get('/logout')
                .then(res => {
                    console.log("로그아웃 됨", res);
                    if (res.data.success) {
                        document.location.href = '/login';
                    } else {
                        alert('로그아웃 실패');
                    }
                })
        } catch (err) {
            console.log("logOut error:", err);
        }
    }

    useEffect(async () => {
        try {
            const res = await authClient.get('/amendBest', {})
            setTitle(res.data);
            SetLoading(false);
            console.log(res.data);
        } catch (e) {
            console.error(e.message);
        }

        //window.addEventListener("click", handleClickOutside, true);
    }, []);

    const tableForm = [];
    const inputAmend = () => {
        title && title.map((el, index) => {
            console.log(el);
            let file = el.FILE_POPUP;
            tableForm.push(
                <div>
                    <div className="test">
                        {/* <div><img src={`/upload/${file}`}></img></div> */}
                        {/* <img src={edit_img} alt="베스트 3선 이미지" /> */}
                        <img src={`/image/${file}`} alt="베스트 3선 이미지"/>
                        <p>
                            <span key={el.INDEX}>{el.TITLE_kr} / {el.TITLE_en} / {el.TITLE_ch} / {el.TITLE_ru}</span>
                            <div>{el.CONTENT_kr}/{el.CONTENT_en}</div>
                        </p>
                        <div className="button">
                            <div className="btn_01" index1={el.INDEX} onClick={() => {
                                toggleModal(el)
                            }}>수정
                            </div>
                            {modalState &&
                                <Amendinput index={index} title={index} toggleModal={toggleModal}></Amendinput>}
                            {/* <div className="btn_01">삭제</div> */}
                            <div className="btn_02" index1={el.INDEX} onClick={() => {
                                poptoggle(el)
                            }}>팝업 업로드
                            </div>
                            {popState && <Popupinput index={index} poptoggle={poptoggle}></Popupinput>}
                        </div>
                    </div>
                </div>
            )
        });
        return (tableForm);
    }
    return (
        <>
            <header>
                <h1><Link to="/"><img src={logo} alt="TNC logo"/></Link></h1>
                <ul>
                    <li><Link to="/">home</Link></li>
                    <li><a href="#" onClick={getLogOut}>logout</a></li>
                </ul>
            </header>
            <div id="body_flex">
                <div id="sidebar">
                    <ul>
                        <li><Link to='/view'>메세지함</Link></li>
                        <li><Link to='/editpage'>베스트 3선 패키지</Link></li>
                    </ul>
                </div>
                <div id="container">
                    <div className="contents02">
                        <h2>베스트 3선</h2>
                        <div className="best_3">
                            {isLoading ? null : inputAmend()}
                        </div>
                    </div>
                </div>
                {/* <!--#container--> */}
            </div>
            {/* <!--#body_flex--> */}
            <div id="footer">
                <p>ⓒ 2021 BUSAN TNC All rights reserved.</p>
            </div>
        </>
    )
}

export default EditPage;