import { useEffect, useRef, useState, StyleSheet } from 'react';

import cn from 'classnames';

/* DatePicker */
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import '../../node_modules/react-datepicker/dist/react-datepicker-cssmodules.css';
// import '../../node_modules/react-datepicker/dist/react-datepicker-cssmodules.min.css';
// import '../../node_modules/react-datepicker/dist/react-datepicker.css';
// import '../../node_modules/react-datepicker/dist/react-datepicker.min.css';
import ko from 'date-fns/locale/ko'; // 한국어적용

/* bootstrap */
import 'bootstrap/dist/js/bootstrap.bundle.min';

/* CSS */
import '../assets/css/fontawesome.css';
import '../assets/css/custom.css'; /* 수정한 CSS */
import '../assets/css/animated.css';
import '../assets/css/owl.css';
import style from '../components/Modal/Modal.module.css';
// import '../../node_modules/intl-tel-input/build/css/intlTelInput.css'; /* 국가번호 CSS */

/* imgs */
import logo from '../assets/images/tnc_newlogo.jpeg';
import firstplace from '../assets/images/custom_1level_2x.png';
import icon_call from '../assets/images/custom_iconcall.png';
import medibusan from '../assets/images/custom_meditour_2x.png';
import US from '../assets/images/flag_us.png';
import RU from '../assets/images/flag_ru.png';
import CH from '../assets/images/flag_ch.png';
import VI from '../assets/images/flag_vi.png';
import insta from '../assets/images/instagram.png';
import facebook from '../assets/images/facebook.png';

/* icon */
import {
  IoIosArrowForward,
  IoIosArrowDropright,
} from 'react-icons/io';

import { HiOutlineMinusSm } from 'react-icons/hi';

import { Link } from 'react-router-dom';
import Popup from '../components/Modal/Popup';
import moment from 'moment';
import { unAuthClient } from '../lib/axios';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";

const Home = () => {
  const [popup, setPopup] = useState(false);
  const [best, setBest] = useState([]);
  const [toggleIndex, setToggleIndex] = useState('');
  const [isLoading, SetLoading] = useState(true);
  const [videoSrc, setVideoSrc] = useState(
    'https://player.vimeo.com/video/779140621?h=cba8fc7e59'
  );
  const [starSrc, setStarSrc] = useState(
    'https://player.vimeo.com/video/779141748?h=09df3f454e'
  );
  const [selectedVideoIndex, setSelectedVideoIndex] = useState('1');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  //모바일 select박스 value에 따라 페이지 전환 때문에 navigate
  const navigate = useNavigate();
  const form = useRef();
  const [initialInputData, SetInitialInputData] = useState({
    user_name: '',
    phone: '',
    user_email: '',
    section: '병원예약', //need to default value
    detail_section: '상급·종합병원',
    message: '',
    user_count: 1,
    start: moment(new Date()).format('YYYY-MM-DD HH:mm'),
    user_Room: 1,
    user_language: 'English',
  });
  const [isActiveLanguage,setIsActiveLanguage] =useState(false);

  const togglePopup = (el) => {
    setToggleIndex(el);
    setPopup(!popup);
    console.log(el);
  };


  const sendEmail = async (event) => {
    event.preventDefault();
    const option = {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: initialInputData,
    };

    if(!_.isEmpty(initialInputData.user_name||!_.isEmpty(initialInputData.user_email))) {
      try {
        await unAuthClient
            .post('/sendmail', option);
        window.alert('success');
      } catch (err) {
        console.log('fetch error : ', err);
      } finally{
        window.location.reload();
      }
    }else{
      window.alert('Please enter required items ')
    }
  };

  const updateField = (e) => {
    if (e.target.name === 'section') {
      SetInitialInputData({
        user_name: '',
        phone: '',
        user_email: '',
        section: e.target.value,
        detail_section: '상급·종합병원',
        message: '',
        user_count: 1,
        start: moment(new Date()).format('YYYY-MM-DD HH:mm'),
        user_Room: 1,
        user_language: 'English',
      });
      setIsActiveLanguage(false);
    }else{
      SetInitialInputData({
        ...initialInputData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  /* 호텔 */

  const addedDate = (data) => {
    SetInitialInputData({
      ...initialInputData,
      start: moment(data).format('YYYY-MM-DD HH:mm'),
    });
    console.log('test : ', data);
    console.log(initialInputData);
  };
  const endedDate = (data, e) => {
    SetInitialInputData({
      ...initialInputData,
      finish: moment(data).format('YYYY-MM-DD HH:mm'),
    });
    console.log('finish : ', data);
    console.log(initialInputData);
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  //베스트 3선 자세히 보기
  const best_pop = () => {
    const best_vener = [];

    if (best.length > 0) {
      best?.map((el, index) => {
        let file = el.FILE_POPUP;
        best_vener.push(
          <div className='custom_bestitems' key={index}>
            <ul>
              {/* <!-- 힐링&웰니스 투어 --> */}
              <li>
                <img src={`/image/${file}`} alt={el.TITLE_kr} />
                {/* <img src={top_img} alt={el.TITLE_kr} /> */}
              </li>
              {/* <!-- img --> */}
              <li>
                <p className='custom_besttitle'>{el.TITLE_kr}</p>
                <p className='custom_besttext'>{el.CONTENT_kr}</p>
              </li>
              {/* <!-- text --> */}
              <li>
                <button
                  className='custom_morebtn'
                  onClick={() => togglePopup(el)}
                >
                  자세히 보기
                </button>
              </li>
            </ul>
          </div>
        );
      });
    }
    return best_vener;
  };

  useEffect(() => {
    const fun = async () => {
      try {
        const res = await unAuthClient.get('/amendBest');
        setBest(res.data);
        SetLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    fun();
  }, []);

  const changeVideo = (event) => {
    setVideoSrc(event.target.name);
    setSelectedVideoIndex(event.target.id);
  };

  const changeStarVideo = (event) => {
    setStarSrc(event.target.name);
  };

  return (
    <>
      <header className='header_area header_sticky wow slideInDown'>
        {popup && (
          <Popup
            change='ko'
            best={best}
            index={toggleIndex}
            togglePopup={togglePopup}
          />
        )}
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <nav className='main-nav'>
                {/* <!-- ***** Logo Start ***** --> */}
                <a href='/KorPage' className='logo'>
                   <img src={logo} alt="logo자리" />
                </a>
                {/* <!-- ***** Logo End ***** --> */}

                {/* <!-- ***** Menu Start ***** --> */}
                <ul className='nav'>
                  <li className='scroll-to-section'>
                    <Link to='/'>
                      <img src={US} alt='usa_flag' />
                    </Link>
                  </li>
                  {/* <!-- 영어 -->  */}

                  <li className='scroll-to-section'>
                    <Link to='/ChPage'>
                      <img src={CH} alt='China_flag' />
                    </Link>
                  </li>
                  {/* <!-- 중국어 --> */}

                  <li className='scroll-to-section'>
                    <Link to='/RuPage'>
                      <img src={RU} alt='Russia_flag' />
                    </Link>
                  </li>
                  {/* <!-- 러시아어 --> */}

                  <li className='scroll-to-section'>
                    <Link to='/ViPage'>
                      <img src={VI} alt='Vietnam_flag' />
                    </Link>
                  </li>
                  {/* <!-- 베트남어 --> */}

                  {/* 23-05-12 소셜미디어 링크 추가 */}
                  <div className='socialMedia_link'>
                    <li className='scroll-to-section'>
                      <Link to='https://www.instagram.com/star_doctor_in_busan_eng/'>
                        <img src={insta} alt='instagram link' />
                      </Link>
                    </li>
                    <li className='scroll-to-section'>
                      <Link to='https://www.facebook.com/Star-doctor-in-busan-eng-112131227281477/'>
                        <img src={facebook} alt='facebook link' />
                      </Link>
                    </li>
                  </div>
                  {/* <li className='scroll-to-section custom_login_btn'>
                    <div className='border-first-button'>
                      <Link to='/login'>login</Link>
                    </div>
                  </li>
                  <li className='custom_settingicon'>
                    <Link to='view'>
                      <IoMdSettings />
                    </Link>
                  </li> */}
                </ul>

                <div className='nav_s'>
                  <select
                    onChange={(e) => {
                      console.log(e.target.value);
                      navigate(e.target.value);
                    }}
                  >
                    <option className='scroll-to-section' href='/KorPage'>
                      KO
                    </option>
                    {/* <!-- 한글 -->   */}
                    <option className='scroll-to-section' value='/'>
                      EN
                    </option>
                    {/* <!-- 영어 --> */}
                    <option className='scroll-to-section' value='/ChPage'>
                      CH
                    </option>
                    {/* <!-- 중국어 --> */}
                    <option className='scroll-to-section' value='/RuPage'>
                      RU
                    </option>
                    {/* <!-- 러시아어 --> */}
                    <option className='scroll-to-section' value='/ViPage'>
                      VI
                    </option>
                    {/* <!-- 베트남어 --> */}
                  </select>
                  {/* 23-05-12 소셜미디어 링크 추가 */}
                  <div className='socialMedia_link'>
                    <li className='scroll-to-section'>
                      <Link to='https://www.instagram.com/'>
                        <img src={insta} alt='instagram link' />
                      </Link>
                    </li>
                    <li className='scroll-to-section'>
                      <Link to='https://www.facebook.com/'>
                        <img src={facebook} alt='facebook link' />
                      </Link>
                    </li>
                  </div>
                  {/* <a href='#contact'>
                    <div className='order-first-button'>login</div>
                  </a>
                  <div className='custom_setting'>
                    <IoMdSettings className='custom_settingicon' />
                  </div> */}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- ***** Header Area End ***** --> */}

      {/* <!-- 대한민국 대표 의료관광도시 부산! --> */}
      <div
        className='main_banner wow fadeIn'
        id='top'
        data-wow-duration='1s'
        data-wow-delay='0.5s'
      >
        <div className='container custom_header'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='col-lg-12 align-self-center'>
                <div
                  className='left-content show-up header-text wow fadeInLeft'
                  data-wow-duration='1s'
                  data-wow-delay='1s'
                >
                  <div className='col-lg-12'>
                    <h6 className='custom_title'>MEDICAL TECHNOLOGY</h6>
                    <h2 className='custom_subtitle'>
                      대한민국 대표 의료관광도시 부산!
                    </h2>
                    <p className='custom_content'>
                      부산은 인구 350만명의 대한민국 제2의 도시이자, 최대
                      항구도시입니다. 사계절 온화한 기후와 산, 바다 등 천혜의
                      관광자원과 부산불꽃축제, 부산국제영화제 등 다양한
                      국제행사가 개최되는 관광도시로 론리플래닛 ‘아시아
                      최고여행지 1위(2018)’에 선정 되는 등 전 세계적인
                      관광휴양지로서 기반을 굳건히 다지고 있습니다. 또한,
                      최고품질의 의료서비스와 최고수준의 의료기술은 이시아지역
                      대표적인 의료관광지로써 “가고싶은 의료관광도시 부산”을
                      만들어내고 있으며 관광객들에게 최고의 만족도를 선사하고
                      있습니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--의료도시 1위 수상 --> */}
      <div id='about' className='about section'>
        {/* <!-- 바탕으로 들어가는 div}
    {/* <div className="row custom_row">
      <div className="col-lg-6 blank">
        {오른쪽 빈공간 bg color만 들어감}
      </div>
      <div className="col-lg-6 bg02img">
        {왼쪽 bg img 들어감}
      </div>
    </div> */}

        <div className='row custom_row'>
          <div className='col-lg-6 bg02img'>
            {/* <!-- 왼쪽 bg img 들어감 --> */}
          </div>
        </div>

        <div className='row custom_float'>
          <div
            className='col-lg-12 align-self-center wow fadeInRight'
            data-wow-duration='1s'
            data-wow-delay='0.5s'
          >
            <div className='section-heading'>
              {/* <!-- 1숫자 --> */}
              <div className='custom_num1img'>
                <img src={firstplace} alt='의료도시 부문1위' />
              </div>
            </div>
            <h2 className='custom_subtitle'>
              대한민국 국가브랜드
              <br />
              '의료도시 부문 1위' 수상
            </h2>
            <p className='custom_text'>
              부산은 의료관광산업의 집중육성을 통해 동북아 의료관광 중심도시를
              추구하고 있으며, 한국 최초 중입자 가속기 도입(2023), 로봇 수술센터
              등 세계적 수준의 의료인프라 시설을 보유하고 있습니다. 또한 우수한
              전문 인력을 보유한 5,000여개의 의료기관과 서면 메디컬스트리트 등
              집적화되어 있는 의료관광 클러스터를 바탕으로 성형외과, 피부과,
              치과에서부터 뇌, 심장, 종양치료 등 중증질환까지 다양한 환자군을
              대상으로 고품질의 의료 서비스를 제공하고 있습니다.
            </p>
          </div>
        </div>
      </div>

      {/* 부산 메디컬 센터, 컨시어지 서비스 */}
      <div id='services' className='services section'>
        <div className='container custom_service'>
          <div className='row col-lg-12'>
            <div className='custom_info'>
              <div
                className='section-heading wow fadeInDown custom_info01 custom_short01'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                {/* <img src={picture01} alt='부산메디컬센터' /> */}
                <iframe
                  src='https://player.vimeo.com/video/787477437?h=c382c2818a&amp;badge=0&amp;autoplay=1&loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                  frameborder='0'
                  allow='autoplay; fullscreen; picture-in-picture'
                  allowfullscreen
                  title='쇼츠 동아대학교병원'
                ></iframe>
                <script src='https://player.vimeo.com/api/player.js'></script>
              </div>

              <div
                className='custom_info02 wow fadeInDown'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                <h4 className='custom_info_title'>부산 Medicall 센터</h4>
                <h6 className='custom_info_subtext'>
                  Medicall 센터는 부산을 방문하시는 의료관광객분들을 위해
                  공항픽업차량부터 호텔예약과 통역서비스 및 웰니스 관광투어
                  패키지 소개까지 각종 편의 서비스를 제공해드림으로써 더욱더
                  편안하고 안전하게 부산의료관광을 즐기실수 있도록 하는 의료관광
                  서비스 플랫폼입니다. 자세한 서비스 문의나 병원예약까지
                  원하시면 메신저나 이메일, 유선통화를 통해 자세하게 상담하실수
                  있습니다. 주요 국가별 언어로 메신저 회신이 가능합니다.
                </h6>
              </div>
            </div>
          </div>
          {/* <!-- row 1 end --> */}
          {/* 테블릿 싸이즈 - CSS 1739 */}
          <div className='row col-lg-12'>
            <div className='custom_info custom_info_s'>
              <div
                className='custom_info02 wow fadeInDown'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                <h4 className='custom_info_title'>컨시어지 서비스</h4>
                <h6 className='custom_info_subtext'>
                  Medicall 센터사업은 부산광역시 예산으로 부산권의료산업협의회가
                  개발하고, 부산 TNC가 운영하는 사업이며 의료관광객의 불편을
                  덜어드리는 컨시어지 전문 플랫폼입니다. 고객맞춤형 다양한
                  컨시어지 서비스 제공으로 부산을 찾는 외국인 의료관광객에게
                  보다 수준높은 서비스를 제공하겠습니다.
                </h6>
              </div>
              <div
                className='section-heading wow fadeInDown custom_info01 custom_short02'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                {/* <img src={picture02} alt='부산메디컬센터' /> */}
                <iframe
                  src='https://player.vimeo.com/video/787477407?h=2bbea0f96b&amp;badge=0&amp;autoplay=1&loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                  frameborder='0'
                  allow='autoplay; fullscreen; picture-in-picture'
                  allowfullscreen
                  title='쇼츠 메디컬센터 김해'
                ></iframe>
                <script src='https://player.vimeo.com/api/player.js'></script>
              </div>
            </div>
          </div>
          {/* <!-- row 2 end --> */}
        </div>
      </div>

      {/* 의료기관, 호텔 소개 바로가기 */}
      <div id='banners' className='banners'>
        <div className='custom_bannerdiv'>
          <div className='custom_medicalbusanlink'>
            <img src={medibusan} alt='메디컬부산' />
          </div>
          <div className='custom_banners'>
            <div className='banner_wrap'>
              <div className='custom_banner01'>
                <p>의료기관 소개</p>
                {/* <a href="#"><button>바로가기 <IoIosArrowForward className='custom_icon'/></button></a> */}
                <a href=''>
                  <button
                    onClick={() =>
                      window.open(
                        'https://www.bsmeditour.go.kr/medicalInstitution.do',
                        '_blank'
                      )
                    }
                  >
                    바로가기 <IoIosArrowForward className='custom_icon' />
                  </button>
                </a>
              </div>
              <div className='custom_banner02'>
                <p>호텔 소개</p>
                {/* <a href="#"><button>바로가기 <IoIosArrowForward className='custom_icon'/></button></a> */}
                <a href='#'>
                  <button
                    onClick={() =>
                      window.open(
                        'https://www.bsmeditour.go.kr/AboutHotel.do',
                        '_blank'
                      )
                    }
                  >
                    바로가기 <IoIosArrowForward className='custom_icon' />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 클리닉 비디오, 리스트 */}
      <div id='clinic_videos' className='clinic_videos section'>
        <div className='container'>
          <div className='custom_video_list_wrap'>
            {/* <div className='iframe_List'> */}
            <div className='clinic_video'>
              <iframe
                src={`${videoSrc}`}
                frameborder='0'
                allow='autoplay; fullscreen'
                allowfullscreen
                title='custom_video'
              ></iframe>
            </div>
            <div className='videoList'>
              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '1' ? '#274fd5' : '',
                    color: selectedVideoIndex === '1' ? '#fff' : '',
                  }}
                  id='1'
                  name='https://player.vimeo.com/video/779140621?h=cba8fc7e59'
                  /* value={'메롱'} */
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {'피부 미용'}
                {/* 피부 미용 */}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '2' ? '#274fd5' : '',
                    color: selectedVideoIndex === '2' ? '#fff' : '',
                  }}
                  id='2'
                  name='https://player.vimeo.com/video/779140351?h=4c6f0c20c7'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {'심장흉통 클리닉'}
                {/* 심장흉통 클리닉 */}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '3' ? '#274fd5' : '',
                    color: selectedVideoIndex === '3' ? '#fff' : '',
                  }}
                  id='3'
                  name='https://player.vimeo.com/video/779140098?h=c4c9cc8e05'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {'면역증강 클리닉'}
                {/* 면역증강 클리닉 */}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '4' ? '#274fd5' : '',
                    color: selectedVideoIndex === '4' ? '#fff' : '',
                  }}
                  id='4'
                  name='https://player.vimeo.com/video/779139926?h=e099099724'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {'하지정맥 클리닉'}
                {/* 하지정맥 클리닉 */}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '5' ? '#274fd5' : '',
                    color: selectedVideoIndex === '5' ? '#fff' : '',
                  }}
                  id='5'
                  name='https://player.vimeo.com/video/779139710?h=8f43aeac91'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {'재건 성형 클리닉'}
                {/* 재건 성형 클리닉 */}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '6' ? '#274fd5' : '',
                    color: selectedVideoIndex === '6' ? '#fff' : '',
                  }}
                  id='6'
                  name='https://player.vimeo.com/video/779138898?h=8e27f59c23'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {'혈액질환'}
                {/* 혈액질환 */}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '7' ? '#274fd5' : '',
                    color: selectedVideoIndex === '7' ? '#fff' : '',
                  }}
                  id='7'
                  name='https://player.vimeo.com/video/779120666?h=e2b4f6a346'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {'시력 교정'}
                {/* 시력 교정 */}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '8' ? '#274fd5' : '',
                    color: selectedVideoIndex === '8' ? '#fff' : '',
                  }}
                  id='8'
                  name='https://player.vimeo.com/video/779106965?h=4be381c049'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {'소아외과'}
                {/* 소아외과 */}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '9' ? '#274fd5' : '',
                    color: selectedVideoIndex === '9' ? '#fff' : '',
                  }}
                  id='9'
                  name='https://player.vimeo.com/video/783852367'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 담낭질환 */}
                {'담낭질환 클리닉'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '10' ? '#274fd5' : '',
                    color: selectedVideoIndex === '10' ? '#fff' : '',
                  }}
                  id='10'
                  name='https://player.vimeo.com/video/783852595'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 폐암 */}
                {'폐암 클리닉'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '11' ? '#274fd5' : '',
                    color: selectedVideoIndex === '11' ? '#fff' : '',
                  }}
                  id='11'
                  name='https://player.vimeo.com/video/784315514'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 대장암 */}
                {'대장암 클리닉'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '12' ? '#274fd5' : '',
                    color: selectedVideoIndex === '12' ? '#fff' : '',
                  }}
                  id='12'
                  name='https://player.vimeo.com/video/785843839'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 내분비 */}
                {'내분비 클리닉'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '13' ? '#274fd5' : '',
                    color: selectedVideoIndex === '13' ? '#fff' : '',
                  }}
                  id='13'
                  name='https://player.vimeo.com/video/891720695'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 3편 뇌전증 */}
                {'뇌전증'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '14' ? '#274fd5' : '',
                    color: selectedVideoIndex === '14' ? '#fff' : '',
                  }}
                  id='14'
                  name='https://player.vimeo.com/video/891721924'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 척추 */}
                {'정형외과'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '15' ? '#274fd5' : '',
                    color: selectedVideoIndex === '15' ? '#fff' : '',
                  }}
                  id='15'
                  name='https://player.vimeo.com/video/891722730'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 임플란트 */}
                {'임플란트'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '16' ? '#274fd5' : '',
                    color: selectedVideoIndex === '16' ? '#fff' : '',
                  }}
                  id='16'
                  name='https://player.vimeo.com/video/891728956'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 두드러기 */}
                {'두드러기 클리닉'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '17' ? '#274fd5' : '',
                    color: selectedVideoIndex === '17' ? '#fff' : '',
                  }}
                  id='17'
                  name='https://player.vimeo.com/video/891731569'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 난임 */}
                {'난임 클리닉'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '18' ? '#274fd5' : '',
                    color: selectedVideoIndex === '18' ? '#fff' : '',
                  }}
                  id='18'
                  name='https://player.vimeo.com/video/898034163'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 부인암 */}
                {'부인암 클리닉'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '19' ? '#274fd5' : '',
                    color: selectedVideoIndex === '19' ? '#fff' : '',
                  }}
                  id='19'
                  name='https://player.vimeo.com/video/898040455'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 난청 */}
                {'난청 클리닉'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '20' ? '#274fd5' : '',
                    color: selectedVideoIndex === '20' ? '#fff' : '',
                  }}
                  id='20'
                  name='https://player.vimeo.com/video/899719509'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 유방암 */}
                {'유방암 클리닉'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>
              <button className='videoList_btn'>라인맞추기용</button>
            </div>
          </div>
        </div>
      </div>

      {/* 예약 form */}
      <div id='new_contact' className='new_contact section'>
        <div className='container container_s'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='naccs'>
                <div className='grid custom_accodian'>
                  <div className='row custom_contact'>
                    <div className='col-lg-3 custom_call'>
                      {/* <!-- 전화 상담 --> */}
                      <ul>
                        <li>
                          <img src={icon_call} alt='문의전화'/>
                        </li>
                        {/* <!-- icon img --> */}
                        <li>
                          <p className='custom_ask'>
                            궁금한 사항이 있으시면
                            <br/>
                            친절하게 상담해드립니다.
                          </p>
                        </li>
                        <li>
                          <p className='custom_servicenumber'>
                            +82 70-8874-9334
                            +82 10-2127-9334
                          </p>
                        </li>
                      </ul>
                      {/* <a href='#'> */}
                      <button className='custom_call_btn'>전화 상담하기</button>
                      {/* </a> */}
                    </div>
                    <div
                        className='col-lg-8 custom_accordion accordion nacc'
                        id='accordionExample'
                    >
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingOne'>
                          <button
                              className='accordion-button'
                              type='button'
                              name='section'
                              onClick={updateField}
                              value='병원예약'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseOne'
                              aria-expanded='true'
                              aria-controls='collapseOne'
                          >
                            {/* <img src={reserve01} alt="Hospital appointment" /> */}
                            병원예약
                          </button>
                        </h2>
                        <div
                            id='collapseOne'
                            className='accordion-collapse collapse show'
                            aria-labelledby='headingOne'
                            data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                                className='custom_form'
                                ref={form}
                                onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>이름</label>
                                <input
                                    type='text'
                                    name='user_name'
                                    onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>연락처</label>
                                <input
                                    id='phone'
                                    type='tel'
                                    name='phone'
                                    onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>이메일</label>
                                <input
                                    type='email'
                                    name='user_email'
                                    onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>진료과목</label>
                                <select
                                    onChange={updateField}
                                    name='detail_section'
                                >
                                  <option>상급·종합병원</option>
                                  <option>종합검진</option>
                                  <option>정형외과</option>
                                  <option>성형외과</option>
                                  <option>산부인과</option>
                                  <option>피부과</option>
                                  <option>치과</option>
                                  <option>안과</option>
                                  <option>한방</option>
                                  <option>기타</option>
                                </select>
                              </div>
                              <div className='custom_language divs'>
                                <label>언어</label>
                                <div className='custom_radioselect'>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='English'
                                        defaultChecked
                                        onChange={updateField}
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>영어</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Russian'
                                        onChange={updateField}
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>러시아어</p>
                                  </div>
                                  {/* <div className="custom_langua">
                                    <input type="radio" name="user_language" value="Japanese" onChange={updateField}/>
                                    <p>Japanese</p>
                                </div> */}
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Chinese'
                                        onChange={updateField}
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>중국어</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Vietnamese'
                                        onChange={updateField}
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>베트남어</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='etc'
                                        onClick={() => setIsActiveLanguage(true)}
                                    />
                                    {isActiveLanguage ? <input className='language_div'
                                                               type='text'
                                                               placeholder=''
                                                               name='user_language'
                                                               onChange={updateField}/> : <p>기타</p>}
                                  </div>
                                </div>
                              </div>
                              <div className='custom_contentbox divs'>
                                <label>내용</label>
                                <textarea
                                    name='message'
                                    onChange={updateField}
                                />
                              </div>
                              <input
                                  type='submit'
                                  value='보내기'
                                  className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* 병원 예약 */}
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingTwo'>
                          <button
                              className='accordion-button collapsed'
                              name='section'
                              onClick={updateField}
                              value='컨시어지'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseTwo'
                              aria-expanded='false'
                              aria-controls='collapseTwo'
                          >
                            {/* <img src={reserve02} alt="Concierge" /> */}
                            컨시어지
                          </button>
                        </h2>
                        <div
                            id='collapseTwo'
                            className='accordion-collapse collapse'
                            aria-labelledby='headingTwo'
                            data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                                className='custom_form'
                                ref={form}
                                onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>이름</label>
                                <input
                                    type='text'
                                    name='user_name'
                                    onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>연락처</label>
                                <input
                                    id='phone'
                                    type='tel'
                                    name='phone'
                                    onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>이메일</label>
                                <input
                                    type='email'
                                    name='user_email'
                                    onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>진료과목</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option>상급·종합병원</option>
                                  <option>종합검진</option>
                                  <option>정형외과</option>
                                  <option>산부인과</option>
                                  <option>성형외과</option>
                                  <option>피부과</option>
                                  <option>치과</option>
                                  <option>안과</option>
                                  <option>한방</option>
                                  <option>기타</option>
                                </select>
                              </div>
                              <div className='custom_language divs'>
                                <label>언어</label>
                                <div className='custom_radioselect'>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='English'
                                        onChange={updateField}
                                        defaultChecked
                                        onClick={()=>setIsActiveLanguage(false)}
                                    />
                                    <p>영어</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Russian'
                                        onChange={updateField}
                                        onClick={()=>setIsActiveLanguage(false)}
                                    />
                                    <p>러시아어</p>
                                  </div>
                                  {/* <div className="custom_langua">
                                    <input type="radio" name="user_language" value="Japanese" onChange={updateField}/>
                                    <p>Japanese</p>
                                </div> */}
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Chinese'
                                        onChange={updateField}
                                        onClick={()=>setIsActiveLanguage(false)}
                                    />
                                    <p>중국어</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Vietnamese'
                                        onChange={updateField}
                                        onClick={()=>setIsActiveLanguage(false)}
                                    />
                                    <p>베트남어</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='etc'
                                        onClick={()=>setIsActiveLanguage(true)}
                                    />
                                    {isActiveLanguage ? <input className='language_div'
                                                               type='text'
                                                               placeholder=''
                                                               name='user_language'
                                                               onChange={updateField}/> : <p>기타</p>}
                                  </div>
                                </div>
                              </div>
                              <div
                                  className={cn(
                                      style.concierge_div,
                                      style.form_div
                                  )}
                              >
                                <div className={style.dateset}>
                                  <p>일자 선택</p>
                                  <DatePicker
                                    locale={ko}
                                    timeCaption='시간'
                                    name='start'
                                    selected={startDate}
                                    onChange={(data, e) => {
                                      setStartDate(data);
                                      addedDate(data, e);
                                    }}
                                    showTimeSelect
                                    filterTime={filterPassedTime}
                                    dateFormat='yyyy-MM-dd, h:mm aa'
                                  />
                                </div>
                                <div className={style.memberset}>
                                  <p>인원</p>
                                  <select
                                    onChange={updateField}
                                    name='user_count'
                                  >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                  </select>
                                </div>
                                <div className={style.destinationset}>
                                  <p>출발 / 도착</p>
                                  <input
                                    type='text'
                                    placeholder=''
                                    name='destination'
                                    onChange={updateField}
                                  />
                                </div>
                                {/* <button className={style.carbtn}>차량예약</button> */}
                              </div>
                              <input
                                type='submit'
                                value='보내기'
                                className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingThree'>
                          <button
                            className='accordion-button collapsed'
                            name='section'
                            onClick={updateField}
                            value='호텔예약'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseThree'
                            aria-expanded='false'
                            aria-controls='collapseThree'
                          >
                            {/* <img className="btn_icon" src={reserve03} alt="Hotel reservation" /> */}
                            호텔예약
                          </button>
                        </h2>
                        <div
                          id='collapseThree'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingThree'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                              className='custom_form'
                              ref={form}
                              onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>이름</label>
                                <input
                                  type='text'
                                  name='user_name'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>연락처</label>
                                <input
                                  id='phone'
                                  type='tel'
                                  name='phone'
                                  onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>이메일</label>
                                <input
                                  type='email'
                                  name='user_email'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>진료과목</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option>상급·종합병원</option>
                                  <option>종합검진</option>
                                  <option>정형외과</option>
                                  <option>산부인과</option>
                                  <option>성형외과</option>
                                  <option>피부과</option>
                                  <option>치과</option>
                                  <option>안과</option>
                                  <option>한방</option>
                                  <option>기타</option>
                                </select>
                              </div>
                              <div className='custom_hotel divs'>
                                <label>호텔이름</label>
                                <input
                                  type='hotel'
                                  name='hotel'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_language divs'>
                                <label>언어</label>
                                <div className='custom_radioselect'>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='English'
                                        onChange={updateField}
                                        defaultChecked
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>영어</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Russian'
                                        onChange={updateField}
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>러시아어</p>
                                  </div>
                                  {/* <div className="custom_langua">
                                    <input type="radio" name="user_language" value="Japanese" onChange={updateField}/>
                                    <p>Japanese</p>
                                </div> */}
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Chinese'
                                        onChange={updateField}
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>중국어</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Vietnamese'
                                        onChange={updateField}
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>베트남어</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='etc'
                                        onClick={() => setIsActiveLanguage(true)}
                                    />
                                    {isActiveLanguage ? <input className='language_div'
                                                               type='text'
                                                               placeholder=''
                                                               name='user_language'
                                                               onChange={updateField}/> : <p>기타</p>}
                                  </div>
                                </div>
                              </div>
                              <div
                                  className={cn(style.hotel_div, style.form_div)}
                              >
                                <div
                                    className={cn(
                                        style.dateset,
                                        style.hotel_dateset
                                    )}
                                >
                                  <p>일자 선택</p>
                                  <div className={style.datepickeralign}>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => {
                                          setStartDate(date);
                                          addedDate(date);
                                        }}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={new Date()}
                                        name='start'
                                        locale={ko}
                                        dateFormat='yyyy-MM-dd'
                                    />
                                    <HiOutlineMinusSm
                                        className={style.icon_line}
                                    />
                                    <DatePicker
                                        name='finish'
                                        selected={endDate}
                                        onChange={(date) => {
                                          setEndDate(date);
                                          endedDate(date);
                                        }}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        locale={ko}
                                        dateFormat='yyyy-MM-dd'
                                    />
                                  </div>
                                </div>
                                <div className={style.memberset}>
                                  <p>인원</p>
                                  <select
                                      onChange={updateField}
                                      name='user_count'
                                  >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                  </select>
                                </div>
                                <div className={style.hotelroom}>
                                  <p>객실</p>
                                  <select
                                      onChange={updateField}
                                      name='user_Room'
                                  >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                  </select>
                                </div>
                                {/* <button className={style.carbtn}>차량예약</button> */}
                              </div>
                              {/* .hotel_div end */}
                              <input
                                type='submit'
                                value='보내기'
                                className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingFour'>
                          <button
                            className='accordion-button collapsed'
                            name='section'
                            onClick={updateField}
                            value='통역예약'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseFour'
                            aria-expanded='false'
                            aria-controls='collapseFour'
                          >
                            {/* <img src={reserve04} alt="translate" /> */}
                            통역예약
                          </button>
                        </h2>
                        <div
                          id='collapseFour'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingFour'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                              className='custom_form'
                              ref={form}
                              onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>이름</label>
                                <input
                                  type='text'
                                  name='user_name'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>연락처</label>
                                <input
                                  id='phone'
                                  type='tel'
                                  name='phone'
                                  onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>이메일</label>
                                <input
                                  type='email'
                                  name='user_email'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>진료과목</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option>상급·종합병원</option>
                                  <option>종합검진</option>
                                  <option>정형외과</option>
                                  <option>산부인과</option>
                                  <option>성형외과</option>
                                  <option>피부과</option>
                                  <option>치과</option>
                                  <option>안과</option>
                                  <option>한방</option>
                                  <option>기타</option>
                                </select>
                              </div>
                              <div
                                className={cn(
                                  style.translate_div,
                                  style.form_div
                                )}
                              >
                                <div className={style.langset}>
                                  <p>언어 선택</p>
                                  <select
                                      onChange={updateField}
                                      name='user_language'
                                  >
                                    <option value='English' selected>영어</option>
                                    <option value='Chinese'>중국어</option>
                                    <option value='Russian'>러시아어</option>
                                    <option value='Vietnamese'>베트남어</option>

                                  </select>
                                </div>
                                <div className={style.dateset}>
                                  <p>일자 선택</p>
                                  <DatePicker
                                    locale={ko}
                                    selected={startDate}
                                    onChange={(data, e) => {
                                      setStartDate(data);
                                      addedDate(data, e);
                                    }}
                                    showTimeSelect
                                    filterTime={filterPassedTime}
                                    timeCaption='시간'
                                    dateFormat='yyyy-MM-dd h:mm aa'
                                  />
                                </div>
                                <div className={style.destinationset}>
                                  <p>출발 / 도착</p>
                                  <input
                                    type='text'
                                    placeholder=''
                                    name='destination'
                                    onChange={updateField}
                                  />
                                </div>
                                {/* <button className={style.carbtn}>차량예약</button> */}
                              </div>
                              <input
                                type='submit'
                                value='보내기'
                                className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* accordion end */}
                  </div>
                  {/*  <!-- .row end --> */}
                </div>
                {/* <!-- .grid end --> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* VR 영상 23-05-12 위치 이동 */}
      <div id='vr_videos' className='col-lg-12 vr_videos'>
        <div className='container'>
          <div className='vr_video_wrap'>
            <ul className='vr_video_list'>
              <li>
                <iframe
                  src='https://player.vimeo.com/video/783849715?h=28004e4a8a&badge=0&autopause=0&player_id=0&app_id=58479/embed'
                  frameborder='0'
                  allow='autoplay; fullscreen'
                  allowfullscreen
                  title='custom_video'
                ></iframe>
              </li>
              <li>
                <iframe
                  src={`${starSrc}`}
                  frameborder='0'
                  allow='autoplay; fullscreen'
                  allowfullscreen
                  title='custom_video'
                ></iframe>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* BEST 3 Collection */}
      <div id='board' className='board col-lg-12'>
        <div className='container custom_board'>
          <div className='section-heading'>
            <h4>BEST 3 Collection</h4>
          </div>
          {isLoading ? null : best_pop()}
        </div>
        {/* .container .custom_board end*/}
      </div>

      <div className='footer'>
        <div className='custom_footcontainer'>
          <div className='row'>
            <div className='col-lg-12 custom_footer'>
              <h5>(주)부산티엔씨</h5>
              <p className='custom_footerp'>
                부산시 동구 충장대로 206 부산항국제여객터미널 3층 3B08
                (부산메디투어센터 &amp; 크루즈라운지)
              </p>
              <ul className='custom_footerul'>
                <li>
                  <span>T</span> 070-8874-9334
                </li>
                <li>
                  <span>F</span> 051-462-9334
                </li>
                <li>
                  <span>E</span> busancruiselounge@gmail.com
                </li>
              </ul>
              <p>Copyright 2023. BUSAN TNC All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
      {/* <ul className="custom_quick">

        <li>
          <a
            href="https://pf.kakao.com/_ixeFTb"
            target="_blank"
            rel="noreferrer"
          >
            <p title="kakao talk"></p>
          </a>
          <p title="kakao talk">Open Chatting</p>
        </li>

        <li>
          <p title="wechat"></p>
          <p title="wechat">ID : Busan_Medicall</p>
        </li>

        <li>
          <p title="whatsapp"></p>
          <p title="whatsapp">ID : Medicall</p>
        </li>

        <li>
          <p title="line"></p>
          <p title="line">ID : busan_medicall</p>
        </li>
      </ul> */}
    </>
  );
};

export default Home;
