import { useEffect, useRef, useState, StyleSheet } from 'react';

import cn from 'classnames';

/* DatePicker */
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


/* bootstrap */
import 'bootstrap/dist/js/bootstrap.bundle.min';

/* CSS */
import '../assets/css/fontawesome.css';
import '../assets/css/custom.css'; /* 수정한 CSS */
import '../assets/css/animated.css';
import '../assets/css/owl.css';
import style from '../components/Modal/Modal.module.css';
/* imgs */
import logo from '../assets/images/tnc_newlogo.jpeg';
import firstplace from '../assets/images/custom_1level_2x.png';
import icon_call from '../assets/images/custom_iconcall.png';
import medibusan from '../assets/images/custom_meditour_2x.png';
import KO from '../assets/images/flag_kr.png';
import US from '../assets/images/flag_us.png';
import RU from '../assets/images/flag_ru.png';
import VI from '../assets/images/flag_vi.png';
import CH from '../assets/images/flag_ch.png';
import insta from '../assets/images/instagram.png';
import facebook from '../assets/images/facebook.png';

/* icon */
import {

  IoIosArrowForward,
  IoIosArrowDropright,
} from 'react-icons/io';

import { HiOutlineMinusSm } from 'react-icons/hi';

import { Link } from 'react-router-dom';
import Popup from '../components/Modal/Popup';
import moment from 'moment';

import { unAuthClient } from '../lib/axios';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";

const RuPage = () => {
  const [popup, setPopup] = useState(false);
  const [best, setBest] = useState([]);
  const [toggleIndex, setToggleIndex] = useState('');
  const [isLoading, SetLoading] = useState(true);
  const [videoSrc, setVideoSrc] = useState(
    'https://player.vimeo.com/video/779141367?h=d4ccfb4baf'
  );
  const [starSrc, setStarSrc] = useState(
    'https://player.vimeo.com/video/779141748?h=09df3f454e'
  );
  const [selectedVideoIndex, setSelectedVideoIndex] = useState('1');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  //모바일 select박스 value에 따라 페이지 전환 때문에 navigate
  const navigate = useNavigate();
  const form = useRef();
  const [initialInputData, SetInitialInputData] = useState({
    user_name: '',
    phone: '',
    user_email: '',
    section: '병원예약', //need to default value
    detail_section: '상급·종합병원',
    message: '',
    user_count: 1,
    start: moment(new Date()).format('YYYY-MM-DD HH:mm'),
    user_Room: 1,
    user_language: 'Russian',
  });
  const togglePopup = (el) => {
    setToggleIndex(el);
    setPopup(!popup);
    console.log(el);
  };
  const sendEmail = async (event) => {
    event.preventDefault();
    const option = {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: initialInputData,
    };
    if(!_.isEmpty(initialInputData.user_name||!_.isEmpty(initialInputData.user_email))) {
      try {
        await unAuthClient
            .post('/sendmail', option);
        window.alert('success');
      } catch (err) {
        console.log('fetch error : ', err);
      } finally{
        window.location.reload();
      }
    }else{
      window.alert('Please enter required items ')
    }
  };

  const updateField = (e) => {
    if (e.target.name === 'section') {
      SetInitialInputData({
        user_name: '',
        phone: '',
        user_email: '',
        section: e.target.value,
        detail_section: '상급·종합병원',
        message: '',
        user_count: 1,
        start: moment(new Date()).format('YYYY-MM-DD HH:mm'),
        user_Room: 1,
        user_language: 'Russian',
      });
      //SetInputData([0]);
    }else{
      SetInitialInputData({
        ...initialInputData,
        [e.target.name]: e.target.value,
      });
    }
    console.log('test Input data :', initialInputData);
  };


  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  /* 호텔 */


  const addedDate = (data) => {
    SetInitialInputData({
      ...initialInputData,
      start: moment(data).format('YYYY-MM-DD HH:mm'),
    });
    console.log('test : ', data);
    console.log(initialInputData);
  };
  const endedDate = (data, e) => {
    SetInitialInputData({
      ...initialInputData,
      finish: moment(data).format('YYYY-MM-DD HH:mm'),
    });
    console.log('finish : ', data);
    console.log(initialInputData);
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  //베스트 3선 자세히 보기
  const best_pop = () => {
    const best_vener = [];
    //let top_img='';
    if (best.length > 0) {
      best.map((el, index) => {
        // switch(el.INDEX){
        //   case 1:
        //     top_img=top01;
        //     break;
        //   case 2:
        //     top_img=top02;
        //     break;
        //   case 3:
        //     top_img=top03;
        //     break;
        // }
        let file = el.FILE_POPUP;
        best_vener.push(
          <div className='custom_bestitems' key={index}>
            <ul>
              {/* <!-- 힐링&웰니스 투어 --> */}
              <li>
                {/* <img src={top_img} alt={el.TITLE} /> */}
                <img src={`/image/${file}`} alt={el.TITLE} />
              </li>
              {/* <!-- img --> */}
              <li>
                <p className='custom_besttitle'>{el.TITLE_ru}</p>
                <p className='custom_besttext'>{el.CONTENT_ru}</p>
              </li>
              {/* <!-- text --> */}
              <li>
                <button
                  className='custom_morebtn'
                  onClick={() => togglePopup(el)}
                >
                  Подробнее
                </button>
              </li>
            </ul>
          </div>
        );
      });
    }
    return best_vener;
  };

  useEffect(() => {
    const fun = async () => {
      try {
        const res = await unAuthClient.get('/amendBest');
        setBest(res.data);
        SetLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    fun();
  }, []);

  const changeVideo = (event) => {
    setVideoSrc(event.target.name);
    setSelectedVideoIndex(event.target.id);
  };

  const changeStarVideo = (event) => {
    setStarSrc(event.target.name);
  };

  return (
    <>
      <header className='header_area header_sticky wow slideInDown'>
        {popup && (
          <Popup
            change='ru'
            best={best}
            index={toggleIndex}
            togglePopup={togglePopup}
          />
        )}
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <nav className='main-nav'>
                {/* <!-- ***** Logo Start ***** --> */}
                <a href='/RuPage' className='logo'>
                   <img src={logo} alt="logo자리" />
                </a>
                {/* <!-- ***** Logo End ***** --> */}

                {/* <!-- ***** Menu Start ***** --> */}
                <ul className='nav'>
                  <li className='scroll-to-section'>
                    <Link to='/'>
                      <img src={US} alt='usa_flag' />
                    </Link>
                  </li>
                  {/* <!-- 영어 --> */}

                  <li className='scroll-to-section'>
                    <Link to='/KorPage'>
                      <img src={KO} alt='SouthKorea_flag' />
                    </Link>
                  </li>
                  {/* <!-- 한국어 --> */}

                  <li className='scroll-to-section'>
                    <Link to='/ChPage'>
                      <img src={CH} alt='China_flag' />
                    </Link>
                  </li>
                  {/* <!-- 중국어 --> */}


                  <li className='scroll-to-section'>
                    <Link to='/ViPage'>
                      <img src={VI} alt='Vietnam_flag' />
                    </Link>
                  </li>
                  {/* <!-- 베트남어 --> */}

                  {/* 23-05-12 소셜미디어 링크 추가 */}
                  <div className='socialMedia_link'>
                    <li className='scroll-to-section'>
                      <Link to='https://www.instagram.com/'>
                        <img src={insta} alt='instagram link' />
                      </Link>
                    </li>
                    <li className='scroll-to-section'>
                      <Link to='https://www.facebook.com/'>
                        <img src={facebook} alt='facebook link' />
                      </Link>
                    </li>
                  </div>
                  {/* <li className="scroll-to-section"><a href="/RuPage">RU</a></li> {/* <!-- 러시아어 --> */}
                  {/* <li className='scroll-to-section custom_login_btn'>
                    <div className='border-first-button'>
                      <Link to='/login'>login</Link>
                    </div>
                  </li>
                  <li className='custom_settingicon'>
                    <Link to='view'>
                      <IoMdSettings />
                    </Link>
                  </li> */}
                </ul>

                <div className='nav_s'>
                  <select
                    onChange={(e) => {
                      console.log(e.target.value);
                      navigate(e.target.value);
                    }}
                  >
                    <option className='scroll-to-section' href='/RuPage'>
                      RU
                    </option>
                    {/* <!-- 러시아어 --> */}
                    <option className='scroll-to-section' value='/'>
                      EN
                    </option>
                    {/* <!-- 영어 --> */}
                    <option className='scroll-to-section' value='/KorPage'>
                      KO
                    </option>
                    {/* <!-- 한글 --> */}
                    <option className='scroll-to-section' value='/ChPage'>
                      CH
                    </option>
                    {/* <!-- 중국어 --> */}
                    <option className='scroll-to-section' value='/ViPage'>
                      VI
                    </option>
                    {/* <!-- 베트남어 --> */}
                  </select>
                  {/* 23-05-12 소셜미디어 링크 추가 */}
                  <div className='socialMedia_link'>
                    <li className='scroll-to-section'>
                      <Link to='https://www.instagram.com/star_doctor_in_busan_ru/'>
                        <img src={insta} alt='instagram link' />
                      </Link>
                    </li>
                    <li className='scroll-to-section'>
                      <Link to='https://www.facebook.com/Star-doctor-in-Busan-ru-101571501683242/'>
                        <img src={facebook} alt='facebook link' />
                      </Link>
                    </li>
                  </div>
                  {/* <a href='#contact'>
                    <div className='order-first-button'>login</div>
                  </a>
                  <div className='custom_setting'>
                    <IoMdSettings className='custom_settingicon' />
                  </div> */}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- ***** Header Area End ***** --> */}

      {/* <!-- 대한민국 대표 의료관광도시 부산! --> */}
      <div
        className='main_banner wow fadeIn'
        id='top'
        data-wow-duration='1s'
        data-wow-delay='0.5s'
      >
        <div className='container custom_header'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='col-lg-12 align-self-center'>
                <div
                  className='left-content show-up header-text wow fadeInLeft'
                  data-wow-duration='1s'
                  data-wow-delay='1s'
                >
                  <div className='col-lg-12'>
                    <h6 className='custom_title'>MEDICAL TECHNOLOGY</h6>
                    <h2 className='custom_subtitle'>
                      Пусан,город-представитель медицинского туризма в Южной
                      Корее
                    </h2>
                    <p className='custom_content'>
                      . Пусан - второй по величине город Кореи и крупнейший
                      портовый город с населением 3,5 миллиона человек. Этот
                      туристический мегаполис с мягким климатом и замечательными
                      природными ресурсами , такими как горы и море, в котором
                      проводятся различные международные мероприятия: Фестиваль
                      фейерверков , Международный кинофестиваль и т.д., был
                      выбран Lonely Planet в 2018 году, как город одного из
                      лучших туристических направлений в Азии , что укрепило его
                      в качестве глобального туристического курорта. Кроме того,
                      высочайшее качество медицинских услуг и высочайший уровень
                      медицинских технологий делают Пусан «городом медицинского
                      туризма, в который хочется поехать» в качестве
                      репрезентативного направления медицинского туризма в Азии,
                      обеспечивая высочайший уровень удовлетворения туристов.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--의료도시 1위 수상 --> */}
      <div id='about' className='about section'>
        {/* <!-- 바탕으로 들어가는 div}
    {/* <div className="row custom_row">
      <div className="col-lg-6 blank">
        {오른쪽 빈공간 bg color만 들어감}
      </div>
      <div className="col-lg-6 bg02img">
        {왼쪽 bg img 들어감}
      </div>
    </div> */}

        <div className='row custom_row'>
          <div className='col-lg-6 bg02img'>
            {/* <!-- 왼쪽 bg img 들어감 --> */}
          </div>
        </div>

        <div className='row custom_float'>
          <div
            className='col-lg-12 align-self-center wow fadeInRight'
            data-wow-duration='1s'
            data-wow-delay='0.5s'
          >
            <div className='section-heading'>
              {/* <!-- 1숫자 --> */}
              <div className='custom_num1img'>
                <img src={firstplace} alt='의료도시 부문1위' />
              </div>
            </div>
            <h2 className='custom_subtitle'>
              Занял 1-е место в рейтинге национального бренда Кореи «Medical
              City»
            </h2>
            <p className='custom_text'>
              Пусан стремится стать центром медицинского туризма в
              Северо-Восточной Азии за счет интенсивного развития индустрии в
              этом направлении. Корея впервые внедряет в медицине ускоритель
              тяжелых частиц(2023), а так же обладает центрами роботизированной
              хирургии высокого мирового уровня. Кроме того, в городе есть 5000
              выдающихся специалистов медицинских институтов, а также блестящие
              медицинские кластеры, такие как медицинская улица Сомён, которые
              позволяют медицинским центрам совместными усилиями предоставлять
              высококачественные медицинские услуги, охватывающие косметическую
              хирургию, дерматологию и стоматологию, а также тяжелые
              заболевания, такие как болезни мозга, сердца и онкология.
            </p>
          </div>
        </div>
      </div>

      {/* 부산 메디컬 센터, 컨시어지 서비스 */}
      <div id='services' className='services section'>
        <div className='container custom_service'>
          <div className='row col-lg-12'>
            <div className='custom_info'>
              <div
                className='section-heading wow fadeInDown custom_info01 custom_short01'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                {/* <img src={picture01} alt='부산메디컬센터' /> */}
                <iframe
                  src='https://player.vimeo.com/video/787477437?h=c382c2818a&amp;badge=0&amp;autoplay=1&loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                  frameborder='0'
                  allow='autoplay; fullscreen; picture-in-picture'
                  allowfullscreen
                  title='쇼츠 동아대학교병원'
                ></iframe>
              </div>

              <div
                className='custom_info02 wow fadeInDown'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                <h4 className='custom_info_title'>Медицинский центр Пусана</h4>
                <h6 className='custom_info_subtext'>
                  Медицинский центр Пусана - это платформа услуг медицинского
                  туризма, которая предоставляет различные услуги для вашего
                  удобства, от трансфера из аэропорта до бронирования отелей,
                  услуг переводчика и предложения оздоровительных турпакетов для
                  медицинских туристов, посещающих Пусан, чтобы они могли
                  наслаждаться медицинским туризмом в Пусане более комфортно и
                  безопасно. Если вам нужны подробные запросы на обслуживание
                  или бронирование больниц, вы можете получить подробную
                  консультацию через мессенджер, электронную почту или
                  стационарный телефон. Мы можем ответить через мессенджер на
                  языках основных стран.
                </h6>
              </div>
            </div>
          </div>
          {/* <!-- row 1 end --> */}
          {/* 테블릿 싸이즈 - CSS 1739 */}
          <div className='row col-lg-12'>
            <div className='custom_info custom_info_s'>
              <div
                className='custom_info02 wow fadeInDown'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                <h4 className='custom_info_title'>Консьерж-сервис</h4>
                <h6 className='custom_info_subtext'>
                  Медицинский центр Пусана - это проект, разработанный
                  Ассоциацией медицинской промышленности Пусана за счет бюджета
                  администрации города Пусана и управляемый Пусанской компанией
                  «TNC», и представляет собой профессиональную платформу
                  помощников, которая избавляет от неудобств медицинских
                  туристов. Мы стараемся предоставлять более качественные услуги
                  иностранным медицинским туристам, посещающим Пусан,
                  предоставляя различную индивидуальную помощь..
                </h6>
              </div>
              <div
                className='section-heading wow fadeInDown custom_info01 custom_short02'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                {/* <img src={picture02} alt='부산메디컬센터' /> */}
                <iframe
                  src='https://player.vimeo.com/video/787477407?h=2bbea0f96b&amp;badge=0&amp;autoplay=1&loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                  frameborder='0'
                  allow='autoplay; fullscreen; picture-in-picture'
                  allowfullscreen
                  title='쇼츠 메디컬센터 김해'
                ></iframe>
              </div>
            </div>
          </div>
          {/* <!-- row 2 end --> */}
        </div>
      </div>

      {/* 의료기관, 호텔 소개 바로가기 */}
      <div id='banners' className='banners'>
        <div className='custom_bannerdiv'>
          <div className='custom_medicalbusanlink'>
            <img src={medibusan} alt='메디컬부산' />
          </div>
          <div className='custom_banners'>
            <div className='banner_wrap'>
              <div className='custom_banner01'>
                <p>
                  Введение медицинских
                  <br />
                  учреждений
                </p>
                <a href=''>
                  <button
                    onClick={() =>
                      window.open(
                        'https://www.bsmeditour.go.kr/medicalInstitution.do',
                        '_blank'
                      )
                    }
                  >
                    Комбинация клавиш
                    <IoIosArrowForward className='custom_icon' />
                  </button>
                </a>
              </div>
              <div className='custom_banner02'>
                <p>Введение в отель</p>
                <a href=''>
                  <button
                    onClick={() =>
                      window.open(
                        'https://www.bsmeditour.go.kr/AboutHotel.do',
                        '_blank'
                      )
                    }
                  >
                    Комбинация клавиш
                    <IoIosArrowForward className='custom_icon' />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 클리닉 비디오, 리스트 */}
      <div id='clinic_videos' className='clinic_videos section'>
        <div className='container'>
          <div className='custom_video_list_wrap'>
            {/* <div className='vr_video_list'> */}
            <div className='clinic_video'>
              <iframe
                src={`${videoSrc}`}
                frameborder='0'
                allow='autoplay; fullscreen'
                allowfullscreen
                title='custom_video'
                className='video01'
              ></iframe>
            </div>
            <div className='videoList'>
              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '1' ? '#274fd5' : '',
                    color: selectedVideoIndex === '1' ? '#fff' : '',
                  }}
                  id='1'
                  name='https://player.vimeo.com/video/779141367'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 담낭 클리닉 */}
                {'Клиника желчного пузыря'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '2' ? '#274fd5' : '',
                    color: selectedVideoIndex === '2' ? '#fff' : '',
                  }}
                  id='2'
                  name='https://player.vimeo.com/video/779140560?h=31f55f5c96'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 피부미용 클리닉 */}
                {'Клиника красоты кожи'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '3' ? '#274fd5' : '',
                    color: selectedVideoIndex === '3' ? '#fff' : '',
                  }}
                  id='3'
                  name='https://player.vimeo.com/video/779140291?h=cf0fcefc1f'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 심장흉통 클리닉 */}
                {'Клиника сердечной недостаточности'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '4' ? '#274fd5' : '',
                    color: selectedVideoIndex === '4' ? '#fff' : '',
                  }}
                  id='4'
                  name='https://player.vimeo.com/video/779140040?h=a160ad73b4'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 면역증강 클리닉 */}
                {'Клиника повышения иммунитета'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '5' ? '#274fd5' : '',
                    color: selectedVideoIndex === '5' ? '#fff' : '',
                  }}
                  id='5'
                  name='https://player.vimeo.com/video/779139848?h=fb84142a78'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 하지정맥 클리닉 */}
                {'Клиника варикозного расширения вен'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '6' ? '#274fd5' : '',
                    color: selectedVideoIndex === '6' ? '#fff' : '',
                  }}
                  id='6'
                  name='https://player.vimeo.com/video/779139775?h=00b6818446'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 재건성형 클리닉 */}
                {'Реконструктивная клиника'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '7' ? '#274fd5' : '',
                    color: selectedVideoIndex === '7' ? '#fff' : '',
                  }}
                  id='7'
                  name='https://player.vimeo.com/video/779138855?h=5e4d0a9ee9'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 혈액질환 클리닉 */}
                {'Клиника заболеваний крови'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '8' ? '#274fd5' : '',
                    color: selectedVideoIndex === '8' ? '#fff' : '',
                  }}
                  id='8'
                  name='https://player.vimeo.com/video/779138691?h=1b0bb3e972'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 시력교정 클리닉 */}
                {'Клиника коррекции зрения'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '9' ? '#274fd5' : '',
                    color: selectedVideoIndex === '9' ? '#fff' : '',
                  }}
                  id='9'
                  name='https://player.vimeo.com/video/779117354?h=8d82640a70'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 소아외과 클리닉 */}
                {'Детская хирургическая клиника'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '10' ? '#274fd5' : '',
                    color: selectedVideoIndex === '10' ? '#fff' : '',
                  }}
                  id='10'
                  name='https://player.vimeo.com/video/783852470'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 폐암 클리닉 */}
                {'Рак лёгких'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>
              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '11' ? '#274fd5' : '',
                    color: selectedVideoIndex === '11' ? '#fff' : '',
                  }}
                  id='11'
                  name='https://player.vimeo.com/video/783852754'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 대장암 클리닉 */}
                {'Рак толстой кишки'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>
              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '12' ? '#274fd5' : '',
                    color: selectedVideoIndex === '12' ? '#fff' : '',
                  }}
                  id='12'
                  name='https://player.vimeo.com/video/784945045'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 내분비*/}
                {'Эндокринология'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>
              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '13' ? '#274fd5' : '',
                    color: selectedVideoIndex === '13' ? '#fff' : '',
                  }}
                  id='13'
                  name='https://player.vimeo.com/video/891732963'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 두드러기*/}
                {'аллергия'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>
              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '14' ? '#274fd5' : '',
                    color: selectedVideoIndex === '14' ? '#fff' : '',
                  }}
                  id='14'
                  name='https://player.vimeo.com/video/891737942'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 뇌전증*/}
                {'Электроэнцефалия'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>
              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '15' ? '#274fd5' : '',
                    color: selectedVideoIndex === '15' ? '#fff' : '',
                  }}
                  id='15'
                  name='https://player.vimeo.com/video/891739426'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 정형외과*/}
                {'травматология и ортопедия'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>
              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '16' ? '#274fd5' : '',
                    color: selectedVideoIndex === '16' ? '#fff' : '',
                  }}
                  id='16'
                  name='https://player.vimeo.com/video/891740440'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 갑상선*/}
                {'щитовидная'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>
              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '17' ? '#274fd5' : '',
                    color: selectedVideoIndex === '17' ? '#fff' : '',
                  }}
                  id='17'
                  name='https://player.vimeo.com/video/891741311'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 치아 임플란트*/}
                {'Имплантаты зубов'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '18' ? '#274fd5' : '',
                    color: selectedVideoIndex === '18' ? '#fff' : '',
                  }}
                  id='18'
                  name='https://player.vimeo.com/video/891743185'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 난임*/}
                {'Наньим'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '19' ? '#274fd5' : '',
                    color: selectedVideoIndex === '19' ? '#fff' : '',
                  }}
                  id='19'
                  name='https://player.vimeo.com/video/898034075'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 부인암 */}
                {'Рак жены'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '20' ? '#274fd5' : '',
                    color: selectedVideoIndex === '20' ? '#fff' : '',
                  }}
                  id='20'
                  name='https://player.vimeo.com/video/898040355'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 난청 */}
                {'глухота́'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '21' ? '#274fd5' : '',
                    color: selectedVideoIndex === '21' ? '#fff' : '',
                  }}
                  id='21'
                  name='https://player.vimeo.com/video/899719606'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 유방암 */}
                {'рак груди'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button className='videoList_btn'>라인맞추기용</button>
            </div>
          </div>
        </div>
      </div>

      {/* 예약 form */}
      <div id='new_contact' className='new_contact section'>
        <div className='container container_s'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='naccs'>
                <div className='grid custom_accodian'>
                  <div className='row custom_contact'>
                    <div className='col-lg-3 custom_call'>
                      {/* <!-- 전화 상담 --> */}
                      <ul>
                        <li>
                          <img src={icon_call} alt='문의전화' />
                        </li>
                        {/* <!-- icon img --> */}
                        <li>
                          <p className='custom_ask'>
                            Если у вас есть вопросы, мы вежливо консультируем
                            вас.
                          </p>
                        </li>
                        <li>
                          <p className='custom_servicenumber'>
                            +82 70-8874-9334
                            +82 10-2127-9334
                          </p>
                        </li>
                      </ul>
                      {/* <a href='#'> */}
                      <button className='custom_call_btn'>
                        Телефонные консультации
                      </button>
                      {/* </a> */}
                    </div>
                    <div
                      className='col-lg-8 custom_accordion nacc accordion'
                      id='accordionExample'
                    >
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingOne'>
                          <button
                            className='accordion-button'
                            type='button'
                            name='section'
                            onClick={updateField}
                            value='병원예약'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseOne'
                            aria-expanded='true'
                            aria-controls='collapseOne'
                          >
                            {/* <img src={reserve01} alt="Hospital appointment" /> */}
                            Бронирование больницы
                          </button>
                        </h2>
                        <div
                          id='collapseOne'
                          className='accordion-collapse collapse show'
                          aria-labelledby='headingOne'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                              className='custom_form'
                              ref={form}
                              onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>Имя</label>
                                <input
                                  type='text'
                                  name='user_name'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>Контактный номер</label>
                                <input
                                  id='phone'
                                  type='tel'
                                  name='phone'
                                  onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>электронный адрес</label>
                                <input
                                  type='email'
                                  name='user_email'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>Медицинское направление</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option value='상급·종합병원'>
                                    поликлиника•многопрофильная больница
                                  </option>
                                  <option value='종합검진'>
                                    Общий медосмотр
                                  </option>
                                  <option value='정형외과'>
                                    травматология и ортопедия
                                  </option>
                                  <option value='산부인과'>геникология</option>
                                  <option value='성형외과'>
                                    пластическая хирургия
                                  </option>
                                  <option value='피부과'>
                                    дерматология,уход за кожей
                                  </option>
                                  <option value='치과'>стоматология</option>
                                  <option value='안과'>офтальмология</option>
                                  <option value='한방'>
                                    нетрадиционная медицина
                                  </option>
                                  <option value='기타'>другое</option>
                                </select>
                              </div>
                              <div className='custom_language divs'>
                                <label>Язык</label>
                                <div className='custom_radioselect'>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='English'
                                      onChange={updateField}
                                    />
                                    <p>Английский язык</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='Russian'
                                      defaultChecked
                                      onChange={updateField}
                                    />
                                    <p>Русский язык</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='Chinese'
                                      onChange={updateField}
                                    />
                                    <p>Китайский язык</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Vietnamese'
                                        onChange={updateField}
                                    />
                                    <p>Вьетнамский</p>
                                  </div>
                                </div>
                              </div>
                              <div className='custom_contentbox divs'>
                                <label>Содержание</label>
                                <textarea
                                  name='message'
                                  onChange={updateField}
                                />
                              </div>
                              <input
                                type='submit'
                                value='отправить email'
                                className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* 병원 예약 */}
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingTwo'>
                          <button
                            className='accordion-button collapsed'
                            name='section'
                            onClick={updateField}
                            value='컨시어지'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseTwo'
                            aria-expanded='false'
                            aria-controls='collapseTwo'
                          >
                            {/* <img src={reserve02} alt="Concierge" /> */}
                            Помощник
                          </button>
                        </h2>
                        <div
                          id='collapseTwo'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingTwo'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                              className='custom_form'
                              ref={form}
                              onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>Имя</label>
                                <input
                                  type='text'
                                  name='user_name'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>Контактный номер</label>
                                <input
                                  id='phone'
                                  type='tel'
                                  name='phone'
                                  onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>электронный адрес</label>
                                <input
                                  type='email'
                                  name='user_email'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>Медицинское направление</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option value='상급·종합병원'>
                                    поликлиника•многопрофильная больница
                                  </option>
                                  <option value='종합검진'>
                                    Общий медосмотр
                                  </option>
                                  <option value='정형외과'>
                                    травматология и ортопедия
                                  </option>
                                  <option value='산부인과 '>геникология</option>
                                  <option value='성형외과'>
                                    пластическая хирургия
                                  </option>
                                  <option value='피부과'>
                                    дерматология,уход за кожей
                                  </option>
                                  <option value='치과'>стоматология</option>
                                  <option value='안과 '>офтальмология</option>
                                  <option value='한방'>
                                    нетрадиционная медицина
                                  </option>
                                  <option value='기타'>другое</option>
                                </select>
                              </div>
                              <div className='custom_language divs'>
                                <label>Язык</label>
                                <div className='custom_radioselect'>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='English'
                                      onChange={updateField}
                                    />
                                    <p>Английский язык</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='Russian'
                                      defaultChecked
                                      onChange={updateField}
                                    />
                                    <p>Русский язык</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='Chinese'
                                      onChange={updateField}
                                    />
                                    <p>Китайский язык</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Vietnamese'
                                        onChange={updateField}
                                    />
                                    <p>Вьетнамский</p>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={cn(
                                  style.concierge_div,
                                  style.form_div
                                )}
                              >
                                <div className={style.dateset}>
                                  <p>Выбор даты</p>
                                  <DatePicker
                                    name='registration_date'
                                    selected={startDate}
                                    onChange={(data, e) => {
                                      setStartDate(data);
                                      addedDate(data, e);
                                    }}
                                    showTimeSelect
                                    filterTime={filterPassedTime}
                                    dateFormat='MMMM d, yyyy h:mm aa'
                                  />
                                </div>
                                <div className={style.memberset}>
                                  <p>Количество человек</p>
                                  <select
                                    onChange={updateField}
                                    name='user_count'
                                  >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                  </select>
                                </div>
                                <div className={style.destinationset}>
                                  <p>Пункт назначения / назначения</p>
                                  <input
                                    type='text'
                                    placeholder=''
                                    name='destination'
                                    onChange={updateField}
                                  />
                                </div>
                                {/* <button className={style.carbtn}>차량예약</button> */}
                              </div>
                              <input
                                type='submit'
                                value='отправить email'
                                className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingThree'>
                          <button
                            className='accordion-button collapsed'
                            name='section'
                            onClick={updateField}
                            value='호텔예약'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseThree'
                            aria-expanded='false'
                            aria-controls='collapseThree'
                          >
                            {/* <img className="btn_icon" src={reserve03} alt="Hotel reservation" /> */}
                            Бронирование отеля
                          </button>
                        </h2>
                        <div
                          id='collapseThree'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingThree'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                              className='custom_form'
                              ref={form}
                              onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>Имя</label>
                                <input
                                  type='text'
                                  name='user_name'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>Контактный номер</label>
                                <input
                                  id='phone'
                                  type='tel'
                                  name='phone'
                                  onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>электронный адрес</label>
                                <input
                                  type='email'
                                  name='user_email'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>Медицинское направление</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option value='상급·종합병원'>
                                    поликлиника•многопрофильная больница
                                  </option>
                                  <option value='종합검진'>
                                    Общий медосмотр
                                  </option>
                                  <option value='정형외과'>
                                    травматология и ортопедия
                                  </option>
                                  <option value='산부인과 '>геникология</option>
                                  <option value='성형외과'>
                                    пластическая хирургия
                                  </option>
                                  <option value='피부과'>
                                    дерматология,уход за кожей
                                  </option>
                                  <option value='치과'>стоматология</option>
                                  <option value='안과 '>офтальмология</option>
                                  <option value='한방'>
                                    нетрадиционная медицина
                                  </option>
                                  <option value='기타'>другое</option>
                                </select>
                              </div>
                              <div className='custom_hotel divs'>
                                <label>Название отеля</label>
                                <input
                                  type='hotel'
                                  name='hotel'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_language divs'>
                                <label>Язык</label>
                                <div className='custom_radioselect'>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='English'
                                      onChange={updateField}
                                    />
                                    <p>Английский язык</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='Russian'
                                      defaultChecked
                                      onChange={updateField}
                                    />
                                    <p>Русский язык</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='Chinese'
                                      onChange={updateField}
                                    />
                                    <p>Китайский язык</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Vietnamese'
                                        onChange={updateField}
                                    />
                                    <p>Вьетнамский</p>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={cn(style.hotel_div, style.form_div)}
                              >
                                <div
                                  className={cn(
                                    style.dateset,
                                    style.hotel_dateset
                                  )}
                                >
                                  <p>Выбор даты</p>
                                  <div className={style.datepickeralign}>
                                    <DatePicker
                                      selected={startDate}
                                      onChange={(date) => {
                                        setStartDate(date);
                                        addedDate(date);
                                      }}
                                      selectsStart
                                      startDate={startDate}
                                      endDate={endDate}
                                      minDate={new Date()}
                                      name='start'
                                    />
                                    <HiOutlineMinusSm
                                      className={style.icon_line}
                                    />
                                    <DatePicker
                                      name='finish'
                                      selected={endDate}
                                      onChange={(date) => {
                                        setEndDate(date);
                                        endedDate(date);
                                      }}
                                      selectsEnd
                                      startDate={startDate}
                                      endDate={endDate}
                                      minDate={startDate}
                                    />
                                  </div>
                                </div>
                                <div className={style.memberset}>
                                  <p>Количество человек</p>
                                  <select
                                    onChange={updateField}
                                    name='user_count'
                                  >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                  </select>
                                </div>
                                <div className={style.hotelroom}>
                                  <p>Комната</p>
                                  <select
                                    onChange={updateField}
                                    name='user_Room'
                                  >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                  </select>
                                </div>
                                {/* <button className={style.carbtn}>차량예약</button> */}
                              </div>
                              {/* .hotel_div end */}
                              <input
                                type='submit'
                                value='отправить email'
                                className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingFour'>
                          <button
                            className='accordion-button collapsed'
                            name='section'
                            onClick={updateField}
                            value='통역예약'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseFour'
                            aria-expanded='false'
                            aria-controls='collapseFour'
                          >
                            {/* <img src={reserve04} alt="translate" /> */}
                            бронирование переводчика
                          </button>
                        </h2>
                        <div
                          id='collapseFour'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingFour'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                              className='custom_form'
                              ref={form}
                              onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>Имя</label>
                                <input
                                  type='text'
                                  name='user_name'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>Контактный номер</label>
                                <input
                                  id='phone'
                                  type='tel'
                                  name='phone'
                                  onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>электронный адресil</label>
                                <input
                                  type='email'
                                  name='user_email'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>Медицинское направление</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option value='상급·종합병원'>
                                    поликлиника•многопрофильная больница
                                  </option>
                                  <option value='종합검진'>
                                    Общий медосмотр
                                  </option>
                                  <option value='정형외과'>
                                    травматология и ортопедия
                                  </option>
                                  <option value='산부인과 '>геникология</option>
                                  <option value='성형외과'>
                                    пластическая хирургия
                                  </option>
                                  <option value='피부과'>
                                    дерматология,уход за кожей
                                  </option>
                                  <option value='치과'>стоматология</option>
                                  <option value='안과 '>офтальмология</option>
                                  <option value='한방'>
                                    нетрадиционная медицина
                                  </option>
                                  <option value='기타'>другое</option>
                                </select>
                              </div>
                              <div
                                className={cn(
                                  style.translate_div,
                                  style.form_div
                                )}
                              >
                                <div className={style.langset}>
                                  <p>Выбор языка</p>
                                  <select
                                    onChange={updateField}
                                    name='user_language'
                                  >
                                    <option value='English'>
                                      китайский язык
                                    </option>
                                    <option value='Chinese'>английский</option>
                                    <option value="Russian" selected>Русский</option>
                                  </select>
                                </div>
                                <div className={style.dateset}>
                                  <p>Выбор даты</p>
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(data, e) => {
                                      setStartDate(data);
                                      addedDate(data, e);
                                    }}
                                    showTimeSelect
                                    filterTime={filterPassedTime}
                                    dateFormat='MMMM d, yyyy h:mm aa'
                                  />
                                </div>
                                <div className={style.destinationset}>
                                  <p>Пункт назначения/ назначения</p>
                                  <input
                                    type='text'
                                    placeholder=''
                                    name='destination'
                                    onChange={updateField}
                                  />
                                </div>
                                {/* <button className={style.carbtn}>차량예약</button> */}
                              </div>
                              <input
                                type='submit'
                                value='отправить email'
                                className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* accordion end */}
                  </div>
                  {/*  <!-- .row end --> */}
                </div>
                {/* <!-- .grid end --> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* VR 영상 23-05-12 위치 이동 */}
      <div id='vr_videos' className='col-lg-12 vr_videos'>
        <div className='container'>
          <div className='vr_video_wrap'>
            <ul className='vr_video_list'>
              <li>
                <iframe
                  src='https://player.vimeo.com/video/783849715?h=28004e4a8a&badge=0&autopause=0&player_id=0&app_id=58479/embed'
                  frameborder='0'
                  allow='autoplay; fullscreen'
                  allowfullscreen
                  title='custom_video'
                ></iframe>
              </li>
              <li>
                <iframe
                  src={`${starSrc}`}
                  frameborder='0'
                  allow='autoplay; fullscreen'
                  allowfullscreen
                  title='custom_video'
                ></iframe>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* BEST 3 Collection */}
      <div id='board' className='board col-lg-12'>
        <div className='container custom_board'>
          <div className='section-heading'>
            <h4>BEST 3 Collection</h4>
          </div>
          {isLoading ? null : best_pop()}
          {/* <div className="custom_bestitems">
          <ul>
            {/* <!-- 힐링&웰니스 투어 --> /}
            <li>
              <img src={top01} alt="힐링&amp;웰니스 투어" />
            </li> {/* <!-- img --> /}
            <li>
              <p className="custom_besttitle">Healing&amp;Wellness Tour</p>
              <p className="custom_besttext">Tradition Clay Kiln Tour</p>
            </li> {/* <!-- text --> /}
            <li><button className="custom_morebtn"> View Detail </button></li>
          </ul>
          <ul>
            {/* <!-- 뷰티&인생샷 투어 --> /}
            <li>
              <img src={top02} alt="뷰티&amp;인생샷 투어" />
            </li> {/* <!-- img --> /}
            <li>
              <p className="custom_besttitle">Beauty&amp;Photo shoot Tour</p>
              <p className="custom_besttext">Like an actor today</p>
            </li> {/* <!-- text --> /}
            <li><button className="custom_morebtn"> View Detail </button></li>
          </ul>
          <ul>
            {/* <!-- 부산 원도심 투어 --> /}
            <li>
              <img src={top03} alt="부산 원도심 투어" />
            </li> {/* <!-- img --> /}
            <li>
              <p className="custom_besttitle">Busan Original Downtown Tour</p>
              <p className="custom_besttext">Dong-gu ibagu street &amp; International Market</p>
            </li>{/*  <!-- text --> /}
            <li><button className="custom_morebtn"> View Detail </button></li>
          </ul>
        </div> */}
        </div>
        {/* .container .custom_board end*/}
      </div>

      <div className='footer'>
        <div className='custom_footcontainer'>
          <div className='row'>
            <div className='col-lg-12 custom_footer'>
              <h5>АО. TNC г.Пусан</h5>
              <p className='custom_footerp'>
                г.Пусан Тонгу Чунчандеро 206 Международный пассажирский терминал
                порта Пусан 3 этаж 3В08 (Busan Meditour Center&amp;cruiselounge)
              </p>
              <ul className='custom_footerul'>
                <li>
                  <span>T</span> 070-8874-9334
                </li>
                <li>
                  <span>F</span> 051-462-9334
                </li>
                <li>
                  <span>E</span> busancruiselounge@gmail.com
                </li>
              </ul>
              <p>Copyright 2023. BUSAN TNC All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>

      {/* <ul className="custom_quick">

        <li>
          <a
            href="https://pf.kakao.com/_ixeFTb"
            target="_blank"
            rel="noreferrer"
          >
            <p title="kakao talk"></p>
          </a>
          <p title="kakao talk">Open Chatting</p>
        </li>

        <li>
          <p title="wechat"></p>
          <p title="wechat">ID : Busan_Medicall</p>
        </li>

        <li>
          <p title="whatsapp"></p>
          <p title="whatsapp">ID : Medicall</p>
        </li>

        <li>
          <p title="line"></p>
          <p title="line">ID : busan_medicall</p>
        </li>
      </ul> */}
    </>
  );
};

export default RuPage;
