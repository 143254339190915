import { useEffect, useRef, useState, StyleSheet } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

/* DatePicker */
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

/* bootstrap */
import 'bootstrap/dist/js/bootstrap.bundle.min';
/* CSS */
import '../assets/css/fontawesome.css';
import '../assets/css/custom.css'; /* 수정한 CSS */
import '../assets/css/animated.css';
import '../assets/css/owl.css';
import style from '../components/Modal/Modal.module.css';

/* imgs */
import logo from '../assets/images/tnc_newlogo.jpeg';
import firstplace from '../assets/images/custom_1level_2x.png';
import icon_call from '../assets/images/custom_iconcall.png';
import medibusan from '../assets/images/custom_meditour_2x.png';
import KO from '../assets/images/flag_kr.png';
import US from '../assets/images/flag_us.png';
import RU from '../assets/images/flag_ru.png';
import CH from '../assets/images/flag_ch.png';
import VI from '../assets/images/flag_vi.png';
import insta from '../assets/images/instagram.png';
import facebook from '../assets/images/facebook.png';
/* icon */
import {
  IoMdSettings,
  IoIosArrowForward,
  IoIosArrowDropright,
} from 'react-icons/io';
import { HiOutlineMinusSm } from 'react-icons/hi';

import { Link } from 'react-router-dom';
import Popup from '../components/Modal/Popup';
import moment from 'moment';

import { unAuthClient } from '../lib/axios';
import _ from 'lodash';

const Engpage = () => {
  const [popup, setPopup] = useState(false);
  const [best, setBest] = useState([]);
  const [toggleIndex, setToggleIndex] = useState('');
  const [isLoading, SetLoading] = useState(true);
  const [videoSrc, setVideoSrc] = useState(
    'https://player.vimeo.com/video/779140621?h=cba8fc7e59'
  );
  const [starSrc, setStarSrc] = useState(
    'https://player.vimeo.com/video/779141748?h=09df3f454e'
  );

  const [selectedVideoIndex, setSelectedVideoIndex] = useState('1');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  //모바일 select박스 value에 따라 페이지 전환 때문에 navigate
  const navigate = useNavigate();
  const form = useRef();
  const [initialInputData, SetInitialInputData] = useState({
    user_name: '',
    phone: '',
    user_email: '',
    section: '병원예약', //need to default value
    detail_section: '상급·종합병원',
    message: '',
    user_count: 1,
    start: moment(new Date()).format('YYYY-MM-DD HH:mm'),
    user_Room: 1,
    user_language: 'English',
  });
  const [isActiveLanguage,setIsActiveLanguage] =useState(false);

  const togglePopup = (el) => {
    setToggleIndex(el);
    setPopup(!popup);
  };

  const sendEmail = async (event) => {
    event.preventDefault();
    let url = '/sendmail';
    const option = {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: initialInputData,
    };
    if(!_.isEmpty(initialInputData.user_name||!_.isEmpty(initialInputData.user_email))) {
      try {
        await unAuthClient
            .post('/sendmail', option);
        window.alert('success');
      } catch (err) {
        console.log('fetch error : ', err);
      } finally{
        window.location.reload();
      }
    }else{
      window.alert('Please enter required items ')
    }
  };

  const updateField = (e) => {
    if (e.target.name === 'section') {
      SetInitialInputData({
        user_name: '',
        phone: '',
        user_email: '',
        section: e.target.value,
        detail_section: '상급·종합병원',
        message: '',
        user_count: 1,
        start: moment(new Date()).format('YYYY-MM-DD HH:mm'),
        user_Room: 1,
        user_language: 'English',
      });
      setIsActiveLanguage(false);
    }else{
      SetInitialInputData({
        ...initialInputData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  /* 호텔 */

  const addedDate = (data) => {
    SetInitialInputData({
      ...initialInputData,
      start: moment(data).format('YYYY-MM-DD HH:mm'),
    });
  };
  const endedDate = (data, e) => {
    SetInitialInputData({
      ...initialInputData,
      finish: moment(data).format('YYYY-MM-DD HH:mm'),
    });
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  //베스트 3선 자세히 보기
  const best_pop = () => {
    const best_vener = [];
    if (best.length > 0) {
      best.map((el, index) => {
        const file = el.FILE_POPUP;
        best_vener.push(
          <div className='custom_bestitems' key={index}>
            <ul>
              {/* <!-- 힐링&웰니스 투어 --> */}
              <li>
                {/* <img src={top_img} alt={el.TITLE_en} /> */}
                <img src={`/image/${file}`} alt='베스트 3선 이미지' />
              </li>
              {/* <!-- img --> */}
              <li>
                <p className='custom_besttitle'>{el.TITLE_en}</p>
                <p className='custom_besttext'>{el.CONTENT_en}</p>
              </li>
              {/* <!-- text --> */}
              <li>
                <button
                  className='custom_morebtn'
                  onClick={() => togglePopup(el)}
                >
                  Read more
                </button>
              </li>
            </ul>
          </div>
        );
      });
    }
    return best_vener;
  };

  useEffect(() => {
    const fun = async () => {
      try {
        const res = await unAuthClient.get('/amendBest');
        setBest(res.data);
        SetLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    fun();
  }, []);

  //기덕님 채팅기능

  // useEffect(() => {
  //   const script = document.createElement('script')
  //   script.src = 'https://developers.kakao.com/sdk/js/kakao.js'
  //   script.async = true
  //   document.body.append(script)
  //   createKakaoButton()
  //   return () => {
  //     document.body.removeChild(script)
  //   }
  // }, [])

  // const createKakaoButton = () => {
  //   if (window.Kakao) {
  //     console.log('kakao access');
  //     const kakao = window.Kakao
  //     if (!kakao.isInitialized()) {
  //       console.log('kakao initialize');
  //       kakao.init('d3316914d086bd71d8372df154d6cc11');
  //     }
  //     kakao.Channel.createChatButton({
  //       container: '#kakao-talk-channel-chat-button',
  //       channelPublicId: '_BxgeCb'
  //     });
  //   }
  //   else {
  //     console.log('can not kakao access');
  //     console.log('??? : ', window.kakao)
  //   }
  // }
  const changeVideo = (event) => {
    setVideoSrc(event.target.name);
    setSelectedVideoIndex(event.target.id);
  };

  const changeStarVideo = (event) => {
    setStarSrc(event.target.name);
  };

  return (
    <>
      <header className='header_area header_sticky wow slideInDown'>
        {popup && (
          <Popup
            change='en'
            best={best}
            index={toggleIndex}
            togglePopup={togglePopup}
          />
        )}
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <nav className='main-nav'>
                {/* <!-- ***** Logo Start ***** --> */}
                <a href='/' className='logo'>
                   <img src={logo} alt="logo자리" />
                </a>
                {/* <!-- ***** Logo End ***** --> */}

                {/* <!-- ***** Menu Start ***** --> */}
                <ul className='nav'>
                  {/* <li className="scroll-to-section"><a href="/">EN</a></li> */}
                  {/* <!-- 영어 --> */}
                  <li className='scroll-to-section'>
                    <Link to='/KorPage'>
                      <img src={KO} alt='SouthKorea_flag' />
                    </Link>
                  </li>
                  {/* <!-- 한국어 --> */}

                  <li className='scroll-to-section'>
                    <Link to='/ChPage'>
                      <img src={CH} alt='China_flag' />
                    </Link>
                  </li>
                  {/* <!-- 중국어 --> */}

                  <li className='scroll-to-section'>
                    <Link to='/RuPage'>
                      <img src={RU} alt='Russia_flag' />
                    </Link>
                  </li>
                  {/* <!-- 러시아어 --> */}

                  <li className='scroll-to-section'>
                    <Link to='/ViPage'>
                      <img src={VI} alt='Vietnam_flag' />
                    </Link>
                  </li>
                  {/* <!-- 베트남어 --> */}

                  {/* 23-05-12 소셜미디어 링크 추가 */}
                  <div className='socialMedia_link'>
                    <li className='scroll-to-section'>
                      <Link to='https://www.instagram.com/star_doctor_in_busan_eng/'>
                        <img src={insta} alt='instagram link' />
                      </Link>
                    </li>
                    <li className='scroll-to-section'>
                      <Link to='https://www.facebook.com/Star-doctor-in-busan-eng-112131227281477/'>
                        <img src={facebook} alt='facebook link' />
                      </Link>
                    </li>
                  </div>
                  {/* <li className='scroll-to-section custom_login_btn'>
                    <div className='border-first-button'>
                      <Link to='/login'>login</Link>
                    </div>
                  </li>
                  <li className='custom_settingicon'>
                    <Link to='view'>
                      <IoMdSettings />
                    </Link>
                  </li> */}
                </ul>

                <div className='nav_s'>
                  <select
                    onChange={(e) => {
                      console.log(e.target.value);
                      navigate(e.target.value);
                    }}
                  >
                    <option className='scroll-to-section' href='/Home'>
                      EN
                    </option>
                    {/* <!-- 영어 --> */}
                    <option className='scroll-to-section' value='/KorPage'>
                      KO
                    </option>
                    {/* <!-- 한글 --> */}
                    <option className='scroll-to-section' value='/ChPage'>
                      CH
                    </option>
                    {/* <!-- 중국어 --> */}
                    <option className='scroll-to-section' value='/RuPage'>
                      RU
                    </option>
                    {/* <!-- 러시아어 --> */}
                    <option className='scroll-to-section' value='/ViPage'>
                      VI
                    </option>
                    {/* <!-- 베트남어 --> */}
                  </select>
                  {/* 23-05-12 소셜미디어 링크 추가 */}
                  <div className='socialMedia_link'>
                    <li className='scroll-to-section'>
                      <Link to='https://www.instagram.com/'>
                        <img src={insta} alt='instagram link' />
                      </Link>
                    </li>
                    <li className='scroll-to-section'>
                      <Link to='https://www.facebook.com/'>
                        <img src={facebook} alt='facebook link' />
                      </Link>
                    </li>
                  </div>
                  {/* <a href='#contact'>
                    <div className='order-first-button'>login</div>
                  </a>
                  <div className='custom_setting'>
                    <IoMdSettings className='custom_settingicon' />
                  </div> */}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- ***** Header Area End ***** --> */}

      {/* <!-- 대한민국 대표 의료관광도시 부산! --> */}
      <div
        className='main_banner wow fadeIn'
        id='top'
        data-wow-duration='1s'
        data-wow-delay='0.5s'
      >
        <div className='container custom_header'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='col-lg-12 align-self-center'>
                <div
                  className='left-content show-up header-text wow fadeInLeft'
                  data-wow-duration='1s'
                  data-wow-delay='1s'
                >
                  <div className='col-lg-12'>
                    <h6 className='custom_title'>MEDICAL TECHNOLOGY</h6>
                    <h2 className='custom_subtitle'>
                      Korea's representative medical tourism city, Busan!
                    </h2>
                    <p className='custom_content'>
                      Busan is 2nd biggest city with 3.5 million citizens, and
                      is the biggest port city in korea. The welcoming weather
                      throughout the entire year, along with beautiful mountains
                      and oceans from the heaven, as well as international
                      festivals like Busan Fireworks Festival and Busan
                      International Film Festival make Busan one of the most
                      interesting tourist cities in the world. It has been
                      chosen as number one tourist city in Asia (2018) by Lonely
                      Planet, Now Busan is known globally as a popular tourist
                      destination. In addition, the highest-quality medical
                      services and the highest-quality medical technology are
                      creating "Busan, the medical tourism city you want to
                      visit," as a representative medical tourist destination in
                      Asia, and provide the best satisfaction to tourists.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--의료도시 1위 수상 --> */}
      <div id='about' className='about section'>
        {/* <!-- 바탕으로 들어가는 div}
    {/* <div className="row custom_row">
      <div className="col-lg-6 blank">
        {오른쪽 빈공간 bg color만 들어감}
      </div>
      <div className="col-lg-6 bg02img">
        {왼쪽 bg img 들어감}
      </div>
    </div> */}

        <div className='row custom_row'>
          <div className='col-lg-6 bg02img'>
            {/* <!-- 왼쪽 bg img 들어감 --> */}
          </div>
        </div>

        <div className='row custom_float'>
          <div
            className='col-lg-12 align-self-center wow fadeInRight'
            data-wow-duration='1s'
            data-wow-delay='0.5s'
          >
            <div className='section-heading'>
              {/* <!-- 1숫자 --> */}
              <div className='custom_num1img'>
                <img src={firstplace} alt='의료도시 부문1위' />
              </div>
            </div>
            <h2 className='custom_subtitle'>
              1st place for the best medical city by Korea's National Brand
            </h2>
            <p className='custom_text'>
              By focusing on medical industry, Busan is well-set to be a hot
              tourist site for medical trips in North-east Asia and has
              established world-class medical infrastructure, by employing heavy
              particle accelerator (2023) and robotic surgery center in Korea
              for the first time. In addition, The city has 5,000 medical
              institutes with outstanding experts, as well as brilliant medical
              clusters such as seomyeon medical street, that enable such medical
              centers to create synergies together. High-quality medical
              services covering cosmetic surgery, dermatology, and dentistry, as
              well as severe diseases such as brain and heart disease and tumor
              are provided.
            </p>
          </div>
        </div>
      </div>

      {/* 부산 메디컬 센터, 컨시어지 서비스 */}
      <div id='services' className='services section'>
        <div className='container custom_service'>
          <div className='row col-lg-12'>
            <div className='custom_info'>
              <div
                className='section-heading wow fadeInDown custom_info01 custom_short01'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                {/* <img src={picture01} alt='부산메디컬센터' /> */}
                <iframe
                  src='https://player.vimeo.com/video/787477437?h=c382c2818a&amp;badge=0&amp;autoplay=1&loop=1&mute=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                  frameborder='0'
                  allow='autoplay; fullscreen; picture-in-picture'
                  allowfullscreen
                  title='쇼츠 동아대학교병원'
                ></iframe>
              </div>

              <div
                className='custom_info02 wow fadeInDown'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                <h4 className='custom_info_title'>Busan Medicall Center</h4>
                <h6 className='custom_info_subtext'>
                  The Medicall Center is a medical tourism service platform that
                  provides various convenience services from airport pickup
                  vehicles to hotel reservations, interpretation services, and
                  introduction of wellness tour packages for medical tourists
                  visiting Busan so that they can enjoy Busan medical tourism
                  more comfortably and safely. If you want detailed service
                  inquiries or hospital reservations, you can consult in detail
                  through messenger, e-mail, or landline. We can reply by
                  messenger in languages of major countries.
                </h6>
              </div>
            </div>
          </div>
          {/* <!-- row 1 end --> */}
          {/* 테블릿 싸이즈 - CSS 1739 */}
          <div className='row col-lg-12'>
            <div className='custom_info custom_info_s'>
              <div
                className='custom_info02 wow fadeInDown'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                <h4 className='custom_info_title'>Concierge Service</h4>
                <h6 className='custom_info_subtext'>
                  The Medicall Center business is a project developed by the
                  Busan Medical Industry Association with the budget of Busan
                  Metropolitan City and operated by Busan TNC, and is a
                  concierge professional platform that relieves the
                  inconvenience of medical tourists. We will provide
                  higher-quality services to foreign medical tourists visiting
                  Busan by providing customized various concierge services.
                </h6>
              </div>
              <div
                className='section-heading wow fadeInDown custom_info01 custom_short02'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                {/* <img src={picture02} alt='부산메디컬센터' /> */}
                <iframe
                  src='https://player.vimeo.com/video/787477407?h=2bbea0f96b&amp;badge=0&amp;autoplay=1&loop=1&mute=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                  frameborder='0'
                  allow='autoplay; fullscreen; picture-in-picture'
                  allowfullscreen
                  title='쇼츠 메디컬센터 김해'
                ></iframe>
              </div>
            </div>
          </div>
          {/* <!-- row 2 end --> */}
        </div>
      </div>

      {/* 의료기관, 호텔 소개 바로가기 */}
      <div id='banners' className='banners section'>
        <div className='custom_bannerdiv'>
          <div className='custom_medicalbusanlink'>
            <img src={medibusan} alt='메디컬부산' />
          </div>
          <div className='custom_banners'>
            <div className='banner_wrap'>
              <div className='custom_banner01'>
                <p>Medical Institutions</p>
                <a href=''>
                  <button
                    onClick={() =>
                      window.open(
                        'https://www.bsmeditour.go.kr/medicalInstitution.do',
                        '_blank'
                      )
                    }
                  >
                    GO <IoIosArrowForward className='custom_icon' />
                  </button>
                </a>
              </div>
              <div className='custom_banner02'>
                <p>Hotel Introduction</p>
                <a href=''>
                  <button
                    onClick={() =>
                      window.open(
                        'https://www.bsmeditour.go.kr/AboutHotel.do',
                        '_blank'
                      )
                    }
                  >
                    GO <IoIosArrowForward className='custom_icon' />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 클리닉 비디오, 리스트 */}
      <div id='clinic_videos' className='clinic_videos section'>
        <div className='container'>
          <div className='custom_video_list_wrap'>
            {/* <div className='vr_video_list'> */}
            <div className='clinic_video'>
              <iframe
                src={`${videoSrc}`}
                frameborder='0'
                allow='autoplay; fullscreen'
                allowfullscreen
                title='custom_video'
              ></iframe>
            </div>
            <div className='videoList'>
              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '1' ? '#274fd5' : '',
                    color: selectedVideoIndex === '1' ? '#fff' : '',
                  }}
                  id='1'
                  name='https://player.vimeo.com/video/779140621?h=cba8fc7e59'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {'Beauty clinic'}
                {/* 피부미용 */}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '2' ? '#274fd5' : '',
                    color: selectedVideoIndex === '2' ? '#fff' : '',
                  }}
                  id='2'
                  name='https://player.vimeo.com/video/779140351?h=4c6f0c20c7'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {'Cardiothoracic'}
                {/* 심장흉통클리닉 */}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '3' ? '#274fd5' : '',
                    color: selectedVideoIndex === '3' ? '#fff' : '',
                  }}
                  id='3'
                  name='https://player.vimeo.com/video/779140098?h=c4c9cc8e05'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {'Immunosuppressive'}
                {/* 면역증강 클리닉 */}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '4' ? '#274fd5' : '',
                    color: selectedVideoIndex === '4' ? '#fff' : '',
                  }}
                  id='4'
                  name='https://player.vimeo.com/video/779139926?h=e099099724'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 하지정맥 클리닉 */}
                {'Varicose vein'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '5' ? '#274fd5' : '',
                    color: selectedVideoIndex === '5' ? '#fff' : '',
                  }}
                  id='5'
                  name='https://player.vimeo.com/video/779139710?h=8f43aeac91'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 재건성형 클리닉 */}
                {'Reconstructive plastic surgery'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '6' ? '#274fd5' : '',
                    color: selectedVideoIndex === '6' ? '#fff' : '',
                  }}
                  id='6'
                  name='https://player.vimeo.com/video/779138898?h=8e27f59c23'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 혈액질환 */}
                {'Blood disease'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '7' ? '#274fd5' : '',
                    color: selectedVideoIndex === '7' ? '#fff' : '',
                  }}
                  id='7'
                  name='https://player.vimeo.com/video/779120666?h=e2b4f6a346'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 시력교정 */}
                {'Eye correction'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '8' ? '#274fd5' : '',
                    color: selectedVideoIndex === '8' ? '#fff' : '',
                  }}
                  id='8'
                  name='https://player.vimeo.com/video/779106965?h=4be381c049'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 소아외과 */}
                {'Pediatric surgery'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '9' ? '#274fd5' : '',
                    color: selectedVideoIndex === '9' ? '#fff' : '',
                  }}
                  id='9'
                  name='https://player.vimeo.com/video/783852367'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 담낭질환 */}
                {'Gallbladder disease'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '10' ? '#274fd5' : '',
                    color: selectedVideoIndex === '10' ? '#fff' : '',
                  }}
                  id='10'
                  name='https://player.vimeo.com/video/783852595'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 폐암 */}
                {'Lung cancer disease'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '11' ? '#274fd5' : '',
                    color: selectedVideoIndex === '11' ? '#fff' : '',
                  }}
                  id='11'
                  name='https://player.vimeo.com/video/784315514'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 대장암 */}
                {'Colorectal cancer'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '12' ? '#274fd5' : '',
                    color: selectedVideoIndex === '12' ? '#fff' : '',
                  }}
                  id='12'
                  name='https://player.vimeo.com/video/785843839'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 내분비 */}
                {'Endocrine clinic'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '13' ? '#274fd5' : '',
                    color: selectedVideoIndex === '13' ? '#fff' : '',
                  }}
                  id='13'
                  name='https://player.vimeo.com/video/891720695'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 3편 뇌전증 */}
                {'Epilepsy'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '14' ? '#274fd5' : '',
                    color: selectedVideoIndex === '14' ? '#fff' : '',
                  }}
                  id='14'
                  name='https://player.vimeo.com/video/891721924'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 척추 */}
                {'Spine clinic'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '15' ? '#274fd5' : '',
                    color: selectedVideoIndex === '15' ? '#fff' : '',
                  }}
                  id='15'
                  name='https://player.vimeo.com/video/891722730'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 임플란트 */}
                {'Implant'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '16' ? '#274fd5' : '',
                    color: selectedVideoIndex === '16' ? '#fff' : '',
                  }}
                  id='16'
                  name='https://player.vimeo.com/video/891728956'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 두드러기 */}
                {'Urticaria clinic'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '17' ? '#274fd5' : '',
                    color: selectedVideoIndex === '17' ? '#fff' : '',
                  }}
                  id='17'
                  name='https://player.vimeo.com/video/891731569'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 난임 */}
                {'Infertility'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '18' ? '#274fd5' : '',
                    color: selectedVideoIndex === '18' ? '#fff' : '',
                  }}
                  id='18'
                  name='https://player.vimeo.com/video/898034163'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 부인암 */}
                {'Gynecological cancer'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '19' ? '#274fd5' : '',
                    color: selectedVideoIndex === '19' ? '#fff' : '',
                  }}
                  id='19'
                  name='https://player.vimeo.com/video/898040455'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 난청 */}
                {'Hearing-impaired'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedVideoIndex === '20' ? '#274fd5' : '',
                    color: selectedVideoIndex === '20' ? '#fff' : '',
                  }}
                  id='20'
                  name='https://player.vimeo.com/video/899719509'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/*휴&락 유방암 */}
                {'Breast cancer'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button className='videoList_btn'>라인맞추기용</button>
            </div>
          </div>
        </div>
      </div>

      {/* 예약 form */}
      <div id='new_contact' className='new_contact section'>
        <div className='container container_s'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='naccs'>
                <div className='grid custom_accodian'>
                  <div className='row custom_contact'>
                    <div className='col-lg-3 custom_call'>
                      {/* <!-- 전화 상담 --> */}
                      <ul>
                        <li>
                          <img src={icon_call} alt='문의전화' />
                        </li>
                        {/* <!-- icon img --> */}
                        <li>
                          <p className='custom_ask'>
                            If you have any questions,
                            <br />
                            We kindly consult with you.
                          </p>
                        </li>
                        <li>
                          <p className='custom_servicenumber'>
                            +82 70-8874-9334
                            +82 10-2127-9334
                          </p>
                        </li>
                      </ul>
                      {/* <a href='#'> */}
                      <button className='custom_call_btn'>
                        Customer Center
                      </button>
                      {/* </a> */}
                    </div>
                    <div
                      className='col-lg-8 custom_accordion nacc accordion'
                      // className='accordion'
                      id='accordionExample'
                    >
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingOne'>
                          <button
                            className='accordion-button'
                            type='button'
                            name='section'
                            onClick={updateField}
                            value='병원예약'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseOne'
                            aria-expanded='true'
                            aria-controls='collapseOne'
                          >
                            {/* <img src={reserve01} alt="Hospital appointment" /> */}
                            Hospital Reservation
                          </button>
                        </h2>
                        <div
                          id='collapseOne'
                          className='accordion-collapse collapse show'
                          aria-labelledby='headingOne'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                              className='custom_form'
                              ref={form}
                              onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>Name</label>
                                <input
                                  type='text'
                                  name='user_name'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>Contact Number</label>
                                <input
                                  id='phone'
                                  type='tel'
                                  name='phone'
                                  onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>Email</label>
                                <input
                                  type='email'
                                  name='user_email'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>Medical Subject</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option value='상급병원'>
                                    Primary Hospital, General Hospital
                                  </option>
                                  <option value='종합병원'>
                                    Comprehensive medical examination
                                  </option>
                                  <option value='정형외과'>
                                    Orthopedics
                                  </option>
                                  <option value='성형외과'>
                                    Plastic Surgery
                                  </option>
                                  <option value='피부과'>Dermatology</option>
                                  <option value='치과'>Dentistry</option>
                                  <option value='안과'>Ophthalmology</option>
                                  <option value='산부인과'>
                                    Obstetrics and gynecology
                                  </option>
                                  <option value='한의학'>
                                    Oriental Medicine
                                  </option>
                                  <option value='기타'>Others</option>
                                </select>
                              </div>
                              <div className='custom_language divs'>
                                <label>Preference Language</label>
                                <div className='custom_radioselect'>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='English'
                                        onChange={updateField}
                                        defaultChecked
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>English</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Russian'
                                        onChange={updateField}
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>Russian</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Chinese'
                                        onChange={updateField}
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>Chinese</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Vietnamese'
                                        onChange={updateField}
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>Vietnamese</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='etc'
                                        onClick={() => setIsActiveLanguage(true)}
                                    />
                                    {isActiveLanguage ? <input className='language_div'
                                                               type='text'
                                                               placeholder=''
                                                               name='user_language'
                                                               onChange={updateField}/> : <p>Etc.</p>}
                                  </div>
                                </div>
                              </div>
                              <div className='custom_contentbox divs'>
                                <label>Content</label>
                                <textarea
                                    name='message'
                                    onChange={updateField}
                                />
                              </div>
                              <input
                                  type='submit'
                                  value='Send Email'
                                  className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* 병원 예약 */}
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingTwo'>
                          <button
                            className='accordion-button collapsed'
                            name='section'
                            onClick={updateField}
                            value='컨시어지'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseTwo'
                            aria-expanded='false'
                            aria-controls='collapseTwo'
                          >
                            {/* <img src={reserve02} alt="Concierge" /> */}
                            Concierge
                          </button>
                        </h2>
                        <div
                          id='collapseTwo'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingTwo'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                              className='custom_form'
                              ref={form}
                              onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>Name</label>
                                <input
                                  type='text'
                                  name='user_name'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>Contact Number</label>
                                <input
                                  id='phone'
                                  type='tel'
                                  name='phone'
                                  onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>Email</label>
                                <input
                                  type='email'
                                  name='user_email'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>Medical Subject</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option value='상급병원'>
                                    Primary Hospital, General Hospital
                                  </option>
                                  <option value='종합병원'>
                                    Comprehensive medical examination
                                  </option>
                                  <option value='정형외과'>
                                    Orthopedics
                                  </option>
                                  <option value='성형외과'>
                                    Plastic Surgery
                                  </option>
                                  <option value='피부과'>Dermatology</option>
                                  <option value='치과'>Dentistry</option>
                                  <option value='안과'>Ophthalmology</option>
                                  <option value='산부인과'>
                                    Obstetrics and gynecology
                                  </option>
                                  <option value='한의학'>
                                    Oriental Medicine
                                  </option>
                                  <option value='기타'>Others</option>
                                </select>
                              </div>
                              <div className='custom_language divs'>
                                <label>Preference Language</label>
                                <div className='custom_radioselect'>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='English'
                                        onChange={updateField}
                                        defaultChecked
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>English</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Russian'
                                        onChange={updateField}
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>Russian</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Chinese'
                                        onChange={updateField}
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>Chinese</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Vietnamese'
                                        onChange={updateField}
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>Vietnamese</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='etc'
                                        onClick={() => setIsActiveLanguage(true)}
                                    />
                                    {isActiveLanguage ? <input className='language_div'
                                                               type='text'
                                                               placeholder=''
                                                               name='user_language'
                                                               onChange={updateField}/> : <p>Etc.</p>}
                                  </div>
                                </div>
                              </div>
                              <div
                                  className={cn(
                                      style.concierge_div,
                                      style.form_div
                                  )}
                              >
                                <div className={style.dateset}>
                                  <p>Select Date</p>
                                  <DatePicker
                                      name='start'
                                      selected={startDate}
                                      onChange={(data, e) => {
                                        setStartDate(data);
                                        addedDate(data, e);
                                      }}
                                      showTimeSelect
                                      filterTime={filterPassedTime}
                                    dateFormat='MMMM d, yyyy h:mm aa'
                                  />
                                </div>
                                <div className={style.memberset}>
                                  <p>The number of people</p>
                                  <select
                                    onChange={updateField}
                                    name='user_count'
                                  >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                  </select>
                                </div>
                                <div className={style.destinationset}>
                                  <p>Departure/Destination</p>
                                  <input
                                    type='text'
                                    placeholder=''
                                    name='destination'
                                    onChange={updateField}
                                  />
                                </div>
                                {/* <button className={style.carbtn}>차량예약</button> */}
                              </div>
                              <input
                                type='submit'
                                value='Send Email'
                                className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingThree'>
                          <button
                            className='accordion-button collapsed'
                            name='section'
                            onClick={updateField}
                            value='호텔예약'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseThree'
                            aria-expanded='false'
                            aria-controls='collapseThree'
                          >
                            {/* <img className="btn_icon" src={reserve03} alt="Hotel reservation" /> */}
                            Hotel Reservation
                          </button>
                        </h2>
                        <div
                          id='collapseThree'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingThree'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                              className='custom_form'
                              ref={form}
                              onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>Name</label>
                                <input
                                  type='text'
                                  name='user_name'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>Contact Number</label>
                                <input
                                  id='phone'
                                  type='tel'
                                  name='phone'
                                  onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>Email</label>
                                <input
                                  type='email'
                                  name='user_email'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>Medical Subject</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option value='상급병원'>
                                    Primary Hospital, General Hospital
                                  </option>
                                  <option value='종합병원'>
                                    Comprehensive medical examination
                                  </option>
                                  <option value='정형외과'>
                                    Orthopedics
                                  </option>
                                  <option value='성형외과'>
                                    Plastic Surgery
                                  </option>
                                  <option value='피부과'>Dermatology</option>
                                  <option value='치과'>Dentistry</option>
                                  <option value='안과'>Ophthalmology</option>
                                  <option value='산부인과'>
                                    Obstetrics and gynecology
                                  </option>
                                  <option value='한의학'>
                                    Oriental Medicine
                                  </option>
                                  <option value='기타'>Others</option>
                                </select>
                              </div>
                              <div className='custom_hotel divs'>
                                <label>Hotel Name</label>
                                <input
                                  type='hotel'
                                  name='hotel'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_language divs'>
                                <label>Preference Language</label>
                                <div className='custom_radioselect'>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='English'
                                        defaultChecked
                                        onChange={updateField}
                                    />
                                    <p>English</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Russian'
                                        onChange={updateField}
                                    />
                                    <p>Russian</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Chinese'
                                        onChange={updateField}
                                    />
                                    <p>Chinese</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Vietnamese'
                                        onChange={updateField}
                                        onClick={() => setIsActiveLanguage(false)}
                                    />
                                    <p>Vietnamese</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='etc'
                                        onClick={() => setIsActiveLanguage(true)}
                                    />
                                    {isActiveLanguage ? <input className='language_div'
                                                               type='text'
                                                               placeholder=''
                                                               name='user_language'
                                                               onChange={updateField}/> : <p>Etc.</p>}
                                  </div>
                                </div>
                              </div>
                              <div
                                  className={cn(style.hotel_div, style.form_div)}
                              >
                                <div
                                    className={cn(
                                        style.dateset,
                                        style.hotel_dateset
                                    )}
                                >
                                  <p>Select Date</p>
                                  <div className={style.datepickeralign}>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => {
                                          setStartDate(date);
                                          addedDate(date);
                                      }}
                                      selectsStart
                                      startDate={startDate}
                                      endDate={endDate}
                                      minDate={new Date()}
                                      name='start'
                                    />
                                    <HiOutlineMinusSm
                                      className={style.icon_line}
                                    />
                                    <DatePicker
                                      name='finish'
                                      selected={endDate}
                                      onChange={(date) => {
                                        setEndDate(date);
                                        endedDate(date);
                                      }}
                                      selectsEnd
                                      startDate={startDate}
                                      endDate={endDate}
                                      minDate={startDate}
                                    />
                                  </div>
                                </div>
                                <div className={style.memberset}>
                                  <p>The number of people</p>
                                  <select
                                    onChange={updateField}
                                    name='user_count'
                                  >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                  </select>
                                </div>
                                <div className={style.hotelroom}>
                                  <p>Rooms</p>
                                  <select
                                    onChange={updateField}
                                    name='user_Room'
                                  >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                  </select>
                                </div>
                                {/* <button className={style.carbtn}>차량예약</button> */}
                              </div>
                              {/* .hotel_div end */}
                              <input
                                type='submit'
                                value='Send Email'
                                className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingFour'>
                          <button
                            className='accordion-button collapsed'
                            name='section'
                            onClick={updateField}
                            value='통역예약'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseFour'
                            aria-expanded='false'
                            aria-controls='collapseFour'
                          >
                            {/* <img src={reserve04} alt="translate" /> */}
                            Interpretation Reservation
                          </button>
                        </h2>
                        <div
                          id='collapseFour'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingFour'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                              className='custom_form'
                              ref={form}
                              onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>Name</label>
                                <input
                                  type='text'
                                  name='user_name'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>Contact Number</label>
                                <input
                                  id='phone'
                                  type='tel'
                                  name='phone'
                                  onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>Email</label>
                                <input
                                  type='email'
                                  name='user_email'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>Medical Subject</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option value='상급병원'>
                                    Primary Hospital, General Hospital
                                  </option>
                                  <option value='종합병원'>
                                    Comprehensive medical examination
                                  </option>
                                  <option value='정형외과'>
                                    Orthopedics
                                  </option>
                                  <option value='성형외과'>
                                    Plastic Surgery
                                  </option>
                                  <option value='피부과'>Dermatology</option>
                                  <option value='치과'>Dentistry</option>
                                  <option value='안과'>Ophthalmology</option>
                                  <option value='산부인과'>
                                    Obstetrics and gynecology
                                  </option>
                                  <option value='한의학'>
                                    Oriental Medicine
                                  </option>
                                  <option value='기타'>Others</option>
                                </select>
                              </div>
                              <div
                                className={cn(
                                  style.translate_div,
                                  style.form_div
                                )}
                              >
                                <div className={style.langset}>
                                  <p>Select Language</p>
                                  <select
                                      onChange={updateField}
                                      name='user_language'
                                  >
                                    <option selected>English</option>
                                    <option>Chinese</option>
                                    <option>Russian</option>
                                    <option>Vietnamese</option>
                                    <option>Japanese</option>
                                  </select>
                                </div>
                                <div className={style.dateset}>
                                  <p>Select Date</p>
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(data, e) => {
                                      setStartDate(data);
                                      addedDate(data, e);
                                    }}
                                    showTimeSelect
                                    filterTime={filterPassedTime}
                                    dateFormat='MMMM d, yyyy h:mm aa'
                                  />
                                </div>
                                <div className={style.destinationset}>
                                  <p>Departure/Destination</p>
                                  <input
                                    type='text'
                                    placeholder=''
                                    name='destination'
                                    onChange={updateField}
                                  />
                                </div>
                                {/* <button className={style.carbtn}>차량예약</button> */}
                              </div>
                              <input
                                type='submit'
                                value='Send Email'
                                className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* accordion end */}
                  </div>
                  {/*  <!-- .row end --> */}
                </div>
                {/* <!-- .grid end --> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* VR 영상 23-05-12 위치 이동 */}
      <div id='vr_videos' className='col-lg-12 vr_videos'>
        <div className='container'>
          <div className='vr_video_wrap'>
            <ul className='vr_video_list'>
              <li>
                <iframe
                  src='https://player.vimeo.com/video/783849715?h=28004e4a8a&badge=0&autopause=0&player_id=0&app_id=58479/embed'
                  frameborder='0'
                  allow='autoplay; fullscreen'
                  allowfullscreen
                  title='custom_video'
                ></iframe>
              </li>
              <li>
                <iframe
                  src={`${starSrc}`}
                  frameborder='0'
                  allow='autoplay; fullscreen'
                  allowfullscreen
                  title='custom_video'
                ></iframe>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* BEST 3 Collection */}
      <div id='board' className='board col-lg-12'>
        <div className='container custom_board'>
          <div className='section-heading'>
            <h4>BEST 3 Collection</h4>
          </div>
          {isLoading ? null : best_pop()}
        </div>
        {/* .container .custom_board end*/}
      </div>

      <div className='footer'>
        <div className='custom_footcontainer'>
          <div className='row'>
            <div className='col-lg-12 custom_footer'>
              <h5>BusanTNC.Co.Ltd</h5>
              <p className='custom_footerp'>
                Busan Port International Passenger Terminal 3F 3B08, 206,
                Chungjang-daero, Dong-gu, Busan, Republic of
                Korea(BusanMeditourCenter&amp;CruiseLounge)
              </p>
              <ul className='custom_footerul'>
                <li>
                  <span>T</span> 070-8874-9334
                </li>
                <li>
                  <span>F</span> 051-462-9334
                </li>
                <li>
                  <span>E</span> busancruiselounge@gmail.com
                </li>
              </ul>
              <p>Copyright 2023. BUSAN TNC All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>

      {/* <ul className="custom_quick">

        <li>
          <a
            href="https://pf.kakao.com/_ixeFTb"
            target="_blank"
            rel="noreferrer"
          >
            <p title="kakao talk"></p>
          </a>
          <p title="kakao talk">Open Chatting</p>
        </li>

        <li>
          <p title="wechat"></p>
          <p title="wechat">ID : Busan_Medicall</p>
        </li>

        <li>
          <p title="whatsapp"></p>
          <p title="whatsapp">ID : Medicall</p>
        </li>

        <li>
          <p title="line"></p>
          <p title="line">ID : busan_medicall</p>
        </li>
      </ul> */}
    </>
  );
};

export default Engpage;
