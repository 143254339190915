import axios from "axios";

// axios.defaults.baseURL = "http://busanmedicall.com/api";
// //axios.defaults.baseURL = "http://192.168.0.12:11751/api"; //로컬
// axios.defaults.withCredentials = false;

export const BASE_URL = "http://busanmedicall.com/api"; //서버
// export const BASE_URL = "http://192.168.20.4:11751/api"; //로컬

const unAuthClient = axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
});
const authClient = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});
//withCredentials ->refreshToken cookie 주고 받을때 필요하대
// export axios ;
authClient.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem('login_T');

      // if (!token) throw new Error("localStorage is empty", { statusCode: 401 });
      if (!token) {
        const error = new Error('localStorage is empty');
        error.code = 401;
        throw error;
      }

      // 토큰 있으면 헤더로 백엔드에 보내
      if (token) {
        if (config.headers) config.headers.Authorization = `Bearer ${token}`;
        return config;
      } else {
        throw new Error('Token is empty');
      }
    },
    function (error) {
      if (error.response?.status === 401 || error.code === 401) {
        window.localStorage.removeItem('login_T');
        window.location.href = '/';
      }
      return Promise.reject(error);
    }
);

// if response is 401, remove token and redirect to login page
authClient.interceptors.response.use(
    function (response) {
        console.log('response',response);
      return response;
    },
    function (error) {
      // if (error.response?.status === 401 || error.code === 401) {
        // dispatch({ type: LOGOUT });
        window.localStorage.removeItem('login_T');
        window.location.href = '/login';
      // }
      return Promise.reject(error);
    }
);
export { unAuthClient, authClient };

// export default authClient;
