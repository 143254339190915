import "../../assets/css/style.css";
import style from "../../assets/css/custom.css";

import { GrFormClose } from "react-icons/gr";
import cn from "classnames";
import React, { useState, useEffect, useRef } from "react";
import {authClient} from "../../lib/axios";

const PopupModal = ({ index, poptoggle }) => {
  //const inputRef =useRef();
  const [file, setFile] = useState();
  // const [fileUrl,setFileUrl]=useState(null);
  //입력받는 값
  console.log(index);
  const [InputData, SetInputData] = useState({
    index: index.INDEX,
    imgUP_kr: "",
    imgUP_en: "",
    imgUP_ch: "",
    imgUP_ru: "",
  });
  const form = useRef(index);

  const updateField = (e) => {
    SetInputData({
      ...InputData,
      [e.target.id]: file,
    });
    console.log("test Input data :", InputData);
  };

  const submitFrom = async (event) => {
    event.preventDefault();

    console.log("input_data:", InputData);
    let url = "http://192.168.0.64:11751/api/popupinput";
    const option = {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: InputData,
    };
    try {
      await authClient
        .post(`/popupinput`, option)
        //fetch(url, option)
        .then(async (res) => res)
        .then((data) => {
          console.log("result is : ", data);
          poptoggle(false);
          window.location.reload();
        });
    } catch (err) {
      console.log("fetch error : ", err);
      window.alert("권한이 없습니다");
    }
  };

  const isSelectedImg = async (event) => {
    //빈파일이 아닌 경우 함수 진행
    if (event.target.files !== null) {
      //FormData 생성
      const fd = new FormData();
      //FormData에 key, value 추가하기
      fd.append("index", event.target.id);
      fd.append("imgUP", event.target.files[0]);
      // axios 사용해서 백엔드에게 파일 보내기
      await authClient
        //.post(`/upload`, fd)
        .post(`/fileupload`, fd)
        //.post(`/modalinput`, fd)
        .then((res) => {
          //응답으로 받은 url 저장하기 (응답 내용의 표시나 방법은 백엔드와 결정해야한다.)
          //     setFile(res.data);
          SetInputData({
            ...InputData,
            [res.data.index]: res.data.filename,
          });
          setFile(res.data.filename);
          console.log("setFile:", res);
          console.log("파일이름만 저장하라고", res.data.filename);
          // 최종적으로 DB에 저장될 url을 보내는 과정이 부모 컴포넌트에 있기 때문에 부모 컴포넌트에 url을 보내는 과정
          //props.imgStore(res.data.image_url);
        })
        //에러가 날 경우 처리
        .catch((error) => {
          console.log(error.response);
        });
    }
  };

  return (
    <>
      <div className="modal_bg">
        <div className="modal_div">
          <div className="modal_fontclose">
            <p className="modal_font">팝업 업로드 수정</p>
            <p className="modal_close">
              <GrFormClose onClick={poptoggle} />
            </p>
          </div>
          <form
            className="modal_form"
            ref={form}
            onSubmit={submitFrom}
            encType="multipart/form-data"
            method="post"
          >
            {/* <form className="modal_form" ref={form} onSubmit={isSelectedImg} encType="multipart/form-data" method="post"> */}
            <div className="korea">
              <div className="title_input">
                <p>한국어 팝업:</p>
                <input
                  type="file"
                  id="imgUP_kr"
                  name="imgUP"
                  accept="image/*"
                  onChange={(data) => {
                    isSelectedImg(data);
                    updateField(data);
                  }}
                  className="modal_file"
                />
              </div>
            </div>

            <div className="english">
              <div className="title_input">
                <p>영어 팝업:</p>
                <input
                  type="file"
                  id="imgUP_en"
                  name="imgUP"
                  accept="image/*"
                  onChange={(data) => {
                    isSelectedImg(data);
                    updateField(data);
                  }}
                  className="modal_file"
                />
              </div>
            </div>

            <div className="chinese">
              <div className="title_input">
                <p>중국어 팝업:</p>
                <input
                  type="file"
                  id="imgUP_ch"
                  name="imgUP"
                  accept="image/*"
                  onChange={(data) => {
                    isSelectedImg(data);
                    updateField(data);
                  }}
                  className="modal_file"
                />
              </div>
            </div>

            <div className="russia">
              <div className="title_input">
                <p>러시아 팝업:</p>
                <input
                  type="file"
                  id="imgUP_ru"
                  name="imgUP"
                  accept="image/*"
                  onChange={(data) => {
                    isSelectedImg(data);
                    updateField(data);
                  }}
                  className="modal_file"
                />
              </div>
            </div>
            <div className="modal_btns">
              <div className="btn_01" onClick={poptoggle}>
                {" "}
                취소
              </div>
              <div className="btn_02" onClick={submitFrom}>
                {" "}
                저장
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default PopupModal;
