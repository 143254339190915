import React from 'react';
import "../../assets/css/style.css";
import best1 from'../../assets/images/best1.jpg';
import best2 from'../../assets/images/best2.jpg';
import best3 from'../../assets/images/best3.jpg';


function Popup({change,best,index,togglePopup}){

    console.log("change::!",best);
    let testdefault1 = '';
    let testdefault2 = '';
    let testdefault3 = '';
    const selectfile=({change,index})=>{
            console.log(best);
        switch(change){
            case 'en':
                testdefault1 = best[index.INDEX-1].FILE_THUMB_en;
                testdefault2 = best[index.INDEX-1].FILE_THUMB_en;
                testdefault3 = best[index.INDEX-1].FILE_THUMB_en;
            break;
            case 'ch':
                testdefault1 = best[index.INDEX-1].FILE_THUMB_ch;
                testdefault2 = best[index.INDEX-1].FILE_THUMB_ch;
                testdefault3 = best[index.INDEX-1].FILE_THUMB_ch;
            break;
            case 'ru':
                testdefault1 = best[index.INDEX-1].FILE_THUMB_ru;
                testdefault2 = best[index.INDEX-1].FILE_THUMB_ru;
                testdefault3 = best[index.INDEX-1].FILE_THUMB_ru;
            break;
            case 'ko':
                testdefault1 = best[index.INDEX-1].FILE_THUMB;
                testdefault2 = best[index.INDEX-1].FILE_THUMB;
                testdefault3 = best[index.INDEX-1].FILE_THUMB;
            break;
        }
        switch(index.INDEX){
            case 1:
                return(<img className="image" src={`/image/${testdefault1}`}/>)
            case 2:
                return(<img className="image" src={`/image/${testdefault2}`}/>)
                //return(<img className="image" src={testdefault2}/>)
            case 3:
                return(<img className="image" src={`/image/${testdefault3}`}/>)
        }

    }

    return(
        <div className="modal-back">
            <div className="modal-card">
                {selectfile({change,index})}
                {/* <img className="image" src={best1}/> */}
                <div className="btn_close">
                    <button className="button" onClick={togglePopup}>CLOSE</button>
                </div>
            </div>
        </div>

    );
}

export default Popup;