import Logg from '../components/Membership/Login';

const Login =()=> {
    return (
<>
<Logg />
</>
    )
}

export default Login;