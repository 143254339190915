import React from "react";
import { Link, useParams } from "react-router-dom";
// import axios from "axios";
import { authClient } from "../lib/axios";
import { useEffect, useState } from "react";

import "../assets/css/style.css";
import logo from "../../src/assets/images/logo.svg";

const DetailPage = ({ match }) => {
  const [data, setData] = useState("");
  const [isLoading, SetLoading] = useState(true);
  const { index } = useParams();

  useEffect(async () => {
    try {
      const res = await authClient.get(`/detail/${index}`, );
      setData(res.data);
      SetLoading(false);
    } catch (e) {
      console.error(e.message);
    }
  }, []);

  //확인버튼 누르면 확인여부 저장하려고 했는데 삭제함
  // const CheckForm = async()=>{
  //     setStatus(true);
  //     const test = await axios.post(`/detail`,{
  //         index: index,
  //         checked: status
  //     }).then(function(response){
  //         console.log("response:",response);
  //     }).catch(function(error){
  //         console.log("error:",error);
  //     })
  // }
  let formElement = [];
  const confirmedFrom = () => {
    data &&
      data.map((el, index) => {
        formElement.push(
          <>
            <div className="title" key={index}>
              <p>
                {el.NAME}의 {el.MEDICAL_SECTION}_{el.DETAIL_SECTION}문의입니다.
              </p>
              <p>
                <span>의뢰인 이름</span>
                <span>{el.NAME}</span>
              </p>
            </div>
            <div className="detail">
              <p>
                <span>연락처</span>
                {el.CONTACT_NUMBER}
              </p>
              <p>
                <span>이메일</span>
                {el.EMAIL}
              </p>
              <p>
                <span>선호언어</span>
                {el.LANG_PREFERENCE}
              </p>
              <p>
                <span>문의내용</span>

                {el.CONTEXT}
                {el.MEDICAL_SECTION === "컨시어지" ? (
                  <p>
                    일자/시간:{el.REGISTRATION_DATE}
                    <br />
                    예약인원: {el.NUMER_PEOPLE}
                    <br />
                    목적지 :{el.DESTINATION}
                  </p>
                ) : null}
                {el.MEDICAL_SECTION === "호텔예약" ? (
                  <p>
                    선택 호텔: {el.HOTEL} <br /> 예약시작일:{" "}
                    {el.REGISTRATION_DATE} <br />
                    예약마감일:{el.REGISTRATION_END} <br />
                    예약인원: {el.NUMER_PEOPLE} <br />
                    객실 수: {el.ROOM}
                  </p>
                ) : null}
                {el.MEDICAL_SECTION === "통역예약" ? (
                  <p>
                    번역 언어:{el.LANG_PREFERENCE}
                    <br />
                    일자/시간:{el.REGISTRATION_DATE}
                    <br />
                    목적지 :{el.DESTINATION}
                  </p>
                ) : null}
              </p>
            </div>
            <div className="flex01">
              <Link to="/view" className="btn_01">
                목록
              </Link>
              {/* <a href="#" className="btn_02" onClick={CheckForm}>확인</a> */}
            </div>
          </>
        );
      });
    return formElement;
  };
  return (
    <>
      <body>
        <header>
          <h1>
            <Link to="/">
              <img src={logo} alt="TNC logo" />
            </Link>
          </h1>
          <ul>
            <li>
              <Link to="/">home</Link>
            </li>
            <li>
              <a href="#">logout</a>
            </li>
          </ul>
        </header>
        <div id="body_flex">
          <div id="sidebar">
            <ul>
              <li>
                <Link to="/view">메세지함</Link>
              </li>
              <li>
                <Link to="/editpage">베스트 3선 패키지</Link>
              </li>
            </ul>
          </div>
          <div id="container">
            <div className="contents02">
              <h2>메세지함</h2>
              {/* <div className="title">
                    <p>안녕하세요. 테렌즈 그룹웨어 스토리보드 초안입니다.</p>
                    <p>
                        <span>작성자</span>
                        <span>{data.NAME}</span>
                    </p>
                </div>
                <div className="detail">
                    <p>
                        <span>연락처</span>
                        010-1234-4567
                    </p>
                    <p>
                        <span>이메일</span>
                        tiny_person@gmail.com
                    </p>
                    <p>
                        <span>문의내용</span>
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        호텔 예약 문의드립니다. 이번에 가족끼리 부산에 들리게 되었는데
                        해운대 근처 호텔 시선 관련해서 추천 부탁드립니다.
                        </p>
                </div>
                <div className="flex01">
                    <a href="./admin.html" className="btn_01">이전</a>
                    <a href="#" className="btn_02">확인</a>
                </div> */}
              {isLoading ? null : confirmedFrom()}
            </div>
          </div>
          {/* <!--#container--> */}
        </div>
        {/* <!--#body_flex--> */}
        <div id="footer">
          <p>ⓒ 2021 BUSAN TNC All rights reserved.</p>
        </div>
      </body>
    </>
  );
};

export default DetailPage;
