import { useEffect, useRef, useState, StyleSheet } from 'react';

import cn from 'classnames';

/* DatePicker */
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko'; // 한국어적용

/* bootstrap */
import 'bootstrap/dist/js/bootstrap.bundle.min';

/* CSS */
import '../assets/css/fontawesome.css';
import '../assets/css/custom.css'; /* 수정한 CSS */
import '../assets/css/animated.css';
import '../assets/css/owl.css';
import style from '../components/Modal/Modal.module.css';
// import '../../node_modules/intl-tel-input/build/css/intlTelInput.css'; /* 국가번호 CSS */

/* imgs */
import logo from '../assets/images/tnc_newlogo.jpeg';
import firstplace from '../assets/images/custom_1level_2x.png';
import icon_call from '../assets/images/custom_iconcall.png';
import medibusan from '../assets/images/custom_meditour_2x.png';
import KO from '../assets/images/flag_kr.png';
import US from '../assets/images/flag_us.png';
import RU from '../assets/images/flag_ru.png';
import CH from '../assets/images/flag_ch.png';
import insta from '../assets/images/instagram.png';
import facebook from '../assets/images/facebook.png';

/* icon */
import {
    IoMdSettings,
    IoIosArrowForward,
    IoIosArrowDropright,
} from 'react-icons/io';
import { HiOutlineMinusSm } from 'react-icons/hi';

import { Link } from 'react-router-dom';
import Popup from '../components/Modal/Popup';
import moment from 'moment';
import { unAuthClient } from '../lib/axios';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";
function VietnamPage() {

    const [popup, setPopup] = useState(false);
    const [best, setBest] = useState([]);
    const [toggleIndex, setToggleIndex] = useState('');
    const [isLoading, SetLoading] = useState(true);
    const [videoSrc, setVideoSrc] = useState(
        'https://player.vimeo.com/video/779140621?h=cba8fc7e59'
    );
    const [starSrc, setStarSrc] = useState(
        'https://player.vimeo.com/video/779141748?h=09df3f454e'
    );
    const [selectedVideoIndex, setSelectedVideoIndex] = useState('1');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    //모바일 select박스 value에 따라 페이지 전환 때문에 navigate
    const navigate = useNavigate();
    const form = useRef();
    const [initialInputData, SetInitialInputData] = useState({
        user_name: '',
        phone: '',
        user_email: '',
        section: '병원예약', //need to default value
        detail_section: '상급·종합병원',
        message: '',
        user_count: 1,
        start: moment(new Date()).format('YYYY-MM-DD HH:mm'),
        user_Room: 1,
        user_language: 'Vietnamese',
    });
    const togglePopup = (el) => {
        setToggleIndex(el);
        setPopup(!popup);
        console.log(el);
    };


    const sendEmail = async (event) => {
        event.preventDefault();
        const option = {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: initialInputData,
        };

        if(!_.isEmpty(initialInputData.user_name||!_.isEmpty(initialInputData.user_email))) {
            try {
                await unAuthClient
                    .post('/sendmail', option);
                window.alert('success');
            } catch (err) {
                console.log('fetch error : ', err);
            } finally{
                window.location.reload();
            }
        }else{
            window.alert('Please enter required items ')
        }
    };

    const updateField = (e) => {
        if (e.target.name === 'section') {
            SetInitialInputData({
                user_name: '',
                phone: '',
                user_email: '',
                section: e.target.value,
                detail_section: '상급·종합병원',
                message: '',
                user_count: 1,
                start: moment(new Date()).format('YYYY-MM-DD HH:mm'),
                user_Room: 1,
                user_language: 'Vietnamese',
            });
            //SetInputData([0]);
        }else{
            SetInitialInputData({
                ...initialInputData,
                [e.target.name]: e.target.value,
            });
        }

    };
    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    /* 호텔 */

    const addedDate = (data) => {
        SetInitialInputData({
            ...initialInputData,
            start: moment(data).format('YYYY-MM-DD HH:mm'),
        });
    };
    const endedDate = (data, e) => {
        SetInitialInputData({
            ...initialInputData,
            finish: moment(data).format('YYYY-MM-DD HH:mm'),
        });
    };
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    //베스트 3선 자세히 보기
    const best_pop = () => {
        const best_vener = [];

        if (best.length > 0) {
            best?.map((el, index) => {
                let file = el.FILE_POPUP;
                best_vener.push(
                    <div className='custom_bestitems' key={index}>
                        <ul>
                            {/* <!-- 힐링&웰니스 투어 --> */}
                            <li>
                                <img src={`/image/${file}`} alt={el.TITLE_en} />
                                {/* <img src={top_img} alt={el.TITLE_kr} /> */}
                            </li>
                            {/* <!-- img --> */}
                            <li>
                                <p className='custom_besttitle'>{el.TITLE_en}</p>
                                <p className='custom_besttext'>{el.CONTENT_en}</p>
                            </li>
                            {/* <!-- text --> */}
                            <li>
                                <button
                                    className='custom_morebtn'
                                    onClick={() => togglePopup(el)}
                                >
                                    Xem chi tiết
                                </button>
                            </li>
                        </ul>
                    </div>
                );
            });
        }
        return best_vener;
    };

    useEffect(() => {
        const fun = async () => {
            try {
                const res = await unAuthClient.get('/amendBest');
                setBest(res.data);
                SetLoading(false);
            } catch (e) {
                console.error(e);
            }
        };
        fun();
    }, []);

    const changeVideo = (event) => {
        setVideoSrc(event.target.name);
        setSelectedVideoIndex(event.target.id);
    };

    const changeStarVideo = (event) => {
        setStarSrc(event.target.name);
    };

    return (
        <>
            <header className='header_area header_sticky wow slideInDown'>
                {popup && (
                    <Popup
                        change='ko'
                        best={best}
                        index={toggleIndex}
                        togglePopup={togglePopup}
                    />
                )}
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <nav className='main-nav'>
                                {/* <!-- ***** Logo Start ***** --> */}
                                <a href='/KorPage' className='logo'>
                                     <img src={logo} alt="logo자리" />
                                </a>
                                {/* <!-- ***** Logo End ***** --> */}

                                {/* <!-- ***** Menu Start ***** --> */}
                                <ul className='nav'>
                                    <li className='scroll-to-section'>
                                        <Link to='/'>
                                            <img src={US} alt='usa_flag' />
                                        </Link>
                                    </li>
                                    {/* <!-- 영어 -->  */}

                                    <li className='scroll-to-section'>
                                        <Link to='/KorPage'>
                                            <img src={KO} alt='SouthKorea_flag' />
                                        </Link>
                                    </li>
                                    {/* <!-- 한국어 --> */}

                                    <li className='scroll-to-section'>
                                        <Link to='/ChPage'>
                                            <img src={CH} alt='China_flag' />
                                        </Link>
                                    </li>
                                    {/* <!-- 중국어 --> */}

                                    <li className='scroll-to-section'>
                                        <Link to='/RuPage'>
                                            <img src={RU} alt='Russia_flag' />
                                        </Link>
                                    </li>
                                    {/* <!-- 러시아어 --> */}

                                    {/* 23-05-12 소셜미디어 링크 추가 */}
                                    <div className='socialMedia_link'>
                                        <li className='scroll-to-section' style={{visibility: "hidden"}}>
                                            <Link to='https://www.instagram.com/'>
                                                <img src={insta} alt='instagram link' />
                                            </Link>
                                        </li>
                                        <li className='scroll-to-section'>
                                            <Link to='https://www.facebook.com/Star-doctor-in-busan-vt-101837994989301/'>
                                                <img src={facebook} alt='facebook link' />
                                            </Link>
                                        </li>
                                    </div>
                                </ul>

                                <div className='nav_s'>
                                    <select
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            navigate(e.target.value);
                                        }}
                                    >
                                        <option className='scroll-to-section' href='/KorPage'>
                                            KO
                                        </option>
                                        {/* <!-- 한글 -->   */}
                                        <option className='scroll-to-section' value='/'>
                                            EN
                                        </option>
                                        {/* <!-- 영어 --> */}
                                        <option className='scroll-to-section' value='/ChPage'>
                                            CH
                                        </option>
                                        {/* <!-- 중국어 --> */}
                                        <option className='scroll-to-section' value='/RuPage'>
                                            RU
                                        </option>
                                        {/* <!-- 러시아어 --> */}
                                        <option className='scroll-to-section' value='/ViPage'>
                                            VI
                                        </option>
                                        {/* <!-- 베트남어 --> */}
                                    </select>
                                    {/* 23-05-12 소셜미디어 링크 추가 */}
                                    <div className='socialMedia_link'>
                                        <li className='scroll-to-section'>
                                            <Link to='https://www.instagram.com/'>
                                                <img src={insta} alt='instagram link' />
                                            </Link>
                                        </li>
                                        <li className='scroll-to-section'>
                                            <Link to='https://www.facebook.com/'>
                                                <img src={facebook} alt='facebook link' />
                                            </Link>
                                        </li>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!-- ***** Header Area End ***** --> */}

            {/* <!-- 대한민국 대표 의료관광도시 부산! --> */}
            <div
                className='main_banner wow fadeIn'
                id='top'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
            >
                <div className='container custom_header'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='col-lg-12 align-self-center'>
                                <div
                                    className='left-content show-up header-text wow fadeInLeft'
                                    data-wow-duration='1s'
                                    data-wow-delay='1s'
                                >
                                    <div className='col-lg-12'>
                                        <h6 className='custom_title'>MEDICAL TECHNOLOGY</h6>
                                        <h2 className='custom_subtitle'>
                                            Busan, thành phố du lịch y tế tiêu biểu của Hàn Quốc!
                                        </h2>
                                        <p className='custom_content'>
                                            Busan là thành phố lớn thứ hai và thành phố cảng lớn nhất Hàn Quốc với dân số 3,5 triệu người. Đây là thành phố du lịch tài nguyên được thiên nhiên ban tặng với khí hậu ôn hòa bốn mùa có núi, biển và nhiều sự kiện quốc tế được tổ chức “ Lễ hội pháo hoa Busan”, “Liên hoan phim quốc tế Busan” , được Lonely Planet bình chọn là 'Điểm du lịch tuyệt vời số 1 châu Á (2018)' cũng là một địa điểm du lịch nghỉ dưỡng có tầm cỡ trên toàn thế giới. Ngoài ra với chất lượng dịch vụ y tế cao nhất và trình độ công nghệ y tế cao nhất còn đứng ở cương vị là địa điểm du lịch y tế tiêu biểu ở khu vực Châu Á chúng tôi đang cố gắng tạo ra “Busan, thành phố du lịch y tế bạn muốn đến” mang lại sự hài lòng cao nhất cho khách du lịch.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--의료도시 1위 수상 --> */}
            <div id='about' className='about section'>
                <div className='row custom_row'>
                    <div className='col-lg-6 bg02img'>
                        {/* <!-- 왼쪽 bg img 들어감 --> */}
                    </div>
                </div>

                <div className='row custom_float'>
                    <div
                        className='col-lg-12 align-self-center wow fadeInRight'
                        data-wow-duration='1s'
                        data-wow-delay='0.5s'
                    >
                        <div className='section-heading'>
                            {/* <!-- 1숫자 --> */}
                            <div className='custom_num1img'>
                                <img src={firstplace} alt='의료도시 부문1위' />
                            </div>
                        </div>
                        <h2 className='custom_subtitle'>
                            Thương hiệu quốc gia Hàn Quốc
                            <br />
                            Giaỉ thưởng 'Vị trí số 1 trong hạng mục thành phố y tế'
                        </h2>
                        <p className='custom_text'>
                            Busan đang theo đuổi để trở thành thành phố trung tâm về du lịch y tế ở Đông Bắc Á thông qua phát triển chuyên sâu ngành công nghiệp du lịch y tế với cơ sở hạ tầng y tế tầm cỡ thế giới đó là Hàn Quốc là nước đầu tiên du nhập máy gia tốc baryon (2023) và có trung tâm phẫu thuật robot. Ngoài ra, dựa trên cụm du lịch y tế tích hợp của hơn 5.000 cơ sở y tế với các chuyên gia xuất sắc và cụm du lịch y tế Phố y tế Seomyeon, chúng tôi đang cung cấp đa dạng các dịch vụ y tế chất lượng cao cho nhiều nhóm bệnh nhân khác nhau từ phẫu thuật thẩm mỹ, da liễu và nha khoa  đến các bệnh nghiêm trọng như điều trị não, tim và khối u....v.v...
                        </p>
                    </div>
                </div>
            </div>

            {/* 부산 메디컬 센터, 컨시어지 서비스 */}
            <div id='services' className='services section'>
                <div className='container custom_service'>
                    <div className='row col-lg-12'>
                        <div className='custom_info'>
                            <div
                                className='section-heading wow fadeInDown custom_info01 custom_short01'
                                data-wow-duration='1s'
                                data-wow-delay='0.5s'
                            >
                                {/* <img src={picture01} alt='부산메디컬센터' /> */}
                                <iframe
                                    src='https://player.vimeo.com/video/787477437?h=c382c2818a&amp;badge=0&amp;autoplay=1&loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                                    frameborder='0'
                                    allow='autoplay; fullscreen; picture-in-picture'
                                    allowfullscreen
                                    title='쇼츠 동아대학교병원'
                                ></iframe>
                                <script src='https://player.vimeo.com/api/player.js'></script>
                            </div>

                            <div
                                className='custom_info02 wow fadeInDown'
                                data-wow-duration='1s'
                                data-wow-delay='0.5s'
                            >
                                <h4 className='custom_info_title'>Trung tâm y tế Busan</h4>
                                <h6 className='custom_info_subtext'>
                                    Trung tâm Medicall là nền tảng dịch vụ du lịch y tế cung cấp nhiều dịch vụ tiện ích cho khách du lịch y tế khi đến thăm Busan  từ xe đưa đón sân bay đến đặt phòng khách sạn, dịch vụ phiên dịch và giới thiệu các gói tour du lịch chăm sóc sức khỏe nhằm giúp khách có thể tận hưởng du lịch y tế Busan một cách thoải mái và an toàn hơn. Nếu bạn muốn hỏi chi tiết về dịch vụ hoặc đặt lịch khám tại bệnh viện, bạn có thể tư vấn chi tiết qua tin nhắn, email hoặc trực tiêp gọi điện thoại. Chúng tôi có khả năng trả lời Messenger bằng các ngôn ngữ các quốc gia chính.
                                </h6>
                            </div>
                        </div>
                    </div>
                    {/* <!-- row 1 end --> */}
                    {/* 테블릿 싸이즈 - CSS 1739 */}
                    <div className='row col-lg-12'>
                        <div className='custom_info custom_info_s'>
                            <div
                                className='custom_info02 wow fadeInDown'
                                data-wow-duration='1s'
                                data-wow-delay='0.5s'
                            >
                                <h4 className='custom_info_title'>Dịch vụ hướng dẫn khách</h4>
                                <h6 className='custom_info_subtext'>
                                    Dự án Trung tâm Y tế là dự án được phát triển bởi Hội đồng Công nghiệp Y tế Khu vực Busan với ngân sách của Thành phố  Busan và được điều hành bởi Busan TNC, đây là một nền tảng trợ giúp chuyên sâu giúp giảm bớt sự bất tiện của khách du lịch y tế bằng việc cung cấp đa dạng các dịch vụ trợ giúp theo yêu cầu của khách ở mức độ cao hơn cho khách du lịch y tế nước ngoài tìm đến với Busan .
                                </h6>
                            </div>
                            <div
                                className='section-heading wow fadeInDown custom_info01 custom_short02'
                                data-wow-duration='1s'
                                data-wow-delay='0.5s'
                            >
                                {/* <img src={picture02} alt='부산메디컬센터' /> */}
                                <iframe
                                    src='https://player.vimeo.com/video/787477407?h=2bbea0f96b&amp;badge=0&amp;autoplay=1&loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                                    frameborder='0'
                                    allow='autoplay; fullscreen; picture-in-picture'
                                    allowfullscreen
                                    title='쇼츠 메디컬센터 김해'
                                ></iframe>
                                <script src='https://player.vimeo.com/api/player.js'></script>
                            </div>
                        </div>
                    </div>
                    {/* <!-- row 2 end --> */}
                </div>
            </div>

            {/* 의료기관, 호텔 소개 바로가기 */}
            <div id='banners' className='banners'>
                <div className='custom_bannerdiv'>
                    <div className='custom_medicalbusanlink'>
                        <img src={medibusan} alt='메디컬부산' />
                    </div>
                    <div className='custom_banners'>
                        <div className='banner_wrap'>
                            <div className='custom_banner01'>
                                <p>Giới thiệu các cơ sở y tế</p>
                                {/* <a href="#"><button>바로가기 <IoIosArrowForward className='custom_icon'/></button></a> */}
                                <a href=''>
                                    <button
                                        onClick={() =>
                                            window.open(
                                                'https://www.bsmeditour.go.kr/medicalInstitution.do',
                                                '_blank'
                                            )
                                        }
                                    >
                                        đi ngay lập tức thì <IoIosArrowForward className='custom_icon' />
                                    </button>
                                </a>
                            </div>
                            <div className='custom_banner02'>
                                <p>Giới thiệu khách sạn</p>
                                {/* <a href="#"><button>바로가기 <IoIosArrowForward className='custom_icon'/></button></a> */}
                                <a href='#'>
                                    <button
                                        onClick={() =>
                                            window.open(
                                                'https://www.bsmeditour.go.kr/AboutHotel.do',
                                                '_blank'
                                            )
                                        }
                                    >
                                        đi ngay lập tức thì <IoIosArrowForward className='custom_icon' />
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 클리닉 비디오, 리스트 */}
            <div id='clinic_videos' className='clinic_videos section'>
                <div className='container'>
                    <div className='custom_video_list_wrap'>
                        {/* <div className='iframe_List'> */}
                        <div className='clinic_video'>
                            <iframe
                                src={`${videoSrc}`}
                                frameborder='0'
                                allow='autoplay; fullscreen'
                                allowfullscreen
                                title='custom_video'
                            ></iframe>
                        </div>
                        <div className='videoList'>
                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '1' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '1' ? '#fff' : '',
                                }}
                                id='1'
                                name='https://player.vimeo.com/video/779140590'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'làm đẹp da'}
                                {/* 피부 미용 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>

                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '2' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '2' ? '#fff' : '',
                                }}
                                id='2'
                                name='https://player.vimeo.com/video/779140321'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'phòng khám đau tim'}
                                {/* 심장흉통 클리닉 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>

                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '3' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '3' ? '#fff' : '',
                                }}
                                id='3'
                                name='https://player.vimeo.com/video/779140071'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'phòng khám tăng cường miễn dịch'}
                                {/* 면역증강 클리닉 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>

                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '4' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '4' ? '#fff' : '',
                                }}
                                id='4'
                                name='https://player.vimeo.com/video/779139890'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'phòng khám tĩnh mạch dưới đáy biển'}
                                {/* 하지정맥 클리닉 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>

                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '5' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '5' ? '#fff' : '',
                                }}
                                id='5'
                                name='https://player.vimeo.com/video/779139676'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'phòng khám phẫu thuật tái tạo'}
                                {/* 재건 성형 클리닉 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>

                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '6' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '6' ? '#fff' : '',
                                }}
                                id='6'
                                name='https://player.vimeo.com/video/779138873'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'Bịnh máu'}
                                {/* 혈액질환 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>

                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '7' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '7' ? '#fff' : '',
                                }}
                                id='7'
                                name='https://player.vimeo.com/video/779120643'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'điều trị thị lực'}
                                {/* 시력 교정 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>

                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '8' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '8' ? '#fff' : '',
                                }}
                                id='8'
                                name='https://player.vimeo.com/video/779118602'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'khoa nhi ngoại'}
                                {/* 소아외과 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>

                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '9' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '9' ? '#fff' : '',
                                }}
                                id='9'
                                name='https://player.vimeo.com/video/779141404'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'phòng khám túi mật'}
                                {/* 담낭클리닉 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>
                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '10' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '10' ? '#fff' : '',
                                }}
                                id='10'
                                name='https://player.vimeo.com/video/783852548'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'phòng khám ung thư phổi'}
                                {/* 폐암클리닉 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>
                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '11' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '11' ? '#fff' : '',
                                }}
                                id='11'
                                name='https://player.vimeo.com/video/783852823'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'ung thư đại tràng'}
                                {/* 대장암 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>
                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '12' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '12' ? '#fff' : '',
                                }}
                                id='12'
                                name='https://player.vimeo.com/video/784944907'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'phòng khám nội tiết'}
                                {/* 내분비 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>
                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '13' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '13' ? '#fff' : '',
                                }}
                                id='13'
                                name='https://player.vimeo.com/video/891733051'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'dị ứng'}
                                {/* 두드러기 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>
                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '14' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '14' ? '#fff' : '',
                                }}
                                id='14'
                                name='https://player.vimeo.com/video/891734873'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'ung thư đại tràng'}
                                {/* 대장암 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>
                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '15' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '15' ? '#fff' : '',
                                }}
                                id='15'
                                name='https://player.vimeo.com/video/891738257'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'chứng điện giật'}
                                {/* 뇌전증 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>
                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '16' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '16' ? '#fff' : '',
                                }}
                                id='16'
                                name='https://player.vimeo.com/video/891739509'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'Khoa phẫu thuật chỉnh hình'}
                                {/* 정형외과 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>
                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '17' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '17' ? '#fff' : '',
                                }}
                                id='17'
                                name='https://player.vimeo.com/video/891740533'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'tuyến giáp'}
                                {/* 갑상선 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>
                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '18' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '18' ? '#fff' : '',
                                }}
                                id='18'
                                name='https://player.vimeo.com/video/891741383'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'cấy ghép răng'}
                                {/* 치아임플란트 */}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>
                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '19' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '19' ? '#fff' : '',
                                }}
                                id='19'
                                name='https://player.vimeo.com/video/891743248'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {'phòng khám nan y'}
                                {/* 난임 클리닉*/}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>

                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '20' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '20' ? '#fff' : '',
                                }}
                                id='20'
                                name='https://player.vimeo.com/video/898034125'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {/* 부인암 */}
                                {'ung thư phu nhân'}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>

                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '21' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '21' ? '#fff' : '',
                                }}
                                id='21'
                                name='https://player.vimeo.com/video/898040403'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {/* 난청 */}
                                {'sự khó nghe'}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>

                            <button
                                className='videoList_btn'
                                style={{
                                    backgroundColor: selectedVideoIndex === '22' ? '#274fd5' : '',
                                    color: selectedVideoIndex === '22' ? '#fff' : '',
                                }}
                                id='22'
                                name='https://player.vimeo.com/video/899719457'
                                onClick={(e) => {
                                    changeVideo(e);
                                }}
                            >
                                {/* 유방암 */}
                                {'ung thư vú'}
                                <IoIosArrowDropright className='icon_arrow'/>
                            </button>

                            <button className='videoList_btn'>라인맞추기용</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* 예약 form */}
            <div id='new_contact' className='new_contact section'>
                <div className='container container_s'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='naccs'>
                                <div className='grid custom_accodian'>
                                    <div className='row custom_contact'>
                                        <div className='col-lg-3 custom_call'>
                                            {/* <!-- 전화 상담 --> */}
                                            <ul>
                                                <li>
                                                    <img src={icon_call} alt='문의전화' />
                                                </li>
                                                {/* <!-- icon img --> */}
                                                <li>
                                                    <p className='custom_ask'>
                                                        Nếu các bạn có gì thắc mắc
                                                        <br />
                                                        Chúng tôi sẽ tư vấn một cách thân thiện.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className='custom_servicenumber'>
                                                        +82 70-8874-9334
                                                        +82 10-2127-9334
                                                    </p>
                                                </li>
                                            </ul>
                                            {/* <a href='#'> */}
                                            <button className='custom_call_btn'>Tư vấn qua điện thoại</button>
                                            {/* </a> */}
                                        </div>
                                        <div
                                            className='col-lg-8 custom_accordion nacc accordion'
                                            id='accordionExample'
                                        >
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingOne'>
                                                    <button
                                                        className='accordion-button'
                                                        type='button'
                                                        name='section'
                                                        onClick={updateField}
                                                        value='병원예약'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseOne'
                                                        aria-expanded='true'
                                                        aria-controls='collapseOne'
                                                    >
                                                        {/* <img src={reserve01} alt="Hospital appointment" /> */}
                                                        {/*병원예약*/}
                                                        đặt chỗ ở bệnh viện
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseOne'
                                                    className='accordion-collapse collapse show'
                                                    aria-labelledby='headingOne'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body custom_accordion-body'>
                                                        <form
                                                            className='custom_form'
                                                            ref={form}
                                                            onSubmit={sendEmail}
                                                        >
                                                            <div className='custom_name divs'>
                                                                <label>Tên</label>
                                                                <input
                                                                    type='text'
                                                                    name='user_name'
                                                                    onChange={updateField}
                                                                />
                                                            </div>
                                                            <div className='custom_number divs'>
                                                                <label>thông tin liên lạc</label>
                                                                <input
                                                                    id='phone'
                                                                    type='tel'
                                                                    name='phone'
                                                                    onChange={updateField}
                                                                />
                                                                {/* <input type="submit" className="btn" value="Verify" /> */}
                                                            </div>
                                                            <div className='custom_email divs'>
                                                                <label>email</label>
                                                                <input
                                                                    type='email'
                                                                    name='user_email'
                                                                    onChange={updateField}
                                                                />
                                                            </div>
                                                            <div className='custom_selection divs'>
                                                                <label>khoa khám bệnh</label>
                                                                <select
                                                                    onChange={updateField}
                                                                    name='detail_section'
                                                                >
                                                                    <option value='상급·종합병원'>bệnh viện cấp cao • bệnh viện tổng hợp</option>
                                                                    <option value='종합검진'>khám tổng quát</option>
                                                                    <option value='정형외과'>Khoa phẫu thuật chỉnh hình</option>
                                                                    <option value='산부인과'>phẫu thuật thẩm mỹ</option>
                                                                    <option value='성형외과'>sản phụ khoa</option>
                                                                    <option value='피부과'>da liễu</option>
                                                                    <option value='치과'>nha khoa</option>
                                                                    <option value='안과'>nhãn khoa</option>
                                                                    <option value='한방'>đông y</option>
                                                                    <option value='기타'>...v.v</option>
                                                                </select>
                                                            </div>
                                                            <div className='custom_language divs'>
                                                                <label>ngôn ngữ </label>
                                                                <div className='custom_radioselect'>
                                                                    <div className='custom_langua'>
                                                                        <input
                                                                            type='radio'
                                                                            name='user_language'
                                                                            value='English'
                                                                            onChange={updateField}
                                                                        />
                                                                        <p>tiếng Anh</p>
                                                                    </div>
                                                                    <div className='custom_langua'>
                                                                        <input
                                                                            type='radio'
                                                                            name='user_language'
                                                                            value='Russian'
                                                                            onChange={updateField}
                                                                        />
                                                                        <p>tiếng Nga</p>
                                                                    </div>
                                                                    {/* <div className="custom_langua">
                                    <input type="radio" name="user_language" value="Japanese" onChange={updateField}/>
                                    <p>Japanese</p>
                                </div> */}
                                                                    <div className='custom_langua'>
                                                                        <input
                                                                            type='radio'
                                                                            name='user_language'
                                                                            value='Chinese'
                                                                            onChange={updateField}
                                                                        />
                                                                        <p>tiếng Trung</p>
                                                                    </div>
                                                                    <div className='custom_langua'>
                                                                        <input
                                                                            type='radio'
                                                                            name='user_language'
                                                                            value='Vietnamese'
                                                                            defaultChecked
                                                                            onChange={updateField}
                                                                        />
                                                                        <p>tiếng Việt</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='custom_contentbox divs'>
                                                                <label>nội dung</label>
                                                                <textarea
                                                                    name='message'
                                                                    onChange={updateField}
                                                                />
                                                            </div>
                                                            <input
                                                                type='submit'
                                                                value='gửi mail'
                                                                className='custom_sendbtn'
                                                            />
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 병원 예약 끝*/}
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingTwo'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        name='section'
                                                        onClick={updateField}
                                                        value='컨시어지'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseTwo'
                                                        aria-expanded='false'
                                                        aria-controls='collapseTwo'
                                                    >
                                                        {/* <img src={reserve02} alt="Concierge" /> */}
                                                        {/*컨시어지*/}
                                                        Hướng dẫn viên-
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseTwo'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingTwo'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body custom_accordion-body'>
                                                        <form
                                                            className='custom_form'
                                                            ref={form}
                                                            onSubmit={sendEmail}
                                                        >
                                                            <div className='custom_name divs'>
                                                                <label>Tên</label>
                                                                <input
                                                                    type='text'
                                                                    name='user_name'
                                                                    onChange={updateField}
                                                                />
                                                            </div>
                                                            <div className='custom_number divs'>
                                                                <label>thông tin liên lạc</label>
                                                                <input
                                                                    id='phone'
                                                                    type='tel'
                                                                    name='phone'
                                                                    onChange={updateField}
                                                                />
                                                                {/* <input type="submit" className="btn" value="Verify" /> */}
                                                            </div>
                                                            <div className='custom_email divs'>
                                                                <label>email</label>
                                                                <input
                                                                    type='email'
                                                                    name='user_email'
                                                                    onChange={updateField}
                                                                />
                                                            </div>
                                                            <div className='custom_selection divs'>
                                                                <label>khoa khám bệnh </label>
                                                                <select
                                                                    onChange={updateField}
                                                                    name='detail_section'
                                                                >
                                                                    <option value='상급·종합병원'>bệnh viện cấp cao • bệnh
                                                                        viện tổng hợp
                                                                    </option>
                                                                    <option value='종합검진'>khám tổng quát</option>
                                                                    <option value='정형외과'>Khoa phẫu thuật chỉnh hình</option>
                                                                    <option value='산부인과'>phẫu thuật thẩm mỹ</option>
                                                                    <option value='성형외과'>sản phụ khoa</option>
                                                                    <option value='피부과'>da liễu</option>
                                                                    <option value='치과'>nha khoa</option>
                                                                    <option value='안과'>nhãn khoa</option>
                                                                    <option value='한방'>đông y</option>
                                                                    <option value='기타'>khác</option>
                                                                </select>
                                                            </div>
                                                            <div className='custom_language divs'>
                                                                <label>ngôn ngữ</label>
                                                                <div className='custom_radioselect'>
                                                                    <div className='custom_langua'>
                                                                        <input
                                                                            type='radio'
                                                                            name='user_language'
                                                                            value='English'
                                                                            onChange={updateField}
                                                                        />
                                                                        <p>tiếng Anh</p>
                                                                    </div>
                                                                    <div className='custom_langua'>
                                                                        <input
                                                                            type='radio'
                                                                            name='user_language'
                                                                            value='Russian'
                                                                            onChange={updateField}
                                                                        />
                                                                        <p>tiếng Nga</p>
                                                                    </div>
                                                                    {/* <div className="custom_langua">
                                    <input type="radio" name="user_language" value="Japanese" onChange={updateField}/>
                                    <p>Japanese</p>
                                </div> */}
                                                                    <div className='custom_langua'>
                                                                        <input
                                                                            type='radio'
                                                                            name='user_language'
                                                                            value='Chinese'
                                                                            onChange={updateField}
                                                                        />
                                                                        <p>tiếng Trung</p>
                                                                    </div>
                                                                    <div className='custom_langua'>
                                                                        <input
                                                                            type='radio'
                                                                            name='user_language'
                                                                            value='Vietnamese'
                                                                            onChange={updateField}
                                                                            defaultChecked
                                                                        />
                                                                        <p>tiếng Việt </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={cn(
                                                                    style.concierge_div,
                                                                    style.form_div
                                                                )}
                                                            >
                                                                <div className={style.dateset}>
                                                                    <p>chọn ngày</p>
                                                                    <DatePicker
                                                                        name='start'
                                                                        selected={startDate}
                                                                        onChange={(data, e) => {
                                                                            setStartDate(data);
                                                                            addedDate(data, e);
                                                                        }}
                                                                        showTimeSelect
                                                                        filterTime={filterPassedTime}
                                                                        dateFormat='yyyy-MM-dd, h:mm aa'
                                                                    />
                                                                </div>
                                                                <div className={style.memberset}>
                                                                    <p>số người</p>
                                                                    <select
                                                                        onChange={updateField}
                                                                        name='user_count'
                                                                    >
                                                                        <option>1</option>
                                                                        <option>2</option>
                                                                        <option>3</option>
                                                                        <option>4</option>
                                                                        <option>5</option>
                                                                        <option>6</option>
                                                                        <option>7</option>
                                                                        <option>8</option>
                                                                        <option>9</option>
                                                                        <option>10</option>
                                                                    </select>
                                                                </div>
                                                                <div className={style.destinationset}>
                                                                    <p>điểm xuất phát / điểm đến</p>
                                                                    <input
                                                                        type='text'
                                                                        placeholder=''
                                                                        name='destination'
                                                                        onChange={updateField}
                                                                    />
                                                                </div>
                                                                {/* <button className={style.carbtn}>차량예약</button> */}
                                                            </div>
                                                            <input
                                                                type='submit'
                                                                value='gửi mail'
                                                                className='custom_sendbtn'
                                                            />
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingThree'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        name='section'
                                                        onClick={updateField}
                                                        value='호텔예약'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseThree'
                                                        aria-expanded='false'
                                                        aria-controls='collapseThree'
                                                    >
                                                        {/* <img className="btn_icon" src={reserve03} alt="Hotel reservation" /> */}
                                                        {/*호텔예약*/}
                                                        Đặt phòng khách sạn
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseThree'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingThree'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body custom_accordion-body'>
                                                        <form
                                                            className='custom_form'
                                                            ref={form}
                                                            onSubmit={sendEmail}
                                                        >
                                                            <div className='custom_name divs'>
                                                                <label>Tên</label>
                                                                <input
                                                                    type='text'
                                                                    name='user_name'
                                                                    onChange={updateField}
                                                                />
                                                            </div>
                                                            <div className='custom_number divs'>
                                                                <label>thông tin liên lạc</label>
                                                                <input
                                                                    id='phone'
                                                                    type='tel'
                                                                    name='phone'
                                                                    onChange={updateField}
                                                                />
                                                                {/* <input type="submit" className="btn" value="Verify" /> */}
                                                            </div>
                                                            <div className='custom_email divs'>
                                                                <label>email</label>
                                                                <input
                                                                    type='email'
                                                                    name='user_email'
                                                                    onChange={updateField}
                                                                />
                                                            </div>
                                                            <div className='custom_selection divs'>
                                                                <label>khoa y tế </label>
                                                                <select
                                                                    onChange={updateField}
                                                                    name='detail_section'
                                                                >
                                                                    <option value='상급·종합병원'>bệnh viện cấp cao • bệnh
                                                                        viện tổng hợp
                                                                    </option>
                                                                    <option value='종합검진'>khám tổng quát</option>
                                                                    <option value='정형외과'>Khoa phẫu thuật chỉnh hình</option>
                                                                    <option value='산부인과'>phẫu thuật thẩm mỹ</option>
                                                                    <option value='성형외과'>sản phụ khoa</option>
                                                                    <option value='피부과'>da liễu</option>
                                                                    <option value='치과'>nha khoa</option>
                                                                    <option value='안과'>nhãn khoa</option>
                                                                    <option value='한방'>đông y</option>
                                                                    <option value='기타'>khác</option>
                                                                </select>
                                                            </div>
                                                            <div className='custom_hotel divs'>
                                                                <label>tên khách sạn</label>
                                                                <input
                                                                    type='hotel'
                                                                    name='hotel'
                                                                    onChange={updateField}
                                                                />
                                                            </div>
                                                            <div className='custom_language divs'>
                                                                <label>ngôn ngữ </label>
                                                                <div className='custom_radioselect'>
                                                                    <div className='custom_langua'>
                                                                        <input
                                                                            type='radio'
                                                                            name='user_language'
                                                                            value='English'
                                                                            onChange={updateField}
                                                                        />
                                                                        <p>tiếng Anh </p>
                                                                    </div>
                                                                    <div className='custom_langua'>
                                                                        <input
                                                                            type='radio'
                                                                            name='user_language'
                                                                            value='Russian'
                                                                            onChange={updateField}
                                                                        />
                                                                        <p>tiếng Nga</p>
                                                                    </div>
                                                                    <div className='custom_langua'>
                                                                        <input
                                                                            type='radio'
                                                                            name='user_language'
                                                                            value='Chinese'
                                                                            onChange={updateField}
                                                                        />
                                                                        <p>tiếng Trung</p>
                                                                    </div>
                                                                    <div className='custom_langua'>
                                                                        <input
                                                                            type='radio'
                                                                            name='user_language'
                                                                            value='Vietnamese'
                                                                            defaultChecked
                                                                            onChange={updateField}
                                                                        />
                                                                        <p>tiếng Việt</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={cn(style.hotel_div, style.form_div)}
                                                            >
                                                                <div
                                                                    className={cn(
                                                                        style.dateset,
                                                                        style.hotel_dateset
                                                                    )}
                                                                >
                                                                    <p>chọn ngày</p>
                                                                    <div className={style.datepickeralign}>
                                                                        <DatePicker
                                                                            selected={startDate}
                                                                            onChange={(date) => {
                                                                                setStartDate(date);
                                                                                addedDate(date);
                                                                            }}
                                                                            selectsStart
                                                                            startDate={startDate}
                                                                            endDate={endDate}
                                                                            minDate={new Date()}
                                                                            name='start'
                                                                            dateFormat='MMMM d, yyyy h:mm aa'
                                                                        />
                                                                        <HiOutlineMinusSm
                                                                            className={style.icon_line}
                                                                        />
                                                                        <DatePicker
                                                                            name='finish'
                                                                            selected={endDate}
                                                                            onChange={(date) => {
                                                                                setEndDate(date);
                                                                                endedDate(date);
                                                                            }}
                                                                            selectsEnd
                                                                            startDate={startDate}
                                                                            endDate={endDate}
                                                                            minDate={startDate}

                                                                            dateFormat='MMMM d, yyyy h:mm aa'
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className={style.memberset}>
                                                                    <p>số người</p>
                                                                    <select
                                                                        onChange={updateField}
                                                                        name='user_count'
                                                                    >
                                                                        <option>1</option>
                                                                        <option>2</option>
                                                                        <option>3</option>
                                                                        <option>4</option>
                                                                        <option>5</option>
                                                                        <option>6</option>
                                                                        <option>7</option>
                                                                        <option>8</option>
                                                                        <option>9</option>
                                                                        <option>10</option>
                                                                    </select>
                                                                </div>
                                                                <div className={style.hotelroom}>
                                                                    <p>phòng</p>
                                                                    <select
                                                                        onChange={updateField}
                                                                        name='user_Room'
                                                                    >
                                                                        <option>1</option>
                                                                        <option>2</option>
                                                                        <option>3</option>
                                                                        <option>4</option>
                                                                        <option>5</option>
                                                                    </select>
                                                                </div>
                                                                {/* <button className={style.carbtn}>차량예약</button> */}
                                                            </div>
                                                            {/* .hotel_div end */}
                                                            <input
                                                                type='submit'
                                                                value='gửi mail'
                                                                className='custom_sendbtn'
                                                            />
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-item'>
                                                <h2 className='accordion-header' id='headingFour'>
                                                    <button
                                                        className='accordion-button collapsed'
                                                        name='section'
                                                        onClick={updateField}
                                                        value='통역예약'
                                                        type='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#collapseFour'
                                                        aria-expanded='false'
                                                        aria-controls='collapseFour'
                                                    >
                                                        {/* <img src={reserve04} alt="translate" /> */}
                                                        {/*통역예약*/}
                                                        Đặt chỗ phiên dịch
                                                    </button>
                                                </h2>
                                                <div
                                                    id='collapseFour'
                                                    className='accordion-collapse collapse'
                                                    aria-labelledby='headingFour'
                                                    data-bs-parent='#accordionExample'
                                                >
                                                    <div className='accordion-body custom_accordion-body'>
                                                        <form
                                                            className='custom_form'
                                                            ref={form}
                                                            onSubmit={sendEmail}
                                                        >
                                                            <div className='custom_name divs'>
                                                                <label>Tên</label>
                                                                <input
                                                                    type='text'
                                                                    name='user_name'
                                                                    onChange={updateField}
                                                                />
                                                            </div>
                                                            <div className='custom_number divs'>
                                                                <label>thông tin liên lạc</label>
                                                                <input
                                                                    id='phone'
                                                                    type='tel'
                                                                    name='phone'
                                                                    onChange={updateField}
                                                                />
                                                                {/* <input type="submit" className="btn" value="Verify" /> */}
                                                            </div>
                                                            <div className='custom_email divs'>
                                                                <label>email</label>
                                                                <input
                                                                    type='email'
                                                                    name='user_email'
                                                                    onChange={updateField}
                                                                />
                                                            </div>
                                                            <div className='custom_selection divs'>
                                                                <label>khoa khám bệnh</label>
                                                                <select
                                                                    onChange={updateField}
                                                                    name='detail_section'
                                                                >
                                                                    <option value='상급·종합병원'>bệnh viện cấp cao • bệnh
                                                                        viện tổng hợp
                                                                    </option>
                                                                    <option value='종합검진'>khám tổng quát</option>
                                                                    <option value='정형외과'>Khoa phẫu thuật chỉnh hình</option>
                                                                    <option value='산부인과'>phẫu thuật thẩm mỹ</option>
                                                                    <option value='성형외과'>sản phụ khoa</option>
                                                                    <option value='피부과'>da liễu</option>
                                                                    <option value='치과'>nha khoa</option>
                                                                    <option value='안과'>nhãn khoa</option>
                                                                    <option value='한방'>đông y</option>
                                                                    <option value='기타'>khác</option>
                                                                </select>
                                                            </div>

                                                            <div
                                                                className={cn(
                                                                    style.translate_div,
                                                                    style.form_div
                                                                )}
                                                            >
                                                                <div className={style.langset}>
                                                                    <p>lựa chọn ngôn ngữ </p>
                                                                    <select
                                                                        onChange={updateField}
                                                                        name='user_language'
                                                                    >
                                                                        <option value='Chinese'>tiếng Trung</option>
                                                                        <option value='English'>tiếng Anh</option>
                                                                        <option value='Russian'>tiếng Nga</option>
                                                                        <option value='Vietnamese' selected>tiếng Việt</option>
                                                                    </select>
                                                                </div>
                                                                <div className={style.dateset}>
                                                                    <p>chọn ngày</p>
                                                                    <DatePicker

                                                                        selected={startDate}
                                                                        onChange={(data, e) => {
                                                                            setStartDate(data);
                                                                            addedDate(data, e);
                                                                        }}
                                                                        showTimeSelect
                                                                        filterTime={filterPassedTime}
                                                                        timeCaption='시간'
                                                                        dateFormat='MMMM d, yyyy h:mm aa'
                                                                    />
                                                                </div>
                                                                <div className={style.destinationset}>
                                                                    <p>điểm xuất phát / điểm đến</p>
                                                                    <input
                                                                        type='text'
                                                                        placeholder=''
                                                                        name='destination'
                                                                        onChange={updateField}
                                                                    />
                                                                </div>
                                                                {/* <button className={style.carbtn}>차량예약</button> */}
                                                            </div>
                                                            <input
                                                                type='submit'
                                                                value='gửi mail'
                                                                className='custom_sendbtn'
                                                            />
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* accordion end */}
                                    </div>
                                    {/*  <!-- .row end --> */}
                                </div>
                                {/* <!-- .grid end --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* VR 영상 23-05-12 위치 이동 */}
            <div id='vr_videos' className='col-lg-12 vr_videos'>
                <div className='container'>
                    <div className='vr_video_wrap'>
                        <ul className='vr_video_list'>
                            <li>
                                <iframe
                                    src='https://player.vimeo.com/video/783849715?h=28004e4a8a&badge=0&autopause=0&player_id=0&app_id=58479/embed'
                                    frameborder='0'
                                    allow='autoplay; fullscreen'
                                    allowfullscreen
                                    title='custom_video'
                                ></iframe>
                            </li>
                            <li>
                                <iframe
                                    src={`${starSrc}`}
                                    frameborder='0'
                                    allow='autoplay; fullscreen'
                                    allowfullscreen
                                    title='custom_video'
                                ></iframe>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* BEST 3 Collection */}
            <div id='board' className='board col-lg-12'>
                <div className='container custom_board'>
                    <div className='section-heading'>
                        <h4>BEST 3 Collection</h4>
                    </div>
                    {isLoading ? null : best_pop()}
                </div>
            </div>

            <div className='footer'>
                <div className='custom_footcontainer'>
                    <div className='row'>
                        <div className='col-lg-12 custom_footer'>
                            <h5>(Ghi chú)Ông Busan Thiên</h5>
                            <p className='custom_footerp'>
                                Nhà ga hành khách quốc tế Busan 206 Chungjangdaero, Donggu, Busan 3B08
                                (Trung tâm Busan Medi &amp; khu du thuyền)
                            </p>
                            <ul className='custom_footerul'>
                                <li>
                                    <span>T</span> 070-8874-9334
                                </li>
                                <li>
                                    <span>F</span> 051-462-9334
                                </li>
                                <li>
                                    <span>E</span> busancruiselounge@gmail.com
                                </li>
                            </ul>
                            <p>Copyright 2023. BUSAN TNC All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default VietnamPage;
