import '../../assets/css/style.css';
import '../../assets/css/custom.css';
import React, {useState} from 'react';
import {unAuthClient,authClient} from '../../lib/axios';


const Login = () => {
    const [Input, setInput] = useState({
        email: '',
        pass: '',
    });
    const EmailHandle = (e) => {
        setInput({
            ...Input,
            [e.target.name]: e.target.value
        });
        //SetEmail(e.target.value);
    };
    const submitHandle = async (e) => {
        e.preventDefault();

        try {
            // await axios.post("/login",option)
            const {data} = await unAuthClient.post('/login', {data:Input});
            console.log('data', data)
            localStorage.setItem('login_T', data);
            document.location.href = '/view';
        } catch (error) {
            window.alert('접근 권한이 없습니다');
            console.error('You have an error in your code or there are Network issues.', error
            )
        }
    };

    return (
        <>
            <div className="login_wrap">
                <form className="login_form" onSubmit={submitHandle}>
                    <p className="login_signin">Sign-In</p>
                    <div className="login_input">
                        <label>Email address</label>
                        <input type="text" name="email" placeholder="put your email address here"
                               onChange={EmailHandle}/>
                        <label>Password</label>
                        <input type="password" name="pass" placeholder="put your password here" onChange={EmailHandle}/>
                    </div>
                    <button className="login_btn">LOGIN</button>
                </form>
            </div>
        </>
    )
}

export default Login;