import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { CSVLink } from "react-csv";

import "../assets/css/style.css";
import style from "../assets/css/custom.css";

import logo from "../assets/images/logo.svg";

import { HiChevronDoubleLeft } from "react-icons/hi";
import { HiChevronDoubleRight } from "react-icons/hi";
import { HiOutlineSearch } from "react-icons/hi";
import {authClient} from "../lib/axios";

let queryResult = [];
const Manage = () => {
  //사용자정보 불러오는
  const [users, setUsers] = useState();
  //select option에 따라 데이터 불러오는
  const [options, setOptions] = useState("ALL");
  const [selecDate, setSelecDate] = useState();
  //lodaing은 필수로 넣는 항목이라 생각하면 됨.
  const [isLoading, SetLoading] = useState(true);

  //#페이지네이션
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [postsPerPage] = useState(13); // 한 페이지당 게시물 개수
  const indexOfLastPost = currentPage * postsPerPage; // 해당 페이지 마지막 인덱스
  const indexOfFirstPost = indexOfLastPost - postsPerPage; // 해당 페이지 첫 번째 인덱스
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost); // 해당 페이지에서 보여질 게시물 배열

  const changePage = (idx) => {
    setCurrentPage(idx.selected + 1);
  };
  //#페이지네이션

  //로그아웃
  const getLogout = () => {
    try {
      authClient.get("/logout").then((res) => {
        console.log("로그아웃 됨", res);
        if (res.data.success) {
          document.location.href = "/login";
        } else {
          alert("로그아웃 실패");
        }
      });
    } catch (err) {
      console.log("logOut error:", err);
    }
  };
  let tableElement = [];

  useEffect(() => {
    // try {
    //   // 프론트에서 백단으로 토큰이 유효한지 확인을 해야할 것 같은데...
    //   const token = localStorage.getItem('login_T');
    //   const option = {
    //     header: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       "x-access-token": token,
    //     },
    //   };
    //   console.log('token',token)
    //   const res = await authClient.post("/view", option, {
    //
    //   });
    //   //.then(response=>JSON.stringify(response))
    //   //.then(responseData =>{setUsers(responseData.item) } )
    //   setUsers(res.data);
    //   setPosts(res.data);
    //
    //   //console.log('data is :',JSON.stringify(res)); //서버에서 JSON으로 보내줘서 JSON으로 받아올 필요없다!
    //
    //   queryResult = res.data;
    //   queryResult.map((el, index) => {
    //     el.DATE = moment(el.DATE).format("YYYY-MM-DD");
    //   });
    //   //console.log("res:",res);
    //   console.log("set:", queryResult);
    //   SetLoading(false);
    // } catch (e) {
    //   window.alert("접근권한이 없습니다.");
    //   console.error(e.message);
    // }
    getViewMessageDataList();
  }, []);

  const getViewMessageDataList = async()=>{
    try {
      // 프론트에서 백단으로 토큰이 유효한지 확인을 해야할 것 같은데...
      const token = localStorage.getItem('login_T');
      // const option = {
      //   header: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //     "x-access-token": token,
      //   },
      // };
      const res = await authClient.post("/view");
      // const res = await authClient.post("/view", option, {
      //
      // });

      console.log('view res',res)
      setUsers(res.data);
      setPosts(res.data);

      //console.log('data is :',JSON.stringify(res)); //서버에서 JSON으로 보내줘서 JSON으로 받아올 필요없다!

      queryResult = res.data;
      queryResult.map((el, index) => {
        el.DATE = moment(el.DATE).format("YYYY-MM-DD");
      });
      //console.log("res:",res);
      console.log("set:", queryResult);
      SetLoading(false);
    } catch (e) {
      window.alert("접근권한이 없습니다.");
      console.error(e.message);
    }
  }
  //CSV추출
  const createCsvFileName = () => `data_${moment().format()}.csv`;
  const csvData = posts.map((item) => ({
    name: item.NAME,
    phone: item.CONTACT_NUMBER,
    email: item.EMAIL,
    language: item.LANG_PREFERENCE ? item.LANG_PREFERENCE : item.TRANS_LANG,
    section: item.MEDICAL_SECTION,
    detail_section: item.DETAIL_SECTION,
    register_day: item.REGISTRATION_DATE
      ? moment(item.REGISTRATION_DATE).format("YYYY-MM-DD HH:mm")
      : null,
    register_END_day: item.REGISTRATION_END
      ? moment(item.REGISTRATION_END).format("YYYY-MM-DD HH:mm")
      : null,
    hotel: item.HOTEL,
    count: item.NUMBER_PEOPLE,
    room_N: item.ROOM,
  }));

  //날짜검색
  const selectDate = (value) => {
    setSelecDate(value);
  };
  //항목검색
  const selectOption = (value) => {
    setSelecDate(""); //항목 다시 선택하면 날짜 초기화
    setOptions(value);
  };

  const getOption = () => {
    const filterOption = users.filter((el) => {
      if (options === "ALL" || options === "") {
        if (selecDate) {
          console.log(el.DATE);
          return el.DATE === selecDate;
        } else {
          return el;
        }
      } else {
        if (selecDate) {
          console.log(el.DATE);
          return el.DATE === selecDate && el.MEDICAL_SECTION === options;
        } else {
          return el.MEDICAL_SECTION === options;
        }
      }
      //return (el.MEDICAL_SECTION===options || el.DATE===selecDate);
    });

    filterOption &&
      filterOption.map((el, index) => {
        tableElement.push(
          <tr>
            <td key={index}>{el.POST_INDEX}</td>
            <td>
              <Link to={`/detail/${el.POST_INDEX}`}>
                {el.NAME}님의 {el.MEDICAL_SECTION}_{el.DETAIL_SECTION} 문의
                입니다.
              </Link>
            </td>
            <td>{el.NAME}</td>
            <td>{el.CONTACT_NUMBER}</td>
            <td>{el.LANG_PREFERENCE}</td>
            <td>{el.MEDICAL_SECTION}</td>
            <td>{moment(el.DATE).format("YYYY-MM-DD")}</td>
            {/* <td className="unconfirmed"><span>미확인</span></td> */}
          </tr>
        );
      });
    //return위치 변경 원래 return(테이블)-> element.push(테이블)->return(element)
    return tableElement;
  };

  return (
    // <meta name="viewport" content="width=device-width, initial-scale=1.0">
    // <title>부산티엔씨 관리자페이지</title>
    // <link rel="stylesheet" href="./css/reset.css">
    // <link rel="stylesheet" href="./css/style.css">

    <body>
      <header>
        <h1>
          <Link to="/">
            <img src={logo} alt="TNC logo" />
          </Link>
        </h1>
        <ul>
          <li>
            <Link to="/">home</Link>
          </li>
          <li>
            <a>
              <div onClick={getLogout}>logout</div>
            </a>
          </li>
        </ul>
      </header>
      {/* <div id="container">
        <div className="contents">
            <div>
                <div className="tit">
                    <h2>회원 및 문의관리</h2>
                    <div> */}
      <div id="body_flex">
        <div id="sidebar">
          <ul>
            <li>
              <Link to="/view">메세지함</Link>
            </li>
            <li>
              <Link to="/editpage">베스트 3선 패키지</Link>
            </li>
          </ul>
        </div>
        <div id="container">
          <div className="contents">
            <div>
              <div className="tit">
                <h2>메세지함</h2>
                <div>
                  <select
                    name="items1"
                    vlaue={options}
                    onChange={(e) => selectOption(e.target.value)}
                  >
                    <option value="ALL">항목별</option>
                    <option value="병원예약">병원예약</option>
                    <option value="컨시어지">컨시어지</option>
                    <option value="호텔예약">호텔예약</option>
                    <option value="통역예약">통역예약</option>
                  </select>
                  {/* <select name="items1">
                            <option value="HTML">일자별</option>
                            <option value="자바스크립트">자바스크립트</option>
                            <option value="CSS">CSS</option>
                        </select> */}

                  <div className="input_date">
                    <input
                      type="date"
                      onChange={(e) => selectDate(e.target.value)}
                    ></input>
                  </div>

                  {/* <span className="search">
                            <input type="search" id="mySearch" name=""/>
                        </span> */}
                  <CSVLink
                    data={csvData}
                    filename={createCsvFileName}
                    className="excel"
                  >
                    엑셀 다운로드
                  </CSVLink>
                </div>
              </div>
              <div className="table_area">
                <table className="table01">
                  <thead>
                    <tr>
                      <th scope="col">NO.</th>
                      <th scope="col">문의내용</th>
                      <th scope="col">이름</th>
                      <th scope="col">연락처</th>
                      <th scope="col">언어</th>
                      <th scope="col">항목</th>
                      <th scope="col">일자</th>
                      {/* <th scope="col">확인여부</th> */}
                    </tr>
                  </thead>
                  <tbody>{isLoading ? null : getOption()}</tbody>
                </table>
              </div>
            </div>
            <div className="pagination">
              {/* <ul>
                    <li className="start"><a href="#">맨처음으로</a></li>
                    <li className="prev"><a href="#">이전</a></li>
                    <li><a href="#" className="on">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">4</a></li>
                    <li><a href="#">5</a></li>
                    <li><a href="#">6</a></li>
                    <li><a href="#">7</a></li>
                    <li><a href="#">8</a></li>
                    <li className="next"><a href="#">다음</a></li>
                    <li className="end"><a href="#">맨뒤로</a></li>
                </ul> */}
              <ReactPaginate
                pageCount={posts.length / 14}
                pageRangeDisplayed={5}
                marginPagesDisplayed={3}
                breakLabel={"..."}
                previousLabel={<HiChevronDoubleLeft />}
                nextLabel={<HiChevronDoubleRight />}
                onPageChange={changePage}
                containerClassName={style.pagination_ul}
                activeClassName={style.current}
                previousClassName={style.page_btn}
                nextClassName={style.page_btn}
                pageLinkClassName={style.number}
              />
            </div>
          </div>
          {/* <!--.contents--> */}
        </div>
      </div>
      {/* <!--#container--> */}
      <div id="footer">
        <p>ⓒ 2021 BUSAN TNC All rights reserved.</p>
      </div>
    </body>
  );
};

export default Manage;
