import { useEffect, useRef, useState, StyleSheet } from 'react';

import cn from 'classnames';

/* DatePicker */
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
/* bootstrap */
import 'bootstrap/dist/js/bootstrap.bundle.min';

/* CSS */
import '../assets/css/fontawesome.css';
import '../assets/css/custom.css'; /* 수정한 CSS */
import '../assets/css/animated.css';
import '../assets/css/owl.css';
import style from '../components/Modal/Modal.module.css';
/* imgs */
import logo from '../assets/images/tnc_newlogo.jpeg';
import firstplace from '../assets/images/custom_1level_2x.png';
import icon_call from '../assets/images/custom_iconcall.png';
import medibusan from '../assets/images/custom_meditour_2x.png';
import KO from '../assets/images/flag_kr.png';
import US from '../assets/images/flag_us.png';
import RU from '../assets/images/flag_ru.png';
import CH from '../assets/images/flag_ch.png';
import VI from '../assets/images/flag_vi.png'
import weibo from '../assets/images/weibo.png'
import insta from '../assets/images/instagram.png';
import facebook from '../assets/images/facebook.png';

/* icon */
import {

  IoIosArrowForward,
  IoIosArrowDropright,
} from 'react-icons/io';

import { HiOutlineMinusSm } from 'react-icons/hi';

import { Link } from 'react-router-dom';
import Popup from '../components/Modal/Popup';
import moment from 'moment';
import  { unAuthClient } from '../lib/axios';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";

const ChPage = () => {
  const [popup, setPopup] = useState(false);
  const [best, setBest] = useState();
  const [toggleIndex, setToggleIndex] = useState('');
  const [isLoading, SetLoading] = useState(true);
  const [videoSrc, setVideoSrc] = useState(
    'https://player.vimeo.com/video/779140662?h=15f1b85152'
  );
  const [starSrc, setStarSrc] = useState(
    'https://player.vimeo.com/video/779141748?h=09df3f454e'
  );
  const [selectedList, setSelectedList] = useState('1');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const navigate = useNavigate();
  const form = useRef();
  const [initialInputData, SetInitialInputData] = useState({
    user_name: '',
    phone: '',
    user_email: '',
    section: '병원예약', //need to default value
    detail_section: '상급·종합병원',
    message: '',
    user_count: 1,
    start: moment(new Date()).format('YYYY-MM-DD HH:mm'),
    user_Room: 1,
    user_language: 'Chinese',
  });
  const togglePopup = (el) => {
    setToggleIndex(el);
    setPopup(!popup);
    console.log(el);
  };
  const sendEmail = async (event) => {
    event.preventDefault();
    console.log('input data : ', initialInputData);

    let url = 'http://192.168.0.64:11751/api/sendmail';
    const option = {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: initialInputData,
    };
    if(!_.isEmpty(initialInputData.user_name||!_.isEmpty(initialInputData.user_email))) {
      try {
        await unAuthClient
            .post('/sendmail', option);
        window.alert('success');
      } catch (err) {
        console.log('fetch error : ', err);
      } finally{
        window.location.reload();
      }
    }else{
      window.alert('Please enter required items ')
    }
  };

  const updateField = (e) => {
    if (e.target.name === 'section') {
      SetInitialInputData({
        user_name: '',
        phone: '',
        user_email: '',
        section: e.target.value,
        detail_section: '상급·종합병원',
        message: '',
        user_count: 1,
        start: moment(new Date()).format('YYYY-MM-DD HH:mm'),
        user_Room: 1,
        user_language: 'Chinese',
      });

    }else {
      SetInitialInputData({
        ...initialInputData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  /* 호텔 */

  const addedDate = (data) => {
    SetInitialInputData({
      ...initialInputData,
      start: moment(data).format('YYYY-MM-DD HH:mm'),
    });
  };
  const endedDate = (data, e) => {
    SetInitialInputData({
      ...initialInputData,
      finish: moment(data).format('YYYY-MM-DD HH:mm'),
    });
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  //베스트 3선 자세히 보기
  const best_pop = () => {
    const best_vener = [];
    best?.map((el, index) => {
      let file = el.FILE_POPUP;
      best_vener.push(
        <div className='custom_bestitems' key={index}>
          <ul>
            {/* <!-- 힐링&웰니스 투어 --> */}
            <li>
              {/* <img src={top_img} alt={el.TITLE} /> */}
              <img src={`/image/${file}`} alt={el.TITLE} />
            </li>
            {/* <!-- img --> */}
            <li>
              <p className='custom_besttitle'>{el.TITLE_ch}</p>
              <p className='custom_besttext'>{el.CONTENT_ch}</p>
            </li>
            {/* <!-- text --> */}
            <li>
              <button
                className='custom_morebtn'
                onClick={() => togglePopup(el)}
              >
                阅读更多
              </button>
            </li>
          </ul>
        </div>
      );
    });
    return best_vener;
  };

  useEffect(() => {
    const fun = async () => {
      try {
        const res = await unAuthClient.get('/amendBest').then((res) => {
          setBest(res.data);
          SetLoading(false);
        });
      } catch (e) {
        console.error(e.message);
      }
    };
    fun();
  }, []);

  const changeVideo = (event) => {
    setVideoSrc(event.target.name);
    setSelectedList(event.target.id);
  };

  const changeStarVideo = (event) => {
    setStarSrc(event.target.name);
  };

  return (
    <>
      <header className='header_area header_sticky wow slideInDown'>
        {popup && (
          <Popup
            change='ch'
            best={best}
            index={toggleIndex}
            togglePopup={togglePopup}
          />
        )}
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <nav className='main-nav'>
                {/* <!-- ***** Logo Start ***** --> */}
                <a href='/ChPage' className='logo'>
                   <img src={logo} alt="logo자리" />
                </a>
                {/* <!-- ***** Logo End ***** --> */}

                {/* <!-- ***** Menu Start ***** --> */}
                <ul className='nav'>
                  <li className='scroll-to-section'>
                    <Link to='/'>
                      <img src={US} alt='usa_flag' />
                    </Link>
                  </li>
                  {/* <!-- 영어 --> */}

                  <li className='scroll-to-section'>
                    <Link to='/KorPage'>
                      <img src={KO} alt='SouthKorea_flag' />
                    </Link>
                  </li>
                  {/* <!-- 한국어 --> */}

                  <li className='scroll-to-section'>
                    <Link to='/RuPage'>
                      <img src={RU} alt='Russia_flag' />
                    </Link>
                  </li>
                  {/* <!-- 러시아어 --> */}

                  <li className='scroll-to-section'>
                    <Link to='/ViPage'>
                      <img src={VI} alt='Vietnam_flag' />
                    </Link>
                  </li>
                  {/* <!-- 베트남어 --> */}

                  {/* 23-05-12 소셜미디어 링크 추가 */}
                  <div className='socialMedia_link'>
                    <li className='scroll-to-section'>
                      <Link to='https://weibo.com/u/7496215566?refer_flag=1005055010_'>
                        <img src={weibo} alt='weibo link' />
                      </Link>
                    </li>
                    <li className='scroll-to-section' style={{visibility: "hidden"}}>
                      <Link to='https://www.facebook.com/'>
                        <img src={facebook} alt='facebook link' />
                      </Link>
                    </li>
                  </div>
                </ul>

                <div className='nav_s'>
                  <select
                    onChange={(e) => {
                      navigate(e.target.value);
                    }}
                  >
                    <option className='scroll-to-section' href='/ChPage'>
                      CH
                    </option>
                    {/* <!-- 중국어 --> */}
                    <option className='scroll-to-section' value='/'>
                      EN
                    </option>
                    {/* <!-- 영어 --> */}
                    <option className='scroll-to-section' value='/KorPage'>
                      KO
                    </option>
                    {/* <!-- 한글 --> */}
                    <option className='scroll-to-section' value='/RuPage'>
                      RU
                    </option>
                    {/* <!-- 러시아어 --> */}
                    <option className='scroll-to-section' value='/ViPage'>
                      VI
                    </option>
                    {/* <!-- 베트남어 --> */}
                  </select>
                  {/* 23-05-12 소셜미디어 링크 추가 */}
                  <div className='socialMedia_link'>
                    <li className='scroll-to-section'>
                      <Link to='https://www.instagram.com/'>
                        <img src={insta} alt='instagram link' />
                      </Link>
                    </li>
                    <li className='scroll-to-section'>
                      <Link to='https://www.facebook.com/'>
                        <img src={facebook} alt='facebook link' />
                      </Link>
                    </li>
                  </div>
                  {/* <a href='#contact'>
                    <div className='order-first-button'>login</div>
                  </a>
                  <div className='custom_setting'>
                    <IoMdSettings className='custom_settingicon' />
                  </div> */}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- ***** Header Area End ***** --> */}

      {/* <!-- 대한민국 대표 의료관광도시 부산! --> */}
      <div
        className='main_banner wow fadeIn'
        id='top'
        data-wow-duration='1s'
        data-wow-delay='0.5s'
      >
        <div className='container custom_header'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='col-lg-12 align-self-center'>
                <div
                  className='left-content show-up header-text wow fadeInLeft'
                  data-wow-duration='1s'
                  data-wow-delay='1s'
                >
                  <div className='col-lg-12'>
                    <h6 className='custom_title'>MEDICAL TECHNOLOGY</h6>
                    <h2 className='custom_subtitle'>
                      大韩民国代表医疗观光城市 釜山!
                    </h2>
                    <p className='custom_content'>
                      釜山拥有350万市民，是韩国第二大城市，也是韩国最大的港口城市。一年四季气候宜人，有美丽的山和海等观光资源，以及举办釜山烟花节和釜山国际电影节等各种国际性的庆典活动，釜山曾被《孤独星球》评选为“亚洲第一旅游城市(2018年)”，使釜山成为世界著名的旅游休养城市之一。此外，以最优质的医疗服务和最高水平的医疗技术为基础成为亚洲代表性的医疗旅游目的地打造“最想去的医疗旅游城市釜山”，为游客提供最佳的满意度
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--의료도시 1위 수상 --> */}
      <div id='about' className='about section'>
        {/* <!-- 바탕으로 들어가는 div}
    {/* <div className="row custom_row">
      <div className="col-lg-6 blank">
        {오른쪽 빈공간 bg color만 들어감}
      </div>
      <div className="col-lg-6 bg02img">
        {왼쪽 bg img 들어감}
      </div>
    </div> */}

        <div className='row custom_row'>
          <div className='col-lg-6 bg02img'>
            {/* <!-- 왼쪽 bg img 들어감 --> */}
          </div>
        </div>

        <div className='row custom_float'>
          <div
            className='col-lg-12 align-self-center wow fadeInRight'
            data-wow-duration='1s'
            data-wow-delay='0.5s'
          >
            <div className='section-heading'>
              {/* <!-- 1숫자 --> */}
              <div className='custom_num1img'>
                <img src={firstplace} alt='의료도시 부문1위' />
              </div>
            </div>
            <h2 className='custom_subtitle'>
              获得大韩民国国家品牌“医疗城市部门”第一名
            </h2>
            <p className='custom_text'>
              釜山通过重点发展医疗观光产业力求成为东北亚医疗观光中心城市，在韩国首次引进重粒子加速器(2023)和机器人手术中心等世界级医疗基础设施，具备了成为东北亚医疗旅游胜地的条件。此外，以拥有优秀专业人才的5000多家医疗机构和像西面医疗街等集成化的“医疗观光集群”为基础提供从整形外科，皮肤科，牙科等到脑，心脏，肿瘤治疗等重症的多样化患者群体为对象的高治疗量的医疗服务
            </p>
          </div>
        </div>
      </div>

      {/* 부산 메디컬 센터, 컨시어지 서비스 */}
      <div id='services' className='services section'>
        <div className='container custom_service'>
          <div className='row col-lg-12'>
            <div className='custom_info'>
              <div
                className='section-heading wow fadeInDown custom_info01 custom_short01'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                {/* <img src={picture01} alt='부산메디컬센터' /> */}
                <iframe
                  src='https://player.vimeo.com/video/787477437?h=c382c2818a&amp;badge=0&amp;autoplay=1&loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                  frameborder='0'
                  allow='autoplay; fullscreen; picture-in-picture'
                  allowfullscreen
                  title='쇼츠 동아대학교병원'
                ></iframe>
              </div>

              <div
                className='custom_info02 wow fadeInDown'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                <h4 className='custom_info_title'>釜山 Medicall 中心</h4>
                <h6 className='custom_info_subtext'>
                  Medicall中心是为来釜山的医疗游客提供从机场接送机到酒店预订、翻译服务、休养观光旅游产品介绍等各种便利服务使釜山医疗旅游变得更加舒适和安全的医疗旅游服务平台。
                  如果您想要详细的服务咨询或预约医院的话，您可以通过短信、电子邮件或电话进行详细咨询。我们可以用主要国家的语言进行回复。
                </h6>
              </div>
            </div>
          </div>
          {/* <!-- row 1 end --> */}
          {/* 테블릿 싸이즈 - CSS 1739 */}
          <div className='row col-lg-12'>
            <div className='custom_info custom_info_s'>
              <div
                className='custom_info02 wow fadeInDown'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                <h4 className='custom_info_title'>礼宾服务</h4>
                <h6 className='custom_info_subtext'>
                  Medicall中心事业是釜山圈医疗产业协会以釜山市预算而开发的事业，由釜山TNC运营，是减少医疗游客不便的礼宾专业平台。我们将为来访釜山的外国医疗游客提供各种个性化的礼宾服务，提供更优质的服务。
                </h6>
              </div>
              <div
                className='section-heading wow fadeInDown custom_info01 custom_short02'
                data-wow-duration='1s'
                data-wow-delay='0.5s'
              >
                {/* <img src={picture02} alt='부산메디컬센터' /> */}
                <iframe
                  src='https://player.vimeo.com/video/787477407?h=2bbea0f96b&amp;badge=0&amp;autoplay=1&loop=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                  frameborder='0'
                  allow='autoplay; fullscreen; picture-in-picture'
                  allowfullscreen
                  title='쇼츠 메디컬센터 김해'
                ></iframe>
              </div>
            </div>
          </div>
          {/* <!-- row 2 end --> */}
        </div>
      </div>

      {/* 의료기관, 호텔 소개 바로가기 */}
      <div id='banners' className='banners'>
        <div className='custom_bannerdiv'>
          <div className='custom_medicalbusanlink'>
            <img src={medibusan} alt='메디컬부산' />
          </div>
          <div className='custom_banners'>
            <div className='banner_wrap'>
              <div className='custom_banner01'>
                <p>医疗机构介绍</p>
                <a href=''>
                  <button
                    onClick={() =>
                      window.open(
                        'https://www.bsmeditour.go.kr/medicalInstitution.do',
                        '_blank'
                      )
                    }
                  >
                    快捷键 <IoIosArrowForward className='custom_icon' />
                  </button>
                </a>
              </div>
              <div className='custom_banner02'>
                <p>酒店介绍</p>
                <a href=''>
                  <button
                    onClick={() =>
                      window.open(
                        'https://www.bsmeditour.go.kr/AboutHotel.do',
                        '_blank'
                      )
                    }
                  >
                    快捷键 <IoIosArrowForward className='custom_icon' />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 클리닉 비디오, 리스트 */}
      <div id='clinic_videos' className='clinic_videos section'>
        <div className='container'>
          <div className='custom_video_list_wrap'>
            {/* <div className='vr_video_list'> */}
            <div className='clinic_video'>
              <iframe
                src={`${videoSrc}`}
                frameborder='0'
                allow='autoplay; fullscreen'
                allowfullscreen
                title='custom_video'
                className='video01'
              ></iframe>
            </div>
            <div className='videoList'>
              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '1' ? '#274fd5' : '',
                    color: selectedList === '1' ? '#fff' : '',
                  }}
                  id='1'
                  name='https://player.vimeo.com/video/779140662?h=15f1b85152'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 피부미용 */}
                {'皮肤美容诊所'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '2' ? '#274fd5' : '',
                    color: selectedList === '2' ? '#fff' : '',
                  }}
                  id='2'
                  name='https://player.vimeo.com/video/779140388?h=9759b04586'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 심장흉통 */}
                {'心脏胸痛诊所'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '3' ? '#274fd5' : '',
                    color: selectedList === '3' ? '#fff' : '',
                  }}
                  id='3'
                  name='https://player.vimeo.com/video/779140128?h=b285668944'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 면역증강 */}
                {'增强免疫力门诊'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '14' ? '#274fd5' : '',
                    color: selectedList === '4' ? '#fff' : '',
                  }}
                  id='4'
                  name='https://player.vimeo.com/video/779139955?h=9a8f8791da'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 하지정맥 */}
                {'下肢静脉门诊'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '5' ? '#274fd5' : '',
                    color: selectedList === '5' ? '#fff' : '',
                  }}
                  id='5'
                  name='https://player.vimeo.com/video/779139736?h=67bb7ad9f5'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 재건성형 */}
                {'重建整形诊所'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '6' ? '#274fd5' : '',
                    color: selectedList === '6' ? '#fff' : '',
                  }}
                  id='6'
                  name='https://player.vimeo.com/video/779138932?h=f8f5f12b89'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 혈액질환 */}
                {'血液病门诊'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '7' ? '#274fd5' : '',
                    color: selectedList === '7' ? '#fff' : '',
                  }}
                  id='7'
                  name='https://player.vimeo.com/video/779120696?h=3299cdffcc'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 시력교정 */}
                {'视力矫正诊所'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '8' ? '#274fd5' : '',
                    color: selectedList === '8' ? '#fff' : '',
                  }}
                  id='8'
                  name='https://player.vimeo.com/video/779119812?h=f800fdf097'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 소아외과 */}
                {'小儿外科诊所'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '9' ? '#274fd5' : '',
                    color: selectedList === '9' ? '#fff' : '',
                  }}
                  id='9'
                  name='https://player.vimeo.com/video/780610779'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 담낭 */}
                {'胆囊'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '10' ? '#274fd5' : '',
                    color: selectedList === '10' ? '#fff' : '',
                  }}
                  id='10'
                  name='https://player.vimeo.com/video/783852675'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 폐암클리닉 */}
                {'肺癌诊所'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '11' ? '#274fd5' : '',
                    color: selectedList === '11' ? '#fff' : '',
                  }}
                  id='11'
                  name='https://player.vimeo.com/video/784315564'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 대장암 클리닉*/}
                {'大肠癌诊所'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '12' ? '#274fd5' : '',
                    color: selectedList === '12' ? '#fff' : '',
                  }}
                  id='12'
                  name='https://player.vimeo.com/video/784944996'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 내분비 클리닉*/}
                {'内分泌门诊'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '13' ? '#274fd5' : '',
                    color: selectedList === '13' ? '#fff' : '',
                  }}
                  id='13'
                  name='https://player.vimeo.com/video/891733138'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 두드러기*/}
                {'风疹块'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '14' ? '#274fd5' : '',
                    color: selectedList === '14' ? '#fff' : '',
                  }}
                  id='14'
                  name='https://player.vimeo.com/video/891737353'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 뇌전증*/}
                {'癫痫'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '15' ? '#274fd5' : '',
                    color: selectedList === '15' ? '#fff' : '',
                  }}
                  id='15'
                  name='https://player.vimeo.com/video/891739586'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 정형외과*/}
                {'骨科'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '16' ? '#274fd5' : '',
                    color: selectedList === '16' ? '#fff' : '',
                  }}
                  id='16'
                  name='https://player.vimeo.com/video/891740617'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 갑상선*/}
                {'甲状腺 '}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '17' ? '#274fd5' : '',
                    color: selectedList === '17' ? '#fff' : '',
                  }}
                  id='17'
                  name='https://player.vimeo.com/video/891741481'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 치아 임플란트*/}
                {'牙齿种植牙'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '18' ? '#274fd5' : '',
                    color: selectedList === '18' ? '#fff' : '',
                  }}
                  id='18'
                  name='https://player.vimeo.com/video/891743288'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 난임 클리닉*/}
                {'不孕症门诊'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '19' ? '#274fd5' : '',
                    color: selectedList === '19' ? '#fff' : '',
                  }}
                  id='19'
                  name='https://player.vimeo.com/video/898034209'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 부인암 */}
                {'妇科癌'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '20' ? '#274fd5' : '',
                    color: selectedList === '20' ? '#fff' : '',
                  }}
                  id='20'
                  name='https://player.vimeo.com/video/898040506'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 난청 */}
                {'听力障碍诊所'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button
                  className='videoList_btn'
                  style={{
                    backgroundColor: selectedList === '21' ? '#274fd5' : '',
                    color: selectedList === '21' ? '#fff' : '',
                  }}
                  id='21'
                  name='https://player.vimeo.com/video/899719564'
                  onClick={(e) => {
                    changeVideo(e);
                  }}
              >
                {/* 유방암 */}
                {'乳腺癌诊所'}
                <IoIosArrowDropright className='icon_arrow'/>
              </button>

              <button className='videoList_btn'>라인맞추기용</button>
            </div>
          </div>
        </div>
      </div>

      {/* 예약 form */}
      <div id='new_contact' className='new_contact section'>
        <div className='container container_s'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='naccs'>
                <div className='grid custom_accodian'>
                  <div className='row custom_contact'>
                    <div className='col-lg-3 custom_call'>
                      {/* <!-- 전화 상담 --> */}
                      <ul>
                        <li>
                        <img src={icon_call} alt='문의전화' />
                        </li>
                        {/* <!-- icon img --> */}
                        <li>
                          <p className='custom_ask'>
                            如有疑问，
                            <br />
                            可亲切咨询。
                          </p>
                        </li>
                        <li>
                          <p className='custom_servicenumber'>
                            +82 70-8874-9334
                            +82 10-2127-9334
                          </p>
                        </li>
                      </ul>
                      <a href='#'>
                        <button className='custom_call_btn'>电话咨询</button>
                      </a>
                    </div>
                    <div
                      className='col-lg-8 custom_accordion nacc accordion'
                      id='accordionExample'
                    >
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingOne'>
                          <button
                            className='accordion-button'
                            type='button'
                            name='section'
                            onClick={updateField}
                            value='병원예약'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseOne'
                            aria-expanded='true'
                            aria-controls='collapseOne'
                          >
                            {/* <img src={reserve01} alt="Hospital appointment" /> */}
                            酒店预约
                          </button>
                        </h2>
                        <div
                          id='collapseOne'
                          className='accordion-collapse collapse show'
                          aria-labelledby='headingOne'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                              className='custom_form'
                              ref={form}
                              onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>姓名</label>
                                <input
                                  type='text'
                                  name='user_name'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>联系电话</label>
                                <input
                                  id='phone'
                                  type='tel'
                                  name='phone'
                                  onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>电邮</label>
                                <input
                                  type='email'
                                  name='user_email'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>诊疗科目</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option value='상급·종합병원'>
                                    上级·综合医院
                                  </option>
                                  <option value='종합검진'>综合检查</option>
                                  <option value='정형외과'>骨科</option>
                                  <option value='산부인과'>妇产科</option>
                                  <option value='성형외과'>整形外科</option>
                                  <option value='피부과'>皮肤科</option>
                                  <option value='치과'>牙科</option>
                                  <option value='안과'>眼科</option>
                                  <option value='한방'>韩方</option>
                                  <option value='기타'>其他</option>
                                </select>
                              </div>
                              <div className='custom_language divs'>
                                <label>语言偏好</label>
                                <div className='custom_radioselect'>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='English'
                                      onChange={updateField}
                                    />
                                    <p>英语</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='Russian'
                                      onChange={updateField}
                                    />
                                    <p>俄语</p>
                                  </div>
                                  {/* <div className="custom_langua">
                                    <input type="radio" name="user_language" value="Japanese" onChange={updateField}/>
                                    <p>日语</p>
                                </div> */}
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='Chinese'
                                      defaultChecked
                                      onChange={updateField}
                                    />
                                    <p>中文</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Vietnamese'
                                        onChange={updateField}
                                    />
                                    <p>越南语</p>
                                  </div>
                                </div>
                              </div>
                              <div className='custom_contentbox divs'>
                                <label>内容</label>
                                <textarea
                                  name='message'
                                  onChange={updateField}
                                />
                              </div>
                              <input
                                type='submit'
                                value='发送邮件'
                                className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* 병원 예약 */}
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingTwo'>
                          <button
                            className='accordion-button collapsed'
                            name='section'
                            onClick={updateField}
                            value='컨시어지'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseTwo'
                            aria-expanded='false'
                            aria-controls='collapseTwo'
                          >
                            {/* <img src={reserve02} alt="Concierge" /> */}
                            礼宾服务
                          </button>
                        </h2>
                        <div
                          id='collapseTwo'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingTwo'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                              className='custom_form'
                              ref={form}
                              onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>姓名</label>
                                <input
                                  type='text'
                                  name='user_name'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>联系电话</label>
                                <input
                                  id='phone'
                                  type='tel'
                                  name='phone'
                                  onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>电邮</label>
                                <input
                                  type='email'
                                  name='user_email'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>诊疗科目</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option value='상급·종합병원'>
                                    上级·综合医院
                                  </option>
                                  <option value='종합검진'>综合检查</option>
                                  <option value='정형외과'>骨科</option>
                                  <option value='산부인과'>妇产科</option>
                                  <option value='성형외과'>整形外科</option>
                                  <option value='피부과'>皮肤科</option>
                                  <option value='치과'>牙科</option>
                                  <option value='안과'>眼科</option>
                                  <option value='한방'>韩方</option>
                                  <option value='기타'>其他</option>
                                </select>
                              </div>
                              <div className='custom_language divs'>
                                <label>语言偏好</label>
                                <div className='custom_radioselect'>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='English'
                                      onChange={updateField}
                                    />
                                    <p>英语</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='Russian'
                                      onChange={updateField}
                                    />
                                    <p>俄语</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='Chinese'
                                      defaultChecked
                                      onChange={updateField}
                                    />
                                    <p>中文</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Vietnamese'
                                        onChange={updateField}
                                    />
                                    <p>越南语</p>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={cn(
                                  style.concierge_div,
                                  style.form_div
                                )}
                              >
                                <div className={style.dateset}>
                                  <p>选择日期</p>
                                  <DatePicker
                                    name='start'
                                    selected={startDate}
                                    onChange={(data, e) => {
                                      setStartDate(data);
                                      addedDate(data, e);
                                    }}
                                    showTimeSelect
                                    filterTime={filterPassedTime}
                                    dateFormat='MMMM d, yyyy h:mm aa'
                                  />
                                </div>
                                <div className={style.memberset}>
                                  <p>人数</p>
                                  <select
                                    onChange={updateField}
                                    name='user_count'
                                  >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                  </select>
                                </div>
                                <div className={style.destinationset}>
                                  <p>出发地/到达地</p>
                                  <input
                                    type='text'
                                    placeholder=''
                                    name='destination'
                                    onChange={updateField}
                                  />
                                </div>
                                {/* <button className={style.carbtn}>차량예약</button> */}
                              </div>
                              <input
                                type='submit'
                                value='发送邮件'
                                className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingThree'>
                          <button
                            className='accordion-button collapsed'
                            name='section'
                            onClick={updateField}
                            value='호텔예약'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseThree'
                            aria-expanded='false'
                            aria-controls='collapseThree'
                          >
                            {/* <img className="btn_icon" src={reserve03} alt="Hotel reservation" /> */}
                            酒店预约
                          </button>
                        </h2>
                        <div
                          id='collapseThree'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingThree'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                              className='custom_form'
                              ref={form}
                              onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>姓名</label>
                                <input
                                  type='text'
                                  name='user_name'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>联系电话</label>
                                <input
                                  id='phone'
                                  type='tel'
                                  name='phone'
                                  onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>电邮</label>
                                <input
                                  type='email'
                                  name='user_email'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>诊疗科目</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option value='상급·종합병원'>
                                    上级·综合医院
                                  </option>
                                  <option value='종합검진'>综合检查</option>
                                  <option value='정형외과'>骨科</option>
                                  <option value='산부인과'>妇产科</option>
                                  <option value='성형외과'>整形外科</option>
                                  <option value='피부과'>皮肤科</option>
                                  <option value='치과'>牙科</option>
                                  <option value='안과'>眼科</option>
                                  <option value='한방'>韩方</option>
                                  <option value='기타'>其他</option>
                                </select>
                              </div>
                              <div className='custom_hotel divs'>
                                <label>酒店名称</label>
                                <input
                                  type='hotel'
                                  name='hotel'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_language divs'>
                                <label>语言偏好</label>
                                <div className='custom_radioselect'>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='English'
                                      onChange={updateField}
                                    />
                                    <p>英语</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='Russian'
                                      onChange={updateField}
                                    />
                                    <p>俄语</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                      type='radio'
                                      name='user_language'
                                      value='Chinese'
                                      defaultChecked
                                      onChange={updateField}
                                    />
                                    <p>中文</p>
                                  </div>
                                  <div className='custom_langua'>
                                    <input
                                        type='radio'
                                        name='user_language'
                                        value='Vietnamese'
                                        onChange={updateField}
                                    />
                                    <p>越南语</p>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={cn(style.hotel_div, style.form_div)}
                              >
                                <div
                                  className={cn(
                                    style.dateset,
                                    style.hotel_dateset
                                  )}
                                >
                                  <p>选择日期</p>
                                  <div className={style.datepickeralign}>
                                    <DatePicker
                                      selected={startDate}
                                      onChange={(date) => {
                                        setStartDate(date);
                                        addedDate(date);
                                      }}
                                      selectsStart
                                      startDate={startDate}
                                      endDate={endDate}
                                      minDate={new Date()}
                                      name='start'
                                    />
                                    <HiOutlineMinusSm
                                      className={style.icon_line}
                                    />
                                    <DatePicker
                                      name='finish'
                                      selected={endDate}
                                      onChange={(date) => {
                                        setEndDate(date);
                                        endedDate(date);
                                      }}
                                      selectsEnd
                                      startDate={startDate}
                                      endDate={endDate}
                                      minDate={startDate}
                                    />
                                  </div>
                                </div>
                                <div className={style.memberset}>
                                  <p>人数</p>
                                  <select
                                    onChange={updateField}
                                    name='user_count'
                                  >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                  </select>
                                </div>
                                <div className={style.hotelroom}>
                                  <p>房间</p>
                                  <select
                                    onChange={updateField}
                                    name='user_Room'
                                  >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                  </select>
                                </div>
                                {/* <button className={style.carbtn}>차량예약</button> */}
                              </div>
                              {/* .hotel_div end */}
                              <input
                                type='submit'
                                value='发送邮件'
                                className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingFour'>
                          <button
                            className='accordion-button collapsed'
                            name='section'
                            onClick={updateField}
                            value='통역예약'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseFour'
                            aria-expanded='false'
                            aria-controls='collapseFour'
                          >
                            {/* <img src={reserve04} alt="translate" /> */}
                            预约翻译
                          </button>
                        </h2>
                        <div
                          id='collapseFour'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingFour'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body custom_accordion-body'>
                            <form
                              className='custom_form'
                              ref={form}
                              onSubmit={sendEmail}
                            >
                              <div className='custom_name divs'>
                                <label>姓名</label>
                                <input
                                  type='text'
                                  name='user_name'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_number divs'>
                                <label>联系电话</label>
                                <input
                                  id='phone'
                                  type='tel'
                                  name='phone'
                                  onChange={updateField}
                                />
                                {/* <input type="submit" className="btn" value="Verify" /> */}
                              </div>
                              <div className='custom_email divs'>
                                <label>电邮</label>
                                <input
                                  type='email'
                                  name='user_email'
                                  onChange={updateField}
                                />
                              </div>
                              <div className='custom_selection divs'>
                                <label>诊疗科目</label>
                                <select
                                  onChange={updateField}
                                  name='detail_section'
                                >
                                  <option value='상급·종합병원'>
                                    上级·综合医院
                                  </option>
                                  <option value='종합검진'>综合检查</option>
                                  <option value='정형외과'>骨科</option>
                                  <option value='산부인과'>妇产科</option>
                                  <option value='성형외과'>整形外科</option>
                                  <option value='피부과'>皮肤科</option>
                                  <option value='치과'>牙科</option>
                                  <option value='안과'>眼科</option>
                                  <option value='한방'>韩方</option>
                                  <option value='기타'>其他</option>
                                </select>
                              </div>
                              <div
                                className={cn(
                                  style.translate_div,
                                  style.form_div
                                )}
                              >
                                <div className={style.langset}>
                                  <p>选择语言</p>
                                  <select
                                      onChange={updateField}
                                      name='user_language'
                                  >
                                    <option value='Chinese' selected>中文</option>
                                    <option value='English'>英语</option>
                                    <option value='Russian'>俄语</option>
                                    <option value='Vietnames'>越南语</option>
                                  </select>
                                </div>
                                <div className={style.dateset}>
                                  <p>选择日期</p>
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(data, e) => {
                                      setStartDate(data);
                                      addedDate(data, e);
                                    }}
                                    showTimeSelect
                                    filterTime={filterPassedTime}
                                    dateFormat='MMMM d, yyyy h:mm aa'
                                  />
                                </div>
                                <div className={style.destinationset}>
                                  <p>出发地/到达地</p>
                                  <input
                                    type='text'
                                    placeholder=''
                                    name='destination'
                                    onChange={updateField}
                                  />
                                </div>
                                {/* <button className={style.carbtn}>차량예약</button> */}
                              </div>
                              <input
                                type='submit'
                                value='发送邮件'
                                className='custom_sendbtn'
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* accordion end */}
                  </div>
                  {/*  <!-- .row end --> */}
                </div>
                {/* <!-- .grid end --> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* VR 영상 23-05-12 위치 이동 */}
      <div id='vr_videos' className='col-lg-12 vr_videos'>
        <div className='container'>
          <div className='vr_video_wrap'>
            <ul className='vr_video_list'>
              <li>
                <iframe
                  src='https://player.vimeo.com/video/783849715?h=28004e4a8a&badge=0&autopause=0&player_id=0&app_id=58479/embed'
                  frameborder='0'
                  allow='autoplay; fullscreen'
                  allowfullscreen
                  title='custom_video'
                ></iframe>
              </li>
              <li>
                <iframe
                  src={`${starSrc}`}
                  frameborder='0'
                  allow='autoplay; fullscreen'
                  allowfullscreen
                  title='custom_video'
                ></iframe>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* BEST 3 Collection */}
      <div id='board' className='board col-lg-12'>
        <div className='container custom_board'>
          <div className='section-heading'>
            <h4>BEST 3 Collection</h4>
          </div>
          {isLoading ? null : best_pop()}
          {/* <div className="custom_bestitems">
          <ul>
            {/* <!-- 힐링&웰니스 투어 --> /}
            <li>
              <img src={top01} alt="힐링&amp;웰니스 투어" />
            </li> {/* <!-- img --> /}
            <li>
              <p className="custom_besttitle">Healing&amp;Wellness Tour</p>
              <p className="custom_besttext">Tradition Clay Kiln Tour</p>
            </li> {/* <!-- text --> /}
            <li><button className="custom_morebtn"> View Detail </button></li>
          </ul>
          <ul>
            {/* <!-- 뷰티&인생샷 투어 --> /}
            <li>
              <img src={top02} alt="뷰티&amp;인생샷 투어" />
            </li> {/* <!-- img --> /}
            <li>
              <p className="custom_besttitle">Beauty&amp;Photo shoot Tour</p>
              <p className="custom_besttext">Like an actor today</p>
            </li> {/* <!-- text --> /}
            <li><button className="custom_morebtn"> View Detail </button></li>
          </ul>
          <ul>
            {/* <!-- 부산 원도심 투어 --> /}
            <li>
              <img src={top03} alt="부산 원도심 투어" />
            </li> {/* <!-- img --> /}
            <li>
              <p className="custom_besttitle">Busan Original Downtown Tour</p>
              <p className="custom_besttext">Dong-gu ibagu street &amp; International Market</p>
            </li>{/*  <!-- text --> /}
            <li><button className="custom_morebtn"> View Detail </button></li>
          </ul>
        </div> */}
        </div>
        {/* .container .custom_board end*/}
      </div>

      <div className='footer'>
        <div className='custom_footcontainer'>
          <div className='row'>
            <div className='col-lg-12 custom_footer'>
              <h5>（株）釜山TNC</h5>
              <p className='custom_footerp'>
                釜山广域市 东区 忠壯大路 206 釜山港国际游客中心 3楼 3B08
                （釜山医疗观光中心&amp;邮轮休息室）
              </p>
              <ul className='custom_footerul'>
                <li>
                  <span>T</span> 070-8874-9334
                </li>
                <li>
                  <span>F</span> 051-462-9334
                </li>
                <li>
                  <span>E</span> busancruiselounge@gmail.com
                </li>
              </ul>
              <p>Copyright 2023. BUSAN TNC All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>

      {/* <ul className="custom_quick">

        <li>
          <a href="https://pf.kakao.com/_ixeFTb">
            <p title="kakao talk"></p>
          </a>
          <p title="kakao talk">Open Chatting</p>
        </li>

        <li>
          <p title="wechat"></p>
          <p title="wechat">ID : Busan_Medicall</p>
        </li>

        <li>
          <p title="whatsapp"></p>
          <p title="whatsapp">ID : Medicall</p>
        </li>

        <li>
          <p title="line"></p>
          <p title="line">ID : busan_medicall</p>
        </li>
      </ul> */}
    </>
  );
};

export default ChPage;
