import '../../assets/css/style.css';
import style from '../../assets/css/custom.css';

import { GrFormClose } from 'react-icons/gr';
import cn from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import {authClient} from '../../lib/axios';

const InputModal = ({ index, title, toggleModal }) => {
  //const inputRef =useRef();
  const [file, setFile] = useState();
  const [fileUrl, setFileUrl] = useState(null);
  //입력받는 값
  console.log('inputModal:', title.TITLE_kr);
  const [InputData, SetInputData] = useState({
    index: index.INDEX,
    // title_kr: '',
    // content_kr: '',
    // title_en: '',
    // content_en: '',
    // title_ch: '',
    // content_ch: '',
    // title_ru: '',
    // content_ru: '',
    title_kr: title.TITLE_kr,
    content_kr: title.CONTENT_kr,
    title_en: title.TITLE_en,
    content_en: title.CONTENT_en,
    title_ch: title.TITLE_ch,
    content_ch: title.CONTENT_ch,
    title_ru: title.TITLE_ru,
    content_ru: title.CONTENT_ru,
    imgUP: '',
  });
  const form = useRef(index);

  const updateField = (e) => {
    SetInputData({
      ...InputData,
      [e.target.name]: e.target.value,
    });
    console.log('test Input data :', InputData);
  };

  const submitFrom = async (event) => {
    //console.log("data:",index);
    event.preventDefault();

    //const upload_file= event.input.file;
    //const formData= new FormData();
    //formData.append('imgUP',upload_file);

    //console.log(formData);
    console.log('input_data:', InputData);
    let url = 'http://192.168.0.64:11751/api/modalinput';
    const option = {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      //body: JSON.stringify(InputData)
      data: InputData,
    };
    try {
      await authClient
        .post(`/modalinput`, option)
        //fetch(url, option)
        .then(async (res) => res)
        .then((data) => {
          console.log('result is : ', data);
          toggleModal(false);
          window.location.reload();
        });
    } catch (err) {
      console.log('fetch error : ', err);
      window.alert('권한이 없습니다');
    }
  };

  const isSelectedImg = async (event) => {
    //빈파일이 아닌 경우 함수 진행
    console.log('11111_ n', event.target.files);
    const imgFile = event.target.files[0];
    const imgUrl = URL.createObjectURL(imgFile);
    setFileUrl(imgUrl);

    if (event.target.files !== null) {
      //FormData 생성
      const fd = new FormData();
      //FormData에 key, value 추가하기
      fd.append('imgUP', event.target.files[0]);
      fd.append('title_ch', event.target.value);
      fd.append('content_ch', event.target.value);
      fd.append('title_ru', event.target.value);
      fd.append('content_ru', event.target.value);
      // axios 사용해서 백엔드에게 파일 보내기
      await authClient
        //.post(`/upload`, fd)
        .post(`/fileupload`, fd)
        //.post(`/modalinput`, fd)
        .then((res) => {
          //응답으로 받은 url 저장하기 (응답 내용의 표시나 방법은 백엔드와 결정해야한다.)
          //     setFile(res.data);
          SetInputData({
            ...InputData,
            imgUP: res.data.filename,
          });
          console.log('res:', res);
          console.log('파일이름만 저장하라고', res.data.filename);
          // 최종적으로 DB에 저장될 url을 보내는 과정이 부모 컴포넌트에 있기 때문에 부모 컴포넌트에 url을 보내는 과정
          //props.imgStore(res.data.image_url);
        })
        //에러가 날 경우 처리
        .catch((error) => {
          console.log(error.response);
        });
    }
  };

  return (
    <>
      <div className='modal_bg'>
        <div className='modal_div'>
          <div className='modal_fontclose'>
            <p className='modal_font'>타이틀 수정</p>
            <p className='modal_close'>
              <GrFormClose onClick={toggleModal} />
            </p>
          </div>
          <form
            className='modal_form'
            ref={form}
            onSubmit={submitFrom}
            encType='multipart/form-data'
            method='post'
          >
            {/* <form className="modal_form" ref={form} onSubmit={isSelectedImg} encType="multipart/form-data" method="post"> */}
            <div className='korea'>
              <div className='title_input'>
                <p>한국어 title:</p>
                <input
                  type='text'
                  name='title_kr'
                  value={title.TITLE_kr}
                  onChange={updateField}
                ></input>
              </div>
              <div className='content_input'>
                <p>한국어 content:</p>
                <input
                  type='text'
                  name='content_kr'
                  value={title.CONTENT_kr}
                  onChange={updateField}
                ></input>
              </div>
            </div>

            <div className='english'>
              <div className='title_input'>
                <p>영어 title:</p>
                <input
                  type='text'
                  name='title_en'
                  value={title.TITLE_en}
                  onChange={updateField}
                ></input>
              </div>
              <div className='content_input'>
                <p>영어 content:</p>
                <input
                  type='text'
                  name='content_en'
                  value={title.CONTENT_en}
                  onChange={updateField}
                ></input>
              </div>
            </div>

            <div className='chinese'>
              <div className='title_input'>
                <p>중국어 title:</p>
                <input
                  type='text'
                  name='title_ch'
                  value={title.TITLE_ch}
                  onChange={updateField}
                ></input>
              </div>
              <div className='content_input'>
                <p>중국어 content:</p>
                <input
                  type='text'
                  name='content_ch'
                  value={title.CONTENT_ch}
                  onChange={updateField}
                ></input>
              </div>
            </div>

            <div className='russia'>
              <div className='title_input'>
                <p>러시아 title:</p>
                <input
                  type='text'
                  name='title_ru'
                  value={title.TITLE_ru}
                  onChange={updateField}
                ></input>
              </div>
              <div className='content_input'>
                <p>러시아 content:</p>
                <input
                  type='text'
                  name='content_ru'
                  value={title.CONTENT_ru}
                  onChange={updateField}
                ></input>
              </div>
            </div>
            {/* <img src={fileUrl} height="200px"/> */}
            <input
              type='file'
              id='file-id'
              name='imgUP'
              accept='image/*'
              onChange={(data) => {
                isSelectedImg(data);
                updateField(data);
              }}
              className='modal_file'
            />
            {/* <input type="file" name="imgUP" id="file-id" accept="image/jpg,image/jpeg*"  onChange={updateField} className="modal_file"/> */}
            <div className='modal_btns'>
              <div className='btn_01' onClick={toggleModal}>
                {' '}
                취소
              </div>
              <div className='btn_02' onClick={submitFrom}>
                {' '}
                저장
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default InputModal;
