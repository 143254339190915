import React, { useReducer, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css'; /* 공통 css */
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './pages/Home';
import Manage from './pages/Manage';
import EditPage from './pages/EditPage';
import Login from './pages/Login';
import DetailPage from './pages/DetailPage';
import KorPage from './pages/KorPage';
import RuPage from './pages/RuPage';
import ChPage from './pages/ChPage';
import ViPage from './pages/VietnamPage'
//react-router-dom 버전 바뀌면서 구성 바뀜_k

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} /> {/* 로그인 */}
          <Route path='/' element={<Home />} /> {/* 메인페이지 / 영어 */}
          <Route path='/KorPage' element={<KorPage />} /> {/* 한국어 */}
          <Route path='/ChPage' element={<ChPage />} /> {/* 중어 */}
          <Route path='/RuPage' element={<RuPage />} /> {/* 러어 */}
          <Route path='/ViPage' element={<ViPage />} /> {/* 러어 */}
          <Route path='/view' element={<Manage />} /> {/* 관리자 페이지 */}
          <Route path='/editpage' element={<EditPage />} />
          {/* 이미지,text 수정 페이지 */}
          <Route path='/detail/:index' element={<DetailPage />} />
          {/* 이미지,text 수정 페이지 */}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
